import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import '../index.css';
import 'leaflet/dist/leaflet.css';
import L, { Icon } from 'leaflet';
import markerIconMap from '../images/marker-icon.png';
import axios from 'axios';
import * as Constants from '../utils/Constants';
import LeafletConsumer from './LeafletConsumer';
import { isEqual } from 'lodash';

function Leaflet(props) {
  const { readOnly, locationInputs, onMapDragEnd, leafletMarkerDragger, leafletDoubleClick, stackList, updateStackList, streamStatusData, mapViewType, deviceEnabled } = props;
  const [devicePosition, setDevicePosition] = useState();

  const markerRef = useRef(null);
  const [deviceId, setDeviceId] = useState('');
  const mapMaxBounds = new L.LatLngBounds(
    new L.LatLng(90, 180),
    new L.LatLng(-90, -180)
  );

  useEffect(() => {
    if (locationInputs.latitude !== undefined && locationInputs.longitude !== undefined) {
      setDevicePosition({lat: locationInputs.latitude, lng: locationInputs.longitude})
    }
  }, [locationInputs.latitude, locationInputs.longitude]);

  const getLocationApi = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_location')
      .then(res => {
        const data = res.data.data;
        setDevicePosition({lat: data.latitude, lng: data.longitude})
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getLocationApi();
  }, [getLocationApi]);

  const deviceMarkerEventsHandler = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setDevicePosition(marker.getLatLng());
          leafletMarkerDragger(marker.getLatLng());
        }
      },
    }),
    [leafletMarkerDragger],
  )

  const onStreamMarkerDragEnd = useCallback((e, stack_name) => {
    const {lat, lng} = e.target._latlng;
    let temp = {lat: '', lng: ''};
    if (lat > 84.75) {
      temp.lat = 84.75;
    } else if (lat < -85.5) {
      temp.lat = -85.5;
    } else {
      temp.lat = lat;
    }
    
    if (lng > 180) {
      temp.lng = 180;
    } else if (lng < -180) {
      temp.lng = -180;
    } else {
      temp.lng = lng;
    }
    updateStackList({latitude: temp.lat, longitude: temp.lng}, stack_name);
  }, [updateStackList]);

  const getDeviceId = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/display/left_menu/all')
      .then(res => {
        setDeviceId(res.data.data.device_id);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getDeviceId()
  }, [getDeviceId]);

  const updateMarkerPosition = useCallback((devicePosition) => {
    if (!readOnly) {
      setDevicePosition(devicePosition);
      leafletDoubleClick(devicePosition);
    }
  }, [readOnly, leafletDoubleClick]);

  return (
    <>
      <div style={{ 'marginLeft': '10px' }}>
        <div id='map'>
          <MapContainer
            doubleClickZoom={false}
            scrollWheelZoom={!readOnly}
            maxBounds={mapMaxBounds}
            minZoom={1}
            maxBoundsViscosity={1}
          >
            <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'/>
            {(deviceEnabled && devicePosition !== undefined) ? <Marker
              position={devicePosition}
              icon={new Icon({ iconUrl: markerIconMap, iconSize: [25, 41], iconAnchor: [12, 41] })}
              zIndexOffset={1}
              draggable={!readOnly}
              eventHandlers={deviceMarkerEventsHandler}
              ref={markerRef}>

              <Tooltip style={{ 'font-size': '15px' }}>This Device</Tooltip>
            </Marker> : null}
            <LeafletConsumer isStreamPage={false} {...{updateMarkerPosition, onStreamMarkerDragEnd, stackList, streamStatusData, readOnly, mapViewType, devicePosition, onMapDragEnd}} />
          </MapContainer>
        </div>
      </div>
    </>
  );

}

export default React.memo(Leaflet);
