import React, {useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import * as Constants from '../utils/Constants';
import { SelectionControl } from 'react-md';
import PasswordField from './PasswordField.js';
import styled from 'styled-components';
import { isEmpty } from '../utils/Utils';
import { ReactComponent as CopySVG } from '../images/copy.svg';
import swal from 'sweetalert';
import Loader from './Loader';
import { Wrapper, TextFieldSmall, TextFieldLabel, Row, Column, Button, CopyButton, Label } from '../utils/Styles';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;

const ResponsiveFieldset = styled.fieldset`
  width: 100%;
  padding: 0 15px;
`;

const ResponsiveContainer = styled.div`
  width: 500px;
  padding: 0 15px;
  @media screen and (max-width: 800px) {
    width: 400px;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
  }
  @media screen and (max-width: 500px) {
    width: 275px;
  }
`;

const ContentDiv=styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const TextFieldLabelVPN = styled(Label)`
  width: 190px;
  margin-top: 25px;
  &:after {
    font-weight: bold;
    content:" *";
    color: #EE4B2B;
  }
  
`;

const CopyButton1 = styled(CopySVG)`
  margin: 30px 0px 0px 0px;
  height: 18px;
  width: 18px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
  @media screen and (max-width: 400px) {
    margin: 22px 0px 3px 0px !important
    width: 18px;
  }
`;

const ButtonDelete = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  margin: 1em 0em 1em 1em;
  padding: 0.4em 1em;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7
  @media screen and (max-width: 400px) {
    height: 23px;
  }
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }
`;

const VPNClient = () => {
    const [readOnly, setReadOnly] = useState(true);
    const [isgetData, setgetData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [inputs, setInputs] = useState();
    const [isConfigure, setConfigured] = useState(true);
    const [added, setAdded] = useState(false);

    const handleOnChange = (event) => {
        if (!readOnly) {
            if ((event.target.name === 'host_name' || event.target.name === 'virtual_hub_name' || event.target.name === 'username' || event.target.name === 'password' ) && event.target.value.includes(' ')) {     
                event.target.value = event.target.value.replace(/\s/g, '');    
            } else {
                setInputs({ ...inputs, [event.target.name]: event.target.value });
            }
        }
    };

    const updateEnabledToggle = (value, name) => {
        let jsonData = Object.assign({}, inputs);
        let action = isgetData ? 'updated' : 'added';
        let finalJson = {};
        finalJson.enabled = value;
        finalJson.type = 'vpn';
        finalJson.namespace = 'system';
        finalJson.services={};
        finalJson.services.vpn_connector1 = Object.assign({}, jsonData);
        finalJson.services.vpn_connector1.enabled = true;
        finalJson.services.vpn_connector1.type = 'vpn_connector';
        finalJson.services.vpn_connector1.vpn_type = 'no_shadow';

        if (action === 'added' && added) {
        axios.put(Constants.EDGE_API_ENDPOINT + '/system/vpn/vpn_client/all', finalJson)
            .then(res => {
                setInputs({ ...inputs, [name]: value });
            })
            .catch(error => {
            if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
            } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
            }
            });
        } else {
        axios.patch(Constants.EDGE_API_ENDPOINT + '/system/vpn/vpn_client/all', finalJson)
            .then(res => {
                setInputs({ ...inputs, [name]: value });
            })
            .catch(error => {
            if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
            } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
            }
            });
        } 
    }

    const handleToggleChange = (value, event) => {
        const name = event.target.name;
        if(!readOnly) {
            setInputs({ ...inputs, [name]: value });
        } else {
            updateEnabledToggle(value, name);
        }
    };
    
    const editVPN = () => {
        setReadOnly(false);
        setgetData(true);
    }

    const getVPNData = useCallback(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/vpn/vpn_client/all')
        .then(res => {
            if(res.data.data.action === 'DeleteStack') {
                setReadOnly(false);
                setgetData(false);
                setLoading(false);
                setConfigured(true);
            } else {
                let inputs = Object.assign({}, res.data.data.services.vpn_connector1);
                inputs.enabled =  res.data.data.enabled;
                setInputs(inputs);
                setLoading(false);
                setgetData(true);
                setConfigured(false);
            }
        }).catch(error => {
            // console.log(error);
            setReadOnly(false);
            setgetData(false);
            setLoading(false);
            setConfigured(true);
        });
    },[]);

    useEffect(() => {
        getVPNData();
    },[getVPNData]);

    const saveVPNDetails = (jsonData, action, vpnEnable, notshow) => {
        let finalJson = {};
        finalJson.enabled = vpnEnable;
        finalJson.type = 'vpn';
        finalJson.namespace = 'system';
        finalJson.services={};
        finalJson.services.vpn_connector1 = Object.assign({}, jsonData);
        finalJson.services.vpn_connector1.enabled = true;
        finalJson.services.vpn_connector1.type = 'vpn_connector';
        finalJson.services.vpn_connector1.vpn_type = 'no_shadow';
        let input = Object.assign({}, finalJson.services.vpn_connector1);
        if (action === 'added') {
            axios.put(Constants.EDGE_API_ENDPOINT + '/system/vpn/vpn_client/all', finalJson)
            .then(res => {
                if(vpnEnable && notshow) {
                    swal('User-defined vpn setting created successfully.', { icon: 'success', timer: 4000 });
                    setAdded(false);
                    setInputs(input);
                    setReadOnly(true);
                } else if(!notshow) {
                    setInputs(input);
                } else {
                    if(notshow) {
                    swal({
                        text: `User-defined vpn setting ${action === 'updated' ? 'updated' : 'created'} successfully. \n\n User-defined vpn is disabled, do you want to enable it?`,
                        buttons: ['No', 'Yes'],
                        icon: 'success'
                      }).then((isConfirm) => {
                        if (isConfirm) {
                            saveVPNDetails(jsonData, action, true, false);
                            setReadOnly(true);
                        } else {
                            setReadOnly(true);
                        }
                      });
                    }
                }
              })
              .catch(error => {
                if (error.response) {
                  var errorObj = error.response.data;
                  swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
                } else {
                  swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
                }
              });
          } else {
            axios.patch(Constants.EDGE_API_ENDPOINT + '/system/vpn/vpn_client/all', finalJson)
              .then(res => {
                if(vpnEnable && notshow) {
                    swal('User-defined vpn setting updated successfully.', { icon: 'success', timer: 4000 });
                    setInputs(input);
                    setReadOnly(true);
                } else if(!notshow) {
                    setInputs(input);
                } else {
                    if(notshow) {
                    swal({
                        text: `User-defined vpn setting ${action === 'updated' ? 'updated' : 'created'} successfully. \n\n User-defined vpn is disabled, do you want to enable it?`,
                        buttons: ['No', 'Yes'],
                        icon: 'success'
                      }).then((isConfirm) => {
                        if (isConfirm) {
                            saveVPNDetails(jsonData, action, true, false);
                            setReadOnly(true);
                        } else {
                            setReadOnly(true);
                        }
                      });
                    }
                }
              })
              .catch(error => {
                if (error.response) {
                  var errorObj = error.response.data;
                  swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
                } else {
                  swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
                }
              });
            }
    }

    const deleteVpn = () => {
        swal({text: "\n\n Are you sure you want to delete this user-defined vpn?",
              buttons: ['No', 'Yes'],
              dangerMode: true
        })
        .then((isConfirm) => {
            if (isConfirm) {
                axios.delete(Constants.EDGE_API_ENDPOINT + '/system/vpn/vpn_client/all')
                    .then(res => {
                        swal("User-defined vpn setting deleted successfully.", { icon: "success"});
                        getVPNData();
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        }else{
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
            }
        });
    }

    const saveVpn = () => {
        let jsonData = Object.assign({}, inputs);
        let hostName = jsonData.host_name;
        let hubName = jsonData.virtual_hub_name;
        let username = jsonData.username;
        let password = jsonData.password;
        let vpnEnable = jsonData.enabled;
        let action = isgetData ? 'updated' : 'added';

        if (isEmpty(hostName)) {
            swal('Please enter vpn server host name', { icon: 'error' });
            return;
        }
        if (isEmpty(hubName)) {
            swal('Please enter virtual hub name', { icon: 'error' });
            return;
        }
        if (isEmpty(username)) {
            swal('Please enter username', { icon: 'error' });
            return;
        }
        if (isEmpty(password)) {
            swal('Please enter password', { icon: 'error' });
            return;
        }

        saveVPNDetails(jsonData, action, vpnEnable, true);
    }

    const clickToCopyVPNData = () => {
        const textarea = document.createElement('textarea');
        textarea.id = 'copyTextArea';
        textarea.value =  document.getElementById('host_name_label').innerText + ':\n' + document.getElementById('host_name').value + '\n\n' +  document.getElementById('virtual_hub_name_label').innerText + ':\n' + document.getElementById('virtual_hub_name').value + '\n\n' + document.getElementById('username_label').innerText + ':\n' + document.getElementById('username').value + '\n\n' + document.getElementById('password_label').innerText + ':\n' + document.getElementById('password').value + '\n';
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.width = '0px';
        textarea.style.height = '0px';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.getElementById('copyTextArea').remove();
      };
      
      const clickToCopyPassword = () => {
        const textarea = document.createElement('textarea');
        textarea.id = 'copyTextArea1';
        textarea.value =  document.getElementById('password').value;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.width = '0px';
        textarea.style.height = '0px';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.getElementById('copyTextArea1').remove();
      };

      const configuredHandle = () =>{
        setInputs(Constants.VPN_DEFAULT_VALUE);
        setConfigured(false);
      }

    return (
        <ContentDiv>
            <Wrapper style={{'padding': '5px'}}>
            <Loader isLoading={loading} />
            {isConfigure && !loading &&
            <>
                <Row>
                    <Label style={{'margin-left': '15px', 'margin-top': '10px' }}>User-defined VPN is not configured.</Label>
                </Row>
                <Row>
                    <Button primary onClick={configuredHandle}>Configure</Button>
                </Row>
            </>}
            { inputs && !loading && !isConfigure &&

                    <div>
                        <ResponsiveContainer>
                            <Row style={{'marginBottom': '10px', display:'flex', 'width':'100%', 'marginTop':'10px'}}>
                                <SelectionControl id="enabled" type="switch" label={inputs.enabled ? "Enabled" : "Disabled"} name="enabled" labelBefore="true" value={inputs.enabled} checked={inputs.enabled || false} onChange={handleToggleChange}/>
                                { !readOnly && isgetData &&
                                <div style={{'display':'flex', 'justifyContent':'flex-end', width:'100%'}}>
                                    <ButtonWrapper style={{'marginRight': '0'}}>
                                        <ButtonDelete danger id="btnDeleteInstance" name="btnDeleteInstance"  onClick={deleteVpn} style={{ 'margin': '0px' }} >Delete</ButtonDelete>
                                    </ButtonWrapper>
                                </div>}
                            </Row>
                            <ResponsiveFieldset>
                                {readOnly && <CopyButton primary onClick={() => clickToCopyVPNData()} style={{ 'float': 'right', 'marginTop': '10px' }}>Copy</CopyButton>}
                                    <TextFieldLabelVPN id='host_name_label'>VPN Server Host Name</TextFieldLabelVPN>
                                    <TextFieldSmall id="host_name" autoComplete="off" name="host_name" value={inputs.host_name} onChange={handleOnChange} disabled={readOnly ? readOnly : (!inputs.enabled ? true : false) } style={{'width': '100%'}} />
        
                                    <TextFieldLabelVPN id='virtual_hub_name_label'>Virtual Hub Name</TextFieldLabelVPN>
                                    <TextFieldSmall id="virtual_hub_name" autoComplete="off" name="virtual_hub_name" value={inputs.virtual_hub_name} onChange={handleOnChange} disabled={readOnly ? readOnly : (!inputs.enabled ? true : false)} style={{'width': '100%'}} />

                                    <TextFieldLabelVPN id='username_label'>Username</TextFieldLabelVPN>
                                    <TextFieldSmall id="username" autoComplete="off" name="username" value={inputs.username} onChange={handleOnChange} disabled={readOnly ? readOnly : (!inputs.enabled ? true : false)}  style={{'width': '100%'}} />

                                <Row style={{'marginBottom': '20px'}}>
                                    {readOnly && <CopyButton1 primary onClick={() => clickToCopyPassword()} style={{ 'float': 'right', 'marginTop': '30px' }}>Copy</CopyButton1>}
                                    <TextFieldLabelVPN id='password_label'>Password</TextFieldLabelVPN>
                                    <PasswordField id='password' type='text' name='password' width="100%" value={inputs.password} setInput={handleOnChange} readOnly={readOnly ? readOnly : (!inputs.enabled ? true : false)} />
                                </Row>
                            </ResponsiveFieldset>
                            <Row style={{'width': '100%'}}>
                                {readOnly ?
                                    <ButtonWrapper>
                                        <Button primary onClick={editVPN}>Edit</Button>
                                    </ButtonWrapper> :
                                    <ButtonWrapper>
                                        <Button primary onClick={saveVpn}>Save</Button>
                                        <Button id='btnCancle' danger onClick={() =>  window.location.reload()}>Cancel</Button>
                                    </ButtonWrapper>}
                            </Row>
                        </ResponsiveContainer>
                    </div>}
            </Wrapper>
        </ContentDiv>
    );
}
export default VPNClient;
