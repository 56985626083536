import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Label, Spacer, SelectField, Row, Column, RightDiv, SectionHeader, Button, ButtonWrapper, SectionLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import { SelectionControl, CircularProgress, GridList } from 'react-md';
import swal from "sweetalert";
import styled from 'styled-components';

const Wrapper=styled.div`
  width: 98%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
  margin-right: 1%;
`;

const StyledColumn = styled(Column)`
    @media screen and (max-width: 550px) {
        width: max-content;
    }
`;

const TimeDiv = styled(Column)`
    display: flex;
    @media screen and (max-width: 960px) {
        width: max-content;
    }
    @media screen and (max-width: 400px) {
        flex-direction: column;
        gap: 10px 0;
        & > div > label {
            margin-right: 5px;
            width: 50px;
        }
        & > div:last-child {
            margin-left: 25px;
        }
    }
`;

const StyledGridList = styled(GridList)`
    justify-content: flex-start;
    & > div > label {
        gap: 0 5px;
    }
    & > div > label > span {
        width: 40px;
    }
    @media screen and (max-width: 850px) {
        padding: 0 5px !important;
        margin-left: 25px !important;
        gap: 0 10px;
        justify-content: space-between;
        & > div {
            width: 110px;
        }
    }
`

const Schedule = (props) => {
    const [readOnly, setReadOnly] = useState(true);
    const [scheduleData, setScheduleData] = useState();
    const [isChanged, setChanged] = useState(false);
    const scheduleType = props.scheduleType;
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    const handleOnChange = (event) => {
        event.persist();
        if(!readOnly){
            setScheduleData(scheduleData => ({...scheduleData, [event.target.name]:event.target.value}));
            setChanged(true);
        }
    }

    const handleToggleChange = (value, event) => {
        if(!readOnly){
            let key = event.target.name;
            setScheduleData(scheduleData => ({...scheduleData, [key]: value}));
            setChanged(true);
        }
    }

    const editSchedule = (event) => {
        setReadOnly(readOnly => (false));
    }

    useEffect(() => {
        setScheduleData(props.data);
    }, [props.data != undefined]);

    function prepareRequestJson(inputData){
        var reqData = {};
        var weekdays = "";
        Object.keys(Constants.WEEK_DAYS).map((week_day) => {
            if(inputData[week_day + scheduleType] === true) {
                weekdays += ((Constants.WEEK_DAYS[week_day]) + ",");
            }
        })
        weekdays = weekdays.replace(/,+$/, "");
        reqData["action"] = "Schedule" + scheduleType;
        reqData["enabled"] = scheduleData["enabled" + scheduleType];
        reqData["weekday"] = weekdays;
        reqData["hour"] = scheduleData["hour" + scheduleType];
        reqData["minute"] = scheduleData["minute" + scheduleType];

        if(reqData["enabled"] === true && (reqData["weekday"] === undefined || reqData["weekday"].trim() === "")){
            swal('Select day for schedule ' + scheduleType.toLowerCase(), { icon: 'error' });
            return;
        }
        return reqData;
    }

    const handleCancel = (event) => {
        if(!isChanged){
            setReadOnly(readOnly => (true));
            return;
        } else {
            window.location.reload();
        }
    }

    const handleSubmit = (event) => {
        var inputData = Object.assign({}, scheduleData);
        var jsonData = prepareRequestJson(inputData);
        if (jsonData === undefined) {
            return;
        }
        axios.put(Constants.EDGE_API_ENDPOINT + '/devices/cron', jsonData)
        .then(res => {
            swal("Device schedule " + scheduleType.toLowerCase() + " request submitted successfully.", { icon: "success"});
            setReadOnly(readonly => (true));
            setChanged(false);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    return (
            <Wrapper style={{'padding':'5px'}}>
                { (scheduleData === undefined) &&
                    <div className="loading-msg">
                        <label>Loading...</label>
                        <CircularProgress/>
                    </div>
                }
                { scheduleData !== undefined &&
                    <>
                    <Row>
                        <StyledColumn>
                            <SelectionControl id={"enabled" + scheduleType} type="switch" name={"enabled" + scheduleType} label= {scheduleType + " Schedule "} labelBefore="true" checked={scheduleData["enabled" + scheduleType] || false} onChange={handleToggleChange}/>
                        </StyledColumn>
                    </Row>
                    <Row>
                        <Label style={{padding: '0 2%'}}>Schedule Day</Label>
                    </Row>
                    <Row style={{'width': (isMobileBrowser ? '100%': (window.innerWidth === 1920 || window.innerWidth === 1366) ? '50%' : '100%')}}>
                        <StyledGridList size={3} className="schedule-weekday">
                        { Object.keys(Constants.WEEK_DAYS).map((week_day) => {
                            let isSelected = scheduleData[week_day + scheduleType] === undefined ? false : scheduleData[week_day + scheduleType];
                            return(
                                <SelectionControl id={week_day + scheduleType} type="switch" label={week_day.toUpperCase()} name={week_day + scheduleType} labelBefore="true" checked={isSelected} onChange={handleToggleChange}/>
                                )
                        })}
                        </StyledGridList>
                    </Row>
                    <Row>
                        <Label style={{padding: '0 2%'}}>Schedule Time</Label>
                    </Row>
                    <Row>
                    <TimeDiv>
                        <div>                    
                            <Label className="margin-left-25">Hour</Label>
                            <SelectField className="schedule-hours" name={"hour" + scheduleType} value={scheduleData["hour" + scheduleType]} onChange={handleOnChange} disabled={readOnly}>
                                <SelectOptions items={Constants.HOURS}/>
                            </SelectField>
                        </div>
                        <div>
                            <Label>Minute</Label>
                            <SelectField className="schedule-minutes" name={"minute" + scheduleType} value={scheduleData["minute" + scheduleType]} onChange={handleOnChange} disabled={readOnly}>
                                <SelectOptions items={Constants.MINUTES}/>
                            </SelectField>
                        </div>
                    </TimeDiv>
                    
                    </Row>
                    <Row>
                        <Column style={{width: 'inherit'}}>
                            <ButtonWrapper>
                                <RightDiv>
                                    { readOnly === true &&
                                        <Button primary onClick={editSchedule}>Edit</Button>
                                    }
                                    { readOnly === false &&
                                        <div>
                                            <Button primary onClick={handleSubmit}>Save</Button>
                                            <Button onClick={handleCancel}>Cancel</Button>
                                        </div>
                                    }
                                </RightDiv>
                            </ButtonWrapper>
                        </Column>
                    </Row>
                    </>
                }
            </Wrapper>
            )
}

export default Schedule;
