import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { ContentDiv, Link, Wrapper, Row, LeftDiv1, RightDiv, StreamContentDiv, SectionHeader, SectionLabel, RunningLabel, FailedLabel, RecoveringLabel, DisabledLabel, PausedLabel, Label, SelectField, ThreeDotMenu } from '../utils/Styles';
import "../css/react-metismenu-standard.css";
import * as Constants from '../utils/Constants';
import CameraImage from '../components/CameraImage';
import { GridList, Card, Media, ListItem, MenuButton, CircularProgress } from 'react-md';
import swal from "sweetalert";
import AutoRefresh from '../components/AutoRefresh';
import ls from 'local-storage';
import styled, { css } from 'styled-components';
import downarrow from '../images/downarrow.png';
import uparrow from '../images/uparrow.png';
import axiosRetry from 'axios-retry';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import { useHistory, useLocation } from 'react-router-dom';
import markerIconMap from '../images/marker-icon.png';

import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import '../index.css';
import 'leaflet/dist/leaflet.css';
import L, {Icon} from 'leaflet'
import Mapbox from './MapBox';
import LeafletConsumer from './LeafletConsumer';
import { isEqual } from 'lodash';


const Dropdown = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80%;
    left: auto;
    width: 170px;
    z-index: 2;
    background: #fff;
    padding-bottom: 5px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
`;

const StyledLink = styled(Link)`
    padding: 10px;
    text-decoration: none;
    color: black;
    width: auto;
    margin: 5px 5px 0 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.14);
      cursor: pointer;
    }
`;

const RightDivForLabels = styled(RightDiv)`
  @media screen and (max-width: 525px) {
    width: 250px;
    overflow-x: auto;
    padding-bottom: 5px;
    & > div {
        display: flex;
    }
    & > div > label {
        display: block;
        flex-shrink: 0;
    }
    
    ::-webkit-scrollbar {
        height: 3px;  
        width: 2px;            
        border-radius: 10px;
        background: transparent;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background: none;
    }
    ::-webkit-scrollbar-thumb {
        background: #A8B5BD7D;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #A8B5BD7D;
    }
  }
  @media screen and (max-width: 475px) {
    width: 200px;
  }
  @media screen and (max-width: 420px) {
    width: 150px;
  }
`;

const ResponsiveLeftDiv1 = styled(LeftDiv1)`
  @media screen and (max-width: 360px) {
    width: 65%;
  }
`;

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  margin: 1em 0em 1em 1em;
  padding: 5px 0px 5px 0px;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7;
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }

@media screen and (max-width: 710px)  {
    font-size: 13px;
    max-width: 106px;
}
@media screen and (max-width: 570px)  {
    font-size: 12px;
    max-width: 101px;
}
@media screen and (max-width: 450px)  {
    font-size: 10px;
    max-width: 85px;
}
// @media screen and (max-width: 350px)  {
//     font-size: 8px;
//     min-width: 70px;
//   }

`;

const StyledCard = styled(Card)``;

const StyledGridList = styled(GridList)`
  @media screen and (max-width: 1200px) {
    ${StyledCard} {
        min-width: 450px;
    }
  }
  @media screen and (max-width: 950px) {
    flex-wrap: wrap;
    gap: 5px;
    ${StyledCard} {
        min-width: 75%;
    }
  }
  @media screen and (max-width: 775px) {
    ${StyledCard} {
        min-width: 85%;
    }
  }
  @media screen and (max-width: 700px) {
    ${StyledCard} {
        min-width: 90%;
    }
  }
  @media screen and (max-width: 680px) {
    ${StyledCard} {
        min-width: 85%;
    }
  }
  @media screen and (max-width: 475px) {
    ${Button} {
        width: 100px !important;
        font-size: .8rem;
    }
  }
`;

const StreamContainer = styled.div`
  @media screen and (max-width: 920px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 775px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 715px) {
    font-size: 0.84rem;
  }
  @media screen and (max-width: 680px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 680px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 620px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 570px) {
    font-size: 0.84rem;
  }
  @media screen and (max-width: 540px) {
    font-size: 0.8rem;
  }
`;

const ResponsiveTabList = styled.div`  
    & .rwt__tab {
        @media screen and (max-width: 1000px) {
            padding: 12px;
        }
        @media screen and (max-width: 910px) {
            font-size: 1rem;
        }
        @media screen and (max-width: 875px) {
            font-size: 0.9rem;
        }
        @media screen and (max-width: 800px) {
            padding: 9px;
            font-size: 0.85rem;
        }
        @media screen and (max-width: 750px) {
            padding: 7px 5px;
        }
        @media screen and (max-width: 700px) {
            font-size: 0.75rem;
        }
        @media screen and (max-width: 475px) {
            font-size: 0.65rem;
            padding: 7px 4px;
        }
        @media screen and (max-width: 420px) {
            font-size: 0.6rem;
        }
        @media screen and (max-width: 390px) {
            font-size: 0.52rem;
        }
    }
`;

const InputDiv=styled.div`
    @media screen and (max-width: 390px) {
        font-size: 0.52rem;
    }
`;

const CheckboxContainer = styled(InputDiv)`
    ${({isDisabled}) => isDisabled && css`
        opacity: 0.4;
    `}
`;

const InputCheck=styled.input`
    @media screen and (max-width: 390px) {
        font-size: 0.52rem;
    }
`;

const StreamList = ({history} , props)=> {
    const [stackList, setStackList] = useState();
    const prevStackListRef = useRef();
    const [stackListLength, setStackListLength] = useState(0);
    const [statusCount, setStatusCount] = useState();
    const [streamStatusData, setStreamStatusData] = useState();
    const [healthStatus, sethealthStatus] = useState();
    const [splitIntervalMaxVal, setSplitIntervalMaxVal] = useState();
    const [openDownDrop, setOpenDropDown] = useState(false);
    const ref = useRef();
    const [showMap, setShowMap] = useState(false);
    let path = window.sessionStorage.getItem("path");
    const [mapService, setMapService] = useState('');
    const [mapToken, setMapToken] = useState('');
    const [selectedTab, setSelectedTab] = useState('stream');
    const params = useLocation().search;
    const [historyParams] = useState(params);
    const [configure, setConfigure] = useState(false);
    const [source, setSource] = useState({});
    const [locationInputs, setLocationInputs]=useState({
        latitude: '35.65283',
        longitude: '139.83947',
        enabled_location: true
    })
    const [mapViewType, setMapViewType] = useState('NoStreamDevice');
    const initialMapViewCheckboxes = {streamsCheckbox: false, deviceCheckbox: false};
    const [mapViewCheckboxes, setMapViewCheckboxes] = useState(!localStorage.getItem('mapViewCheckboxesStreamsPage') ? initialMapViewCheckboxes : JSON.parse(localStorage.getItem('mapViewCheckboxesStreamsPage')));
    const center = {
      lat: locationInputs.latitude,
      lng: locationInputs.longitude,
    }
    const [position,setPosition] = useState(center);
    const [isMount, setIsMount] = useState(true);
    const mapMaxBounds = new L.LatLngBounds(
        new L.LatLng(90, 180),
        new L.LatLng(-90, -180)
    );
     const setParams = useCallback(() => {
        const params = '?' +
          `${selectedTab === 'stream' ? 'stream' : '&selectedTab=' + selectedTab}`
        if (params === '?') {
          return '';
        }
        return params;
      }, [selectedTab]);

      const fetchHistoryParams = useCallback(() => {
        const string = new URLSearchParams(historyParams);
        let newParams = {};
        newParams.selectedTab = string.get('selectedTab');
    
        if (newParams.selectedTab) {
          setSelectedTab(newParams.selectedTab);
        }
      }, [historyParams]);

      useEffect(()=>{
        fetchHistoryParams();
      },[fetchHistoryParams]);

      useEffect(() => {
            setTimeout(() => {
                setShowMap(true);
            }, 100);
        
        history.push('/stream-viewer' + setParams());
      }, [history, setParams]);
    
      const getLocationApi = useCallback(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_location')
        .then(res =>{
            const data=res.data.data;
            if (data.latitude !== undefined && data.longitude !== undefined) {
                setLocationInputs(data)
                setPosition({lat: data.latitude, lng: data.longitude});
            }
        })
        .catch(err => {
          console.log(err);
        });
    },[]);

    useEffect(() => {
        getLocationApi();
    },[]);

    const getAccessToken = useCallback( () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_location/map/all')
        .then(res =>{
            if(res.data.data.access_token === ' ' || res.data.data.access_token === ''){
                setMapToken('')
                setMapService(res.data.data.service_provider)
            } else {
                setMapToken(res.data.data.access_token)
                setMapService(res.data.data.service_provider)
            }
        }).catch(err => {
            console.log(err);
          });
    },[]);

    useEffect(() => {
        getAccessToken();
    },[getAccessToken]);


    const hostStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        maxWidth: '350px'
      };

    useOnClickOutside(ref, () => setOpenDropDown(false));

    function viewStream(stack_name) {
        window.location.href = "./view-stream?stream=" + stack_name
    }

    function reloadStreamList(){
        history.push("./stream-viewer");
    }

    useEffect(() => {
        getStreamList();
    }, []);

    function getStreamList(){
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{ 
            retries: 4,
            retryDelay: (retryCount, error) => {
                if(retryCount === 4) {
                    swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
        });
        client.get('/stacks/all')
            .then(res => {
                if("device_id" in res.headers){
                    if(ls.get('device_id') === res.headers["device_id"]) {
                        ls.set("device_id", res.headers["device_id"]);
                    } else {
                        
                        ls.set("device_id", res.headers["device_id"]);
                    }
                    // document.getElementById("deviceId").textContent = res.headers["device_id"];
                }
                if("device_ip" in res.headers){
                    ls.set("device_ip", res.headers["device_ip"]);
                }
                const stacks = res.data["data"]["stacks"];
                Object.keys(stacks).map((stack_name) => {
                    if(stacks[stack_name]["action"] === "DeleteStack"){
                        delete stacks[stack_name];
                    }
                });
                setStackList(stacks);
            }).catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                }else{
                    swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                }
            });

        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/status/all')
            .then(res => {
                var runningCount = 0, recoveringCount = 0, disabledCount = 0, failedCount = 0, pausedCount = 0;
                const stacks = res.data["data"]["stacks"];
                Object.keys(stacks).map((stack_name) => {
                    let statckStatus = stacks[stack_name]["running_status"]["status"];
                    if(statckStatus === "running"){
                        runningCount++;
                    } else if(statckStatus === "disabled"){
                        disabledCount++;
                    } else if(statckStatus === "paused"){
                        pausedCount++;
                    } else if(statckStatus === "recovering" || statckStatus === "desired"){
                        recoveringCount++;
                    } else if(statckStatus === "failed"){
                        failedCount++;
                    }
                });
                var statusCountJson = {
                    "running_count": runningCount,
                    "disabled_count": disabledCount,
                    "recovering_count": recoveringCount,
                    "failed_count": failedCount,
                    "paused_count": pausedCount
                }
                setStatusCount(statusCountJson);
                setStreamStatusData(res.data.data)
            });
    }

    useEffect(() =>{
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/status')
        .then(res =>{
            sethealthStatus(res.data['data']['services']['tmpfs_usage']['/opt/scorer/cache/videos']['size']);
        })
        .catch(err => {
            console.log(err);
        })
    }, []);

    useEffect(() =>{
        const interval = setInterval(() => {
            getStreamList();
        }, 3000);
        return () => clearInterval(interval);
    },[]);

    function getVideoDevice(stack_name, services){
        var video_device = "";
        Object.keys(services).map((service_name) => {
            if(services[service_name]["type"] == "recorder"){
                video_device = services[service_name]["video_device"];
            }
        });
        return video_device;
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
      }

      useEffect(() => {
        let obSize = formatBytes(healthStatus);
        let num = Math.floor(obSize);
        let val = num * 0.4;
        setSplitIntervalMaxVal(Math.floor(val));
      },[healthStatus]);

    function deleteStack(stackName){
        swal({text: "\n\nAre you sure you want to delete this stream?",
              buttons: ['No', 'Yes'],
              dangerMode: true
        })
        .then((isConfirm) => {
            if (isConfirm) {
                axios.delete(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all")
                    .then(res => {
                        swal("Stream delete request submitted successfully.", { icon: "success"});
                        setTimeout(function(){ window.location.reload() }, 2000);
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        }else{
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
            }
        });
    }

    const enableDisableStack = (stackName, actionLabel,source,configure) => {
        var action = (actionLabel == "Enable" ? "start" : "stop")
        var jsonData = {
            "namespace": "default",
            "action": action,
            "source": "Edge UI"
        }
        var msg = "\n\n Are you sure you want to "+ actionLabel.toLowerCase() +" this stream?"
        if(source === "SAFIE" && !configure){
            swal(`\n\nSafie Connector is not configured yet. \nPlease configure it from Settings > Safie Connector.`, {
                buttons: {
                  cancel: "Cancel",
                  configure: {
                    text: "Go to Settings",
                    value: "catch",
                  },
                //   defeat: true,
                },
              })
              .then((value) => {
                switch (value) {
               
                  case "catch":
                    window.location.href = 'safie-camera-settings'
                    break;
               
                  default:
                }
              });
        }else{
            swal({text: msg,
                buttons: ['No', 'Yes'],
                dangerMode: true,
               }).then(function(isConfirm) {
                   if (isConfirm) {
                       axios.post(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
                       .then(res => {
                           swal("Stream " + actionLabel.toLowerCase() + " request submitted successfully.", { icon: "success"});
                           setTimeout(function(){ window.location.reload() }, 2000);
                       })
                       .catch(error => {
                           if(error.response){
                               var errorObj = error.response.data;
                               swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                           }else{
                               swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                           }
                       });
                   }
               });
        }
    }

    function getStreamStatus(stackName){
        var streamStatus = "";
        try{
            if (streamStatusData && "stacks" in streamStatusData){
                streamStatus = streamStatusData["stacks"][stackName]["running_status"]["status"];
            }
        }catch(error){
            console.log(error);
        }
        return streamStatus;
    }

    const truncate = (input) =>{
        if (input.length > 28) {
           return input.substring(0, 28) + '...';
        }
        return input;
      };

      const handleDropDown = () => {
        if(openDownDrop) {
            setOpenDropDown(false);
        } else {
            setOpenDropDown(true);
        }
    }

    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              // Do nothing if clicking ref's element or descendent elements
              if (!ref.current || ref.current.contains(event.target)) {
                return;
              }
      
              handler(event);
            };
      
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
      
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
    }

    const handleSelectTab = (e) => {
        setShowMap(false);
        if(e !== undefined) {
            setTimeout(() => {
                setShowMap(true);
            }, 100);
        }
    }

    function onTabChange(tabId){
        setSelectedTab(tabId);
    }

    const getStackTooltipDetails = (stack_name, stack) => {
        let name = ''
        if(stack === 'USB') {
            name = stack_name + ' (Web Camera)'
        } else if(stack === 'RTSP') {
            name = stack_name + ' (IP Camera)'
        } else if(stack === 'FTP') {
            name = stack_name + ' (Image FTP)'
        } else if(stack === 'SAFIE') {
            name = stack_name + ' (Safie Camera)'
        } else if(stack === 'MLIT') {
            name = stack_name + ' (MLIT encoder)'
        } else if(stack === 'RTMP') {
            name = stack_name + ' (RTMP)'
        } else if(stack === 'RTP') {
            name = stack_name + ' (RTP)'
        }
        return name;
    }

    const getConfigDetails = () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/safie/all')
        .then(res => {
            if(res.data.data.action === 'DeleteStack') {
                setConfigure(false);
            } else {
                if(res.data.data.services.safie_settings1.enabled === true ) {
                    setConfigure(true);
                } else {
                    setConfigure(false);
                }
            }
        })
        .catch(error => {
            console.log(error);
            setConfigure(false);
        });
    }

    useEffect(() => {
        getConfigDetails();
    },[]);

    const getStreamList2 = () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/all')
        .then(res => {
            const stacks = res.data["data"]["stacks"];
           
            Object.keys(stacks).map((stack_name) => {
                let mystack = stacks[stack_name]["services"]["recorder1"]["camera_type"] == "SAFIE";
                setSource(mystack)
            });
    
        }).catch(error => {
           console.log(error);
        });
    }
    useEffect(()=>{
        getStreamList2()
    },[])

    useEffect(() => {
        if (!locationInputs.enabled_location) {
            setMapViewCheckboxes(prev => {
                localStorage.setItem('mapViewCheckboxesStreamsPage', JSON.stringify({...prev, deviceCheckbox: false}));
                return {...prev, deviceCheckbox: false};
            });
        }
    }, [locationInputs.enabled_location]);

    const onMapDragEnd = useCallback(() => {
        setMapViewCheckboxes(initialMapViewCheckboxes);
        localStorage.setItem('mapViewCheckboxesStreamsPage', JSON.stringify({deviceCheckbox: false, streamsCheckbox: false}));
    }, [initialMapViewCheckboxes]);

    const onStreamCheckbox = useCallback((e) => {
        const {checked} = e.target;
        setMapViewCheckboxes(prev => {
            localStorage.setItem('mapViewCheckboxesStreamsPage', JSON.stringify({...prev, streamsCheckbox: checked}));
            return {...prev, streamsCheckbox: checked};
        });

    }, []);

    const onDeviceCheckbox = useCallback((e) => {
        const {checked} = e.target;
        setMapViewCheckboxes(prev => {
            localStorage.setItem('mapViewCheckboxesStreamsPage', JSON.stringify({...prev, deviceCheckbox: checked}));
            return {...prev, deviceCheckbox: checked};
        });

    }, []);
    
    useEffect(() => {
        const {streamsCheckbox, deviceCheckbox} = mapViewCheckboxes;
        let mapViewType = 'NoStreamDevice';
        if (streamsCheckbox && deviceCheckbox) {
            mapViewType = 'StreamDevice';
        } else if (!streamsCheckbox && deviceCheckbox) {
            mapViewType = 'Device';
        } else if (streamsCheckbox && !deviceCheckbox) {
            mapViewType = 'Stream';
        } else if (!streamsCheckbox && !deviceCheckbox) {
            mapViewType = 'NoStreamDevice';
        }
        setMapViewType(mapViewType);
    }, [mapViewCheckboxes]);

    useEffect(() => {
        if (isEqual(prevStackListRef.current, stackList)) return;
        prevStackListRef.current = stackList;
        if (stackList !== undefined) {
            const stackLength = Object.keys(stackList).filter(stack => {
                const { location } = stackList[stack];
                if (location !== undefined) {
                    return (location.enabled_location !== undefined ? location.enabled_location : false);
                } else {
                    return false;
                }
            }).length;
            setStackListLength(stackLength);
            if (stackLength === 0) {
                setMapViewCheckboxes(prev => {
                    return {...prev, streamsCheckbox: false};
                });
            }
        }
    }, [stackList]);

    useEffect(() => {
        if (isMount) {
            if (stackListLength > 0 && localStorage.getItem('mapViewCheckboxesStreamsPage') !== null) {
                setMapViewCheckboxes(JSON.parse(localStorage.getItem('mapViewCheckboxesStreamsPage')));
                setIsMount(false);
            }
        }
    }, [isMount, stackListLength]);
    
    const currentMapView = useRef();

    const getMapCurrentView = useCallback((mapCurrentView) => {
        currentMapView.current = mapCurrentView;
    }, []);

    const onDeviceMarkerClick = useCallback(() => {
        localStorage.setItem('keepRedirectedDeviceMapView', JSON.stringify({keep: true, ...currentMapView.current}));
        history.push('./location?general');
    }, [history]);

    return (
        <ContentDiv style={{'overflow':'hidden', 'paddingBottom':'100px'}}>
            { stackList == undefined &&
                <div className="loading-msg">
                    <label>Loading...</label>
                    <CircularProgress/>
                </div>
            }
            { stackList &&
            <Wrapper>
              <StreamContainer>
                <SectionHeader>
                  <SectionLabel style={{userSelect:'none'}}>Stream Viewer</SectionLabel>
                  { statusCount &&
                  <RightDivForLabels>
                    {/* <div>
                      <AutoRefresh autoRefresh={autoRefreshCallback} pageName="stream_list"/>
                    </div> */}
                    <div style={{"clear": "both"}}>
                      <RunningLabel id="runningCount">Running: {statusCount.running_count}</RunningLabel>
                      <RecoveringLabel id="reconciliationCount">Reconciliation: {statusCount.recovering_count}</RecoveringLabel>
                      <PausedLabel id="pausedCount">Paused: {statusCount.paused_count}</PausedLabel>
                      <DisabledLabel id="disabledCount">Disabled: {statusCount.disabled_count}</DisabledLabel> 
                      <FailedLabel id="failedCount">Failed: {statusCount.failed_count}</FailedLabel>
                    </div>
                  </RightDivForLabels>
                  }
                </SectionHeader>

                <Tabs defaultTab={selectedTab} onChange={onTabChange}>

                    {(locationInputs.enabled_location || stackListLength !== 0) ? <TabList>
                        <ResponsiveTabList>
                            <Tab tabFor="stream" className={`rwt__tab`} >Streams</Tab>
                            <Tab tabFor="map" className={`rwt__tab`} onClick={handleSelectTab}>Geolocation</Tab>
                        </ResponsiveTabList>
                    </TabList> : null}
                    <TabPanel tabId="stream">
                        <>
                        <div style={{ 'display': 'flex', 'justifyContent': 'flex-end', 'marginRight': '10px', 'position': 'relative'}} ref={ref}>
                <Button primary onClick={handleDropDown} style={{'width': '130px'}}>Add Stream <img src={openDownDrop ? uparrow : downarrow} style={{'width': '10px', 'marginLeft': '5px'}} /> </Button>  
                        {openDownDrop && 
                        <Dropdown>
                          <StyledLink href={path === '' ? '/add-usb-stream' : path + 'add-usb-stream'}>Web Camera (UVC)</StyledLink>
                          <StyledLink href={path === '' ? '/add-rtsp-stream' : path + 'add-rtsp-stream' }>IP Camera (RTSP)</StyledLink>
                          <StyledLink href={path === '' ? '/add-ftp-stream' : path + 'add-ftp-stream'}>Image (FTP)</StyledLink>
                          <StyledLink href={path === '' ? '/add-safie-stream' : path + 'add-safie-stream'}>Safie Camera</StyledLink>
                          {Constants.AD_HW_VPU !== 'MMAL' && <StyledLink href={path === '' ? '/add-mlit-stream' : path + 'add-mlit-stream'}>MLIT Encoder (RTP)</StyledLink>}
                          <StyledLink href={path === '' ? '/add-rtmp-stream': path + 'add-rtmp-stream'}>RTMP</StyledLink>
                          <StyledLink href={path === '' ? '/add-rtp-stream': path + 'add-rtp-stream'}>RTP</StyledLink>
                        </Dropdown>}
                    </div>
                { Object.keys(stackList).length===0 &&
                    <div style={{"margin": "100px 20px"}}>Stream list is empty.<br/><br/>You can add a stream using <b>Add Stream</b> menu.</div>
                }
                <StyledGridList size={window.innerWidth >= 1920 ? 4 : 6}>
                { Object.keys(stackList).map((stack_name) => {
                    const {description, enabled, services, action, action_status, snapshot_refresh_interval} = stackList[stack_name];
                    var streamStatus = getStreamStatus(stack_name)
                    const disableMenu = (action === "DeleteStack" && action_status.status === "desired") ? "disabled" : "";
                    const enabledLabel = (streamStatus === "disabled") ? "Enable" : "Disable";
                    var recorderService
                    var sourceType = "RTSP"
                    var refreshImage = (enabled === false || streamStatus == "paused" || streamStatus == "disabled") ? false : true
                    Object.keys(services).map((service_name) => {
                        if(services[service_name]["type"] == "recorder") {
                            recorderService = services[service_name]
                        }
                    })
                    const contentStyle = {
                        "width":" 85%",
                        "text-align": "center",
                        "height": "90%"
                    };
                    return(
                        <StyledCard key={stack_name}>
                            {
                            (() => {
                            if (streamStatus === "paused" )
                                return <Media>
                                    <CameraImage stackName={stack_name} refreshInterval={refreshImage ? snapshot_refresh_interval : 0} streamStatus={streamStatus} recorder={recorderService} isListPage={true}/>
                                </Media>
                            if (streamStatus === "disabled" )
                                return <Media><img src={ require('../images/no_image.png')} className="no_img"/></Media>
                            else
                                return <Media>
                                            <CameraImage stackName={stack_name} refreshInterval={refreshImage ? snapshot_refresh_interval : 0} streamStatus={streamStatus} recorder={recorderService} isListPage={true}/>
                                        </Media>
                            })()
                            }
                            { streamStatusData &&
                                <div className={"stream_list_" + getStreamStatus('' + stack_name + '')}></div>
                            }
                            <StreamContentDiv>
                                <ResponsiveLeftDiv1>
                                    <Link href={'./view-stream?stream=' + stack_name} onClick={() => viewStream('' + stack_name + '')} ><b><div style={hostStyle} title={stack_name}>{stack_name}</div></b></Link>
                                    <p title={description} style={{cursor: "pointer"}}>{truncate(description)}</p>
                                </ResponsiveLeftDiv1>
                                <RightDiv>
                                    <MenuButton
                                      id="menu-button"
                                      icon  
                                      menuItems={[
                                        <ListItem id="viewCtxMenu" key={1} primaryText="View" onClick={() => viewStream('' + stack_name + '')}/>,
                                        // <ListItem id="enableCtxMenu" key={2} primaryText={enabledLabel} onClick={() => enableDisableStack('' + stack_name + '', enabledLabel)} disabled={disableMenu}/>,
                                       <ListItem id="enableCtxMenu" key={2} primaryText={enabledLabel} onClick={() => enableDisableStack('' + stack_name + '', enabledLabel,services.recorder1.camera_type,configure)} disabled={disableMenu}/>,
                                        // <ListItem id="deleteCtxMenu" key={3} primaryText="Delete" onClick={() => deleteStack('' + stack_name + '')}/>
                                      ]}
                                      centered
                                      anchor={{
                                        x: MenuButton.VerticalAnchors.CENTER,
                                        y: MenuButton.VerticalAnchors.CENTER,
                                      }}
                                      style={{"margin-top": "-9px"}}
                                    >
                                        <ThreeDotMenu />
                                    </MenuButton>
                                </RightDiv>
                            </StreamContentDiv>
                        </StyledCard>
                    )
                })}
                </StyledGridList></>
                    </TabPanel>
                    <Row>
                    {showMap && 
                    <TabPanel tabId="map">
                        {selectedTab === 'map' ? <>
                            <div id="map" style={{'marginTop': '20px'}}>
                                <MapContainer
                                    scrollWheelZoom={true}
                                    maxBounds={mapMaxBounds}
                                    minZoom={1}
                                    maxBoundsViscosity={1}
                                >
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                                    {(locationInputs.enabled_location && position !== undefined) ?
                                        <Marker
                                            position={position}
                                            icon={new Icon({ iconUrl: markerIconMap, iconSize: [25, 41], iconAnchor: [12, 41] })}
                                            draggable={false}
                                            eventHandlers={{
                                                click: onDeviceMarkerClick
                                            }}
                                        >
                                            <Tooltip style={{ 'font-size': '15px' }}>This Device</Tooltip>
                                        </Marker>
                                    : null}
                                    <LeafletConsumer readOnly={true} devicePosition={position} isStreamPage={true} {...{stackList, streamStatusData, mapViewType, onMapDragEnd, getMapCurrentView}} />
                                </MapContainer>
                            </div>
                            <CheckboxContainer style={{'display': 'flex', 'flex-wrap': 'wrap', "align-items": "baseline", 'marginTop': '10px', 'marginLeft': '6px'}} isDisabled={stackListLength === 0}>
                                <InputCheck type="checkbox" id="center_map" name="center_map" disabled={stackListLength === 0} checked={mapViewCheckboxes.streamsCheckbox} onChange={onStreamCheckbox} style={{marginTop: '10px'}}/>&nbsp;Show all camera locations on the map
                            </CheckboxContainer>
                            <CheckboxContainer isDisabled={!locationInputs.enabled_location} style={{'display': 'flex', 'flex-wrap': 'wrap', "align-items": "baseline", 'marginLeft': '6px'}}>
                                <InputCheck type="checkbox" id="center_map" name="center_map" checked={mapViewCheckboxes.deviceCheckbox} disabled={!locationInputs.enabled_location} onChange={onDeviceCheckbox} style={{marginTop: '10px'}}/>&nbsp;Move the map to show the device location
                            </CheckboxContainer>
                        </> : null}
                    </TabPanel>}
                    </Row>
                </Tabs>
              </StreamContainer>
            </Wrapper>
            }
        </ContentDiv>
    );
}

export default StreamList;