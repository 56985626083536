import React , { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components';
import axios from 'axios';
import * as Constants from '../utils/Constants';


const DeviceStatusContainer=styled.div`
    padding-right: 15px;
    padding-top: 10px;
    margin-left: 8px;
`;

const TooltipMessage=styled.span`
    font-weight: bold;
    font-size: 1.0em;
    color: black; 
`;

const ReconciliationStatus=styled.a`
    font-weight: bold;
    font-size: 1.2em;
    color: orange;
    cursor: pointer;
`;

const ResetDeviceStatus=styled.a`
    font-weight: bold;
    font-size: 1.2em;
    color: orange;
    cursor: pointer;
`;

const FailedStatus=styled.a`
    font-weight: bold;
    font-size: 1.2em;
    color: red;
    cursor: pointer;
`;

function DeviceHealthStatus(props) {
    const [healthStatus, sethealthStatus] = useState();
    const [devicereset, setDeviceReset] = useState(true);
    const [pageLoading, setPageloading] = useState(true);

    useEffect(() =>{
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/status')
        .then(res =>{
            sethealthStatus(res.data['data']);
        })
        .catch(err => {
            console.log(err)
        })
    }, []);

    // const getResetStatus = useCallback(() => {
    //     let interval;
    //     interval = setInterval(() => {
    //       axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_reset')
    //       .then(res => {
    //         setPageloading(false);
    //         if(res.data.message === 'Not Running') {
    //             setDeviceReset(false);
    //             clearInterval(interval);
    //         } else {
    //             setDeviceReset(true);
    //         }
    //       })
    //       .catch(error => {
    //         console.error(error);
    //         setDeviceReset(false);
    //         setPageloading(false);
    //       });
    //     }, 3000);
    //   },[]);

    //   useEffect(() => {
    //     if (devicereset) {
    //       getResetStatus();
    //     }
    //   }, [devicereset]);
    
    return (
        <div className='deviceHealthStatus'>
            { (healthStatus && healthStatus.status === 'failed') &&
                <DeviceStatusContainer>
                    <FailedStatus title={healthStatus.message} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                </DeviceStatusContainer>
            }
            { (healthStatus && healthStatus.status === 'recovering') && 
                <DeviceStatusContainer>
                    <ReconciliationStatus title={healthStatus.message} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['recovering']}</ReconciliationStatus>
                </DeviceStatusContainer>
            }
            {/* {!pageLoading && 
                <>
                    {devicereset && 
                        <DeviceStatusContainer>
                            <ResetDeviceStatus title='Device Reset currently in progress' data-tip data-for='tooltip'>Device reset in progress</ResetDeviceStatus>
                        </DeviceStatusContainer>}
                </>} */}
        </div>
    )
}

export default DeviceHealthStatus
