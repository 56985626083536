import { useEffect, useCallback, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { isEqual } from 'lodash';

function TabLeafletConsumer({ onDoubleClkPosition = () => {}, mapViewType, streamPosition = [], devicePosition = [], isStreamEnabled = false, onMapDragEnd = () => {}, getMapCurrentView = () => {}, }) {
  const map = useMap();
  const streamPositionRef = useRef();
  const devicePositionRef = useRef();
  const mapViewTypeRef = useRef();
  const isStreamEnabledRef = useRef(isStreamEnabled);
  const isProgramaticUncheck = useRef(false);
  const isProgramaticZoom = useRef(false);
  const isMount = useRef(true);

  // for map drag-end event
  const handleMapDragEnd = useCallback(() => {
    isProgramaticUncheck.current = true;
    onMapDragEnd();
    setTimeout(() => {
      isProgramaticUncheck.current = false;
    }, 200);
  }, [onMapDragEnd]);

  useEffect(() => {
    map.on('dragend', handleMapDragEnd);
    return () => {
      map.removeEventListener('dragend', handleMapDragEnd)
    }
  }, [map, handleMapDragEnd]);

  const getBoundingList = useCallback((mapViewType, streamLatLng, devicePosition, isStreamEnabled) => {
    let boundingList = [];
    if (mapViewType === 'Device') {
      if (devicePosition !== undefined) {
        boundingList.push(L.marker(devicePosition));
      }
      if (isStreamEnabled) {
        boundingList.push(L.marker(streamLatLng));
      }
    } else if (mapViewType === 'NoDevice') {
      boundingList.push(L.marker(streamLatLng));
    }
    return boundingList;
  }, []);

  const shouldBoundingUncheck = useCallback(() => {
    return new Promise((resolve, reject) => {
      let isUncheck = true;
      setTimeout(() => {
        const boundingList = getBoundingList(mapViewTypeRef.current, streamPositionRef.current, devicePositionRef.current, isStreamEnabledRef.current)
        if (boundingList.length !== 0) {
          const group = new L.featureGroup(boundingList);
          const glatlngBounds = group.getBounds();
          let prevMapZoom = map.getZoom();
          let maxBoundsZoom = map.getBoundsZoom(glatlngBounds);
          if (prevMapZoom <= maxBoundsZoom) {
            isUncheck = false;
            map.fitBounds(glatlngBounds, {maxZoom: prevMapZoom});
          } else {
            isUncheck = true;
          }
        }
        resolve(isUncheck);
      }, 100);
    });
  }, [map, getBoundingList]);

  // for map zoom-end event
  const handleMapZoomStart = useCallback(async () => {
    isProgramaticUncheck.current = true;
    if (!isProgramaticZoom.current) {
      localStorage.setItem('viewStreamMapZoom', map.getZoom());
      const isUncheck = await shouldBoundingUncheck();
      if (isUncheck) {
        onMapDragEnd();
      }
    }
    setTimeout(() => {
      isProgramaticUncheck.current = false;
    }, 200);
  }, [onMapDragEnd, shouldBoundingUncheck, map]);

  useEffect(() => {
    map.addEventListener('zoomend', handleMapZoomStart);
    return () => {
      map.removeEventListener('zoomend', handleMapZoomStart)
    }
  }, [map, handleMapZoomStart]);

  const handleLocalSave = useCallback(() => {
    let mapCurrentView = {latLng: map.getCenter(), zoom: map.getZoom()};
    if (!isMount.current) {
      localStorage.setItem('viewStreamMapView', JSON.stringify(mapCurrentView));
      localStorage.setItem('keepRedirectedMapView', JSON.stringify({keep: true, ...mapCurrentView}));
    } else {
      isMount.current = false;
    }
    getMapCurrentView(mapCurrentView);
  }, [map, getMapCurrentView]);

  useEffect(() => {
    map.addEventListener('moveend zoomend', handleLocalSave);
    return () => {
      map.removeEventListener('moveend zoomend', handleLocalSave)
    }
  }, [map, handleLocalSave]);

  // for changing device location on double click in map
  const handleDoubleClick = useCallback((e) => {
    onDoubleClkPosition(e.latlng);
  }, [onDoubleClkPosition]);

  useEffect(() => {
    map.on('dblclick', handleDoubleClick);
    return () => {
      map.removeEventListener('dblclick', handleDoubleClick)
    }
  }, [map, handleDoubleClick]);

  const setMapBounds = useCallback((streamLatLng = [], deviceLatLng = [], isboundDevice) => {
    if (isProgramaticUncheck.current) {
      return;
    }
    isProgramaticZoom.current = true;
    let boundingList = [];
    if (isboundDevice) {
      boundingList.push(L.marker(deviceLatLng));
    }
    if (isStreamEnabled) {
      boundingList.push(L.marker(streamLatLng));
    }
    if (boundingList.length === 0) {      
      const defaultMapView = {latLng: L.latLng(38.16911, 137.79403), zoom: 5};
      const localView = JSON.parse(localStorage.getItem('viewStreamMapView'));
      if (localView !== null) {
        map.setView(localView.latLng, localView.zoom);
      } else {
        map.setView(defaultMapView.latLng, defaultMapView.zoom);
      }
      localStorage.setItem('viewStreamMapView', JSON.stringify({latLng: map.getCenter(), zoom: map.getZoom()}));
      localStorage.setItem('viewStreamMapZoom', map.getZoom());
      return;
    }
    const group = new L.featureGroup(boundingList);
    const glatlngBounds = group.getBounds();
    let prevMapZoom = map.getZoom();
    if (prevMapZoom === undefined || prevMapZoom > 5) {
      prevMapZoom = 5;
    }
    const localZoom = localStorage.getItem('viewStreamMapZoom');
    if (isMount && localZoom !== null) {
      prevMapZoom = parseInt(localZoom);
    }
    let maxBoundsZoom = map.getBoundsZoom(glatlngBounds);
    let maxZoom;
    if (prevMapZoom <= maxBoundsZoom) {
      maxZoom = prevMapZoom;
    } else {
      maxZoom = maxBoundsZoom;
    }
    if (!isMounted.current) {
      const isInside = map.getBounds().contains(glatlngBounds);
      if (!isInside) {
        map.fitBounds(glatlngBounds, {maxZoom});
      }
    } else {
      map.fitBounds(glatlngBounds, {maxZoom});
    }
    localStorage.setItem('viewStreamMapView', JSON.stringify({latLng: map.getCenter(), zoom: map.getZoom()}));
    localStorage.setItem('viewStreamMapZoom', map.getZoom());
    setTimeout(() => {
      isProgramaticZoom.current = false;
    }, 100);
  }, [map, isStreamEnabled, isMount]);

  useEffect(() => {
    if (!isStreamEnabled) {
      isStreamEnabledRef.current = undefined;
      return;
    }
  }, [isStreamEnabled]);

  useEffect(() => {
    return () => {
      if (localStorage.getItem('viewStreamMapView') !== null) {
        localStorage.removeItem('viewStreamMapView')
      }
      if (localStorage.getItem('keepRedirectedMapView') !== null) {
        localStorage.removeItem('keepRedirectedMapView')
      }
    };
  }, []);

  const isMounted = useRef(true);

  const handleUncheckOnRedirection = useCallback((devicePosition) => {
    const isInside = map.getBounds().contains(devicePosition.map(item => parseFloat(item)));
    if (!isInside) {
      onMapDragEnd();
    }
  }, [map, onMapDragEnd]);

  useEffect(() => {
    if (!isEqual(mapViewTypeRef.current, mapViewType) || !isEqual(streamPositionRef.current, streamPosition) || !isEqual(devicePositionRef.current, devicePosition) || !isEqual(isStreamEnabled, isStreamEnabledRef.current)) {
      streamPositionRef.current = streamPosition;
      devicePositionRef.current = devicePosition;
      mapViewTypeRef.current = mapViewType;
      isStreamEnabledRef.current = isStreamEnabled;

      const localRedirectedView = JSON.parse(localStorage.getItem('keepRedirectedMapView'));
      if (localRedirectedView !== null && window.location.search.includes('redirection=true') && isMounted.current) {
        const {keep, latLng, zoom} = localRedirectedView;
        if (keep) {
          isProgramaticZoom.current = true;
          map.setView(latLng, zoom);
          localStorage.setItem('keepRedirectedMapView', JSON.stringify({keep: true, latLng, zoom}));
          handleUncheckOnRedirection(devicePosition);
          setTimeout(() => {
            isProgramaticZoom.current = false;
          }, 100);
        }
        return;
      }
      if (mapViewType === 'Device') {
        setMapBounds(streamPosition, devicePosition, true);
      } else if (mapViewType === 'NoDevice') {
        setMapBounds(streamPosition, [], false);
      }
    }
    if (isEqual(mapViewTypeRef.current, mapViewType)) {
      isMounted.current = false;
    }
  }, [map, mapViewType, streamPosition, devicePosition, isStreamEnabled, setMapBounds, onMapDragEnd, handleUncheckOnRedirection]);

  return null;
}

export default TabLeafletConsumer;