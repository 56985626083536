import React, { useState, useCallback, ChangeEvent } from 'react';
import styled from 'styled-components';
import { ReactComponent as TagSVG } from '../images/tag_black.svg';
import { ReactComponent as CrossSVG } from '../images/cross.svg';


const AppLogo = styled(TagSVG)`
    height: 60px;
    object-fit: contain;
    cursor: pointer;
`;

const CrossIcon = styled(CrossSVG)`
    height: 60px;
    object-fit: contain;
    cursor: pointer;
`;

const TagInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  max-width: 100%;
  padding: 0 8px;
  border: 1px solid hsla(120,1.3%,85.3%,1.000);
  color: hsla(207,5%,56.7%,1.000);
  border-radius: 3px;
  background: ${props => props.theme === true ? '#f2f2f2' : ''};
  &:focus {
    outline: none;
    border-color: 1px solid hsla(120,1.3%,85.3%,1.000);
  }
`;

const TagList = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: ${props => props.theme === true ? '0' : '0 0 5px 0'};
  margin: ${props => props.theme === true ? '0' : '8px 0 0 0'};
  ::-webkit-scrollbar {
    display: none;
  }
  line-height: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 5px;
  scroll-behavior: smooth;
`;

const TagListName = styled.li`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(96, 96, 96);
  padding: 0 8px;
  font-size: 12px;
  list-style: none;
  border-radius: 15px;
  margin: 0 5px 2px 0;
  background: transperent;
  border: 1px solid #A8B5BD;

`;
const TagListTitle = styled.span`
  margin: 3px;
  padding-right: 2px;
  padding-left: 2px;
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width:100px;
`;

const TagListClose = styled.span`
  margin-top:5px;
  cursor: pointer;
  pointer-events: ${props => props.theme === true ? 'none' : 'auto'};
`;

const Container = styled.div`
  max-width:100%;
  position: relative;
  height: 100%;
  outline: none;
  margin-top: 20px;
`;

const IconBox = styled.div`
  width:18px;
  height:18px;
  margin-right:5px;
  display:flex;
  align-items:center;
`;

const IconWrapper = styled.div`
  margin-top: 5px;
`;

const InputContainer = styled.div`
  width: min-content;
  min-width: 50px;
  flex-grow: 1;
  & > input {
    width: 100%;
  }
  input {
    flex: 1;
    border: none;
    font-size: 14px;
    border-radius: 3px;
    padding-top: ${props => props.theme === true ? '10px' : '0'};
    &:focus {
      outline: transparent;
    }
    ::placeholder {
      color: hsla(207,5%,56.7%,1.000);
      opacity: 0.65;
      font-size: 12px;
      font-style: italic;
    }
  }
`;
const TagsInput = (props) => {

  const { newTags, tags, disabled, enteredTag } = props;
  const [tagName, setTagName] = useState(tags);
  const [expanded, setExpanded] = useState(false);

  const removeTags = useCallback((indexToRemove) => {
    setTagName([...tagName.filter((_, index) => index !== indexToRemove)]);
    newTags([...tagName.filter((_, index) => index !== indexToRemove)]);
  },[tagName]);

  const addTags = (event) => {
    const tagValues = event.target.value.trim();
    if (tagValues !== '') {
      enteredTag(event.target.value);
    }
    if (event.key === 'Enter' && tagValues !== '') {
      setTagName([...tagName, event.target.value]);
      newTags([...tagName, event.target.value]);
      event.target.value = '';
      enteredTag('');
    }
  };
  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onBlur = useCallback((e) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        console.log('component officially blurred');
        setExpanded(false);
      }
    }, 0);
  }, []);

  
  return (
    <Container tabIndex={0} onBlur={onBlur}>
      <TagInputWrapper theme={disabled}>
        <TagList theme={tagName.length === 0}>
          {tagName.map((tagName, index) => (
            <TagListName key={index}>
              <IconWrapper>
                  <AppLogo />
              </IconWrapper>
              <TagListTitle title={tagName}>{tagName}</TagListTitle>
              <TagListClose theme={disabled} onClick={() => removeTags(index)}><CrossIcon /></TagListClose>
            </TagListName>
          ))}
          <InputContainer theme={tagName.length === 0}>
          <input
            type='text'
            onKeyUp={event => addTags(event)}
            onClick={toggle}
            maxLength={64}
            disabled={disabled}
            style={{'display': disabled ? 'none' : '', 'pointerEvents': disabled ? 'none' : 'auto'}}
            placeholder={disabled ? '' :'+Tags'}
        />
          </InputContainer>
        </TagList>
      </TagInputWrapper>
    </Container>
  );
};

export default TagsInput;