import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link1, LinkIcon } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import axios from 'axios';
import {OnRefreshContext} from './Metabase';


const MetabaseLink = (props) => { 
    const {onRefresh} = useContext(OnRefreshContext);
    const [status, setStatus] = useState('');

    const getmetDetailsStatus = useCallback(() => {
        if(onRefresh) {
          return;  
        }
        const interval = setInterval(() => {
            axios.get(Constants.EDGE_METABASE + props.port + '/')
            .then((res) => {
                if(res.status === 200) {
                    setStatus(res.status);
                    clearInterval(interval)
                } else {
                    setStatus(res.status);
                }
            }).catch(err => {
            console.log(err)
            })
        }, 1000);
    },[onRefresh]);

    useEffect(() => {
        getmetDetailsStatus();
    },[getmetDetailsStatus]);

    return(
        <div>
            {props.enabled ? 
            <>
            {status !== 200 ? 
            <div style={{'display': 'flex', 'align-items': 'center', 'justifyContent': 'center'}}>
                <div class="spinner-1" ></div>
                <div>&nbsp; &nbsp; Fetching dashboard link... </div>
            </div>:  
            <>
                <LinkIcon />
                <Link1 target="_blank" href={window.location.protocol + '//' + props.path + '/metabase-' + props.port}>Open Dashboard</Link1>
            </>}
            </> : '-' }
        </div>
    );
}

export default MetabaseLink;