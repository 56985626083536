import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link1, LinkIcon } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import axios from 'axios';
import {OnRefreshContextRDB} from './Rdb';


const RDBLink = (props) => { 
    const {onRefresh} = useContext(OnRefreshContextRDB);
    const [status, setStatus] = useState(false);

    const getmetDetailsStatus = useCallback(() => {
        if(onRefresh) {
          return;  
        }
        const interval = setInterval(() => {
            axios.get(Constants.EDGE_RDB + props.port)
            .then((res) => {
                if(res.status === 200 && props.rdbStatus === 'running') {
                    setStatus(true);
                    clearInterval(interval)
                } else {
                    setStatus(false);
                }
            }).catch(err => {
            console.log(err)
            setStatus(false);
            })
        }, 1000);
    },[onRefresh, props.rdbStatus]);

    useEffect(() => {
        getmetDetailsStatus();
    },[getmetDetailsStatus]);

    return(
        <div>
            {props.enabled ? 
            <>
            {!status ? 
            <div style={{'display': 'flex', 'align-items': 'center', 'justifyContent': 'center'}}>
                <div class="spinner-1" ></div>
                <div>&nbsp; &nbsp; Fetching dashboard link... </div>
            </div>:  
            <>
                <LinkIcon />
                <Link1 target="_blank" href={Constants.EDGE_RDB + props.port + '/adminer.php?server=' + props.adminerServerParam +  '&username=' + props.username}>Open Dashboard</Link1>
            </>}
            </> : '-' }
        </div>
    );
}

export default RDBLink;