export const TIMEZONE = [
  {'value':'Africa/Abidjan'               ,       'name': 'Africa/Abidjan'},
  {'value':'Africa/Accra'                 ,       'name': 'Africa/Accra'},
  {'value':'Africa/Addis_Ababa'           ,       'name': 'Africa/Addis_Ababa'},
  {'value':'Africa/Algiers'               ,       'name': 'Africa/Algiers'},
  {'value':'Africa/Asmara'                ,       'name': 'Africa/Asmara'},
  {'value':'Africa/Bamako'                ,       'name': 'Africa/Bamako'},
  {'value':'Africa/Bangui'                ,       'name': 'Africa/Bangui'},
  {'value':'Africa/Banjul'                ,       'name': 'Africa/Banjul'},
  {'value':'Africa/Bissau'                ,       'name': 'Africa/Bissau'},
  {'value':'Africa/Blantyre'              ,       'name': 'Africa/Blantyre'},
  {'value':'Africa/Brazzaville'           ,       'name': 'Africa/Brazzaville'},
  {'value':'Africa/Bujumbura'             ,       'name': 'Africa/Bujumbura'},
  {'value':'Africa/Cairo'                 ,       'name': 'Africa/Cairo'},
  {'value':'Africa/Casablanca'            ,       'name': 'Africa/Casablanca'},
  {'value':'Africa/Ceuta'                 ,       'name': 'Africa/Ceuta'},
  {'value':'Africa/Conakry'               ,       'name': 'Africa/Conakry'},
  {'value':'Africa/Dakar'                 ,       'name': 'Africa/Dakar'},
  {'value':'Africa/Dar_es_Salaam'         ,       'name': 'Africa/Dar_es_Salaam'},
  {'value':'Africa/Djibouti'              ,       'name': 'Africa/Djibouti'},
  {'value':'Africa/Douala'                ,       'name': 'Africa/Douala'},
  {'value':'Africa/El_Aaiun'              ,       'name': 'Africa/El_Aaiun'},
  {'value':'Africa/Freetown'              ,       'name': 'Africa/Freetown'},
  {'value':'Africa/Gaborone'              ,       'name': 'Africa/Gaborone'},
  {'value':'Africa/Harare'                ,       'name': 'Africa/Harare'},
  {'value':'Africa/Johannesburg'          ,       'name': 'Africa/Johannesburg'},
  {'value':'Africa/Juba'                  ,       'name': 'Africa/Juba'},
  {'value':'Africa/Kampala'               ,       'name': 'Africa/Kampala'},
  {'value':'Africa/Khartoum'              ,       'name': 'Africa/Khartoum'},
  {'value':'Africa/Kigali'                ,       'name': 'Africa/Kigali'},
  {'value':'Africa/Kinshasa'              ,       'name': 'Africa/Kinshasa'},
  {'value':'Africa/Lagos'                 ,       'name': 'Africa/Lagos'},
  {'value':'Africa/Libreville'            ,       'name': 'Africa/Libreville'},
  {'value':'Africa/Lome'                  ,       'name': 'Africa/Lome'},
  {'value':'Africa/Luanda'                ,       'name': 'Africa/Luanda'},
  {'value':'Africa/Lubumbashi'            ,       'name': 'Africa/Lubumbashi'},
  {'value':'Africa/Lusaka'                ,       'name': 'Africa/Lusaka'},
  {'value':'Africa/Malabo'                ,       'name': 'Africa/Malabo'},
  {'value':'Africa/Maputo'                ,       'name': 'Africa/Maputo'},
  {'value':'Africa/Maseru'                ,       'name': 'Africa/Maseru'},
  {'value':'Africa/Mbabane'               ,       'name': 'Africa/Mbabane'},
  {'value':'Africa/Mogadishu'             ,       'name': 'Africa/Mogadishu'},
  {'value':'Africa/Monrovia'              ,       'name': 'Africa/Monrovia'},
  {'value':'Africa/Nairobi'               ,       'name': 'Africa/Nairobi'},
  {'value':'Africa/Ndjamena'              ,       'name': 'Africa/Ndjamena'},
  {'value':'Africa/Niamey'                ,       'name': 'Africa/Niamey'},
  {'value':'Africa/Nouakchott'            ,       'name': 'Africa/Nouakchott'},
  {'value':'Africa/Ouagadougou'           ,       'name': 'Africa/Ouagadougou'},
  {'value':'Africa/Porto-Novo'            ,       'name': 'Africa/Porto-Novo'},
  {'value':'Africa/Sao_Tome'              ,       'name': 'Africa/Sao_Tome'},
  {'value':'Africa/Tripoli'               ,       'name': 'Africa/Tripoli'},
  {'value':'Africa/Tunis'                 ,       'name': 'Africa/Tunis'},
  {'value':'Africa/Windhoek'              ,       'name': 'Africa/Windhoek'},
  {'value':'America/Adak'                 ,       'name': 'America/Adak'},
  {'value':'America/Anchorage'            ,       'name': 'America/Anchorage'},
  {'value':'America/Anguilla'             ,       'name': 'America/Anguilla'},
  {'value':'America/Antigua'              ,       'name': 'America/Antigua'},
  {'value':'America/Araguaina'            ,       'name': 'America/Argentina'},
  {'value':'America/Argentina/Buenos_Aires',       'name': 'America/Argentina/Buenos_Aires'},
  {'value':'America/Argentina/Catamarca'  ,       'name': 'America/Argentina/Catamarca'},
  {'value':'America/Argentina/Cordoba'    ,       'name': 'America/Argentina/Cordoba'},
  {'value':'America/Argentina/Jujuy'      ,       'name': 'America/Argentina/Jujuy'},
  {'value':'America/Argentina/La_Rioja'   ,       'name': 'America/Argentina/La_Rioja'},
  {'value':'America/Argentina/Mendoza'    ,       'name': 'America/Argentina/Mendoza'},
  {'value':'America/Argentina/Rio_Gallegos',       'name': 'America/Argentina/Rio_Gallegos'},
  {'value':'America/Argentina/Salta'      ,       'name': 'America/Argentina/Salta'},
  {'value':'America/Argentina/San_Juan'   ,       'name': 'America/Argentina/San_Juan'},
  {'value':'America/Argentina/San_Luis'   ,       'name': 'America/Argentina/San_Luis'},
  {'value':'America/Argentina/Tucuman'    ,       'name': 'America/Argentina/Tucuman'},
  {'value':'America/Argentina/Ushuaia'    ,       'name': 'America/Argentina/Ushuaia'},
  {'value':'America/Aruba'                ,       'name': 'America/Aruba'},
  {'value':'America/Asuncion'             ,       'name': 'America/Asuncion'},
  {'value':'America/Atikokan'             ,       'name': 'America/Atikokan'},
  {'value':'America/Bahia'                ,       'name': 'America/Bahia'},
  {'value':'America/Bahia_Banderas'       ,       'name': 'America/Bahia_Banderas'},
  {'value':'America/Barbados'             ,       'name': 'America/Barbados'},
  {'value':'America/Belem'                ,       'name': 'America/Belem'},
  {'value':'America/Belize'               ,       'name': 'America/Belize'},
  {'value':'America/Blanc-Sablon'         ,       'name': 'America/Blanc-Sablon'},
  {'value':'America/Boa_Vista'            ,       'name': 'America/Boa_Vista'},
  {'value':'America/Bogota'               ,       'name': 'America/Bogota'},
  {'value':'America/Boise'                ,       'name': 'America/Boise'},
  {'value':'America/Cambridge_Bay'        ,       'name': 'America/Cambridge_Bay'},
  {'value':'America/Campo_Grande'         ,       'name': 'America/Campo_Grande'},
  {'value':'America/Cancun'               ,       'name': 'America/Cancun'},
  {'value':'America/Caracas'              ,       'name': 'America/Caracas'},
  {'value':'America/Cayenne'              ,       'name': 'America/Cayenne'},
  {'value':'America/Cayman'               ,       'name': 'America/Cayman'},
  {'value':'America/Chicago'              ,       'name': 'America/Chicago'},
  {'value':'America/Chihuahua'            ,       'name': 'America/Chihuahua'},
  {'value':'America/Costa_Rica'           ,       'name': 'America/Costa_Rica'},
  {'value':'America/Creston'              ,       'name': 'America/Creston'},
  {'value':'America/Cuiaba'               ,       'name': 'America/Cuiaba'},
  {'value':'America/Curacao'              ,       'name': 'America/Curacao'},
  {'value':'America/Danmarkshavn'         ,       'name': 'America/Danmarkshavn'},
  {'value':'America/Dawson'               ,       'name': 'America/Dawson'},
  {'value':'America/Dawson_Creek'         ,       'name': 'America/Dawson_Creek'},
  {'value':'America/Denver'               ,       'name': 'America/Denver'},
  {'value':'America/Detroit'              ,       'name': 'America/Detroit'},
  {'value':'America/Dominica'             ,       'name': 'America/Dominica'},
  {'value':'America/Edmonton'             ,       'name': 'America/Edmonton'},
  {'value':'America/Eirunepe'             ,       'name': 'America/Eirunepe'},
  {'value':'America/El_Salvador'          ,       'name': 'America/El_Salvador'},
  {'value':'America/Fort_Nelson'          ,       'name': 'America/Fort_Nelson'},
  {'value':'America/Fortaleza'            ,       'name': 'America/Fortaleza'},
  {'value':'America/Glace_Bay'            ,       'name': 'America/Glace_Bay'},
  {'value':'America/Goose_Bay'            ,       'name': 'America/Goose_Bay'},
  {'value':'America/Grand_Turk'           ,       'name': 'America/Grand_Turk'},
  {'value':'America/Grenada'              ,       'name': 'America/Grenada'},
  {'value':'America/Guadeloupe'           ,       'name': 'America/Guadeloupe'},
  {'value':'America/Guatemala'            ,       'name': 'America/Guatemala'},
  {'value':'America/Guayaquil'            ,       'name': 'America/Guayaquil'},
  {'value':'America/Guyana'               ,       'name': 'America/Guyana'},
  {'value':'America/Halifax'              ,       'name': 'America/Halifax'},
  {'value':'America/Havana'               ,       'name': 'America/Havana'},
  {'value':'America/Hermosillo'           ,       'name': 'America/Hermosillo'},
  {'value':'America/Indiana/Indianapolis' ,       'name': 'America/Indiana/Indianapolis'},
  {'value':'America/Indiana/Knox'         ,       'name': 'America/Indiana/Knox'},
  {'value':'America/Indiana/Marengo'      ,       'name': 'America/Indiana/Marengo'},
  {'value':'America/Indiana/Petersburg'   ,       'name': 'America/Indiana/Petersburg'},
  {'value':'America/Indiana/Tell_City'    ,       'name': 'America/Indiana/Tell_City'},
  {'value':'America/Indiana/Vevay'        ,       'name': 'America/Indiana/Vevay'},
  {'value':'America/Indiana/Vincennes'    ,       'name': 'America/Indiana/Vincennes'},
  {'value':'America/Indiana/Winamac'      ,       'name': 'America/Indiana/Winamac'},
  {'value':'America/Inuvik'               ,       'name': 'America/Inuvik'},
  {'value':'America/Iqaluit'              ,       'name': 'America/Iqaluit'},
  {'value':'America/Jamaica'              ,       'name': 'America/Jamaica'},
  {'value':'America/Juneau'               ,       'name': 'America/Juneau'},
  {'value':'America/Kentucky/Louisville'  ,       'name': 'America/Kentucky/Louisville'},
  {'value':'America/Kentucky/Monticello'  ,       'name': 'America/Kentucky/Monticello'},
  {'value':'America/Kralendijk'           ,       'name': 'America/Kralendijk'},
  {'value':'America/La_Paz'               ,       'name': 'America/La_Paz'},
  {'value':'America/Lima'                 ,       'name': 'America/Lima'},
  {'value':'America/Los_Angeles'          ,       'name': 'America/Los_Angeles'},
  {'value':'America/Lower_Princes'        ,       'name': 'America/Lower_Princes'},
  {'value':'America/Maceio'               ,       'name': 'America/Maceio'},
  {'value':'America/Managua'              ,       'name': 'America/Managua'},
  {'value':'America/Manaus'               ,       'name': 'America/Manaus'},
  {'value':'America/Marigot'              ,       'name': 'America/Marigot'},
  {'value':'America/Martinique'           ,       'name': 'America/Martinique'},
  {'value':'America/Matamoros'            ,       'name': 'America/Matamoros'},
  {'value':'America/Mazatlan'             ,       'name': 'America/Mazatlan'},
  {'value':'America/Menominee'            ,       'name': 'America/Menominee'},
  {'value':'America/Merida'               ,       'name': 'America/Merida'},
  {'value':'America/Metlakatla'           ,       'name': 'America/Metlakatla'},
  {'value':'America/Mexico_City'          ,       'name': 'America/Mexico_City'},
  {'value':'America/Miquelon'             ,       'name': 'America/Miquelon'},
  {'value':'America/Moncton'              ,       'name': 'America/Moncton'},
  {'value':'America/Monterrey'            ,       'name': 'America/Monterrey'},
  {'value':'America/Montevideo'           ,       'name': 'America/Montevideo'},
  {'value':'America/Montserrat'           ,       'name': 'America/Montserrat'},
  {'value':'America/Nassau'               ,       'name': 'America/Nassau'},
  {'value':'America/New_York'             ,       'name': 'America/New_York'},
  {'value':'America/Nipigon'              ,       'name': 'America/Nipigon'},
  {'value':'America/Nome'                 ,       'name': 'America/Nome'},
  {'value':'America/Noronha'              ,       'name': 'America/Noronha'},
  {'value':'America/North_Dakota/Beulah'  ,       'name': 'America/North_Dakota/Beulah'},
  {'value':'America/North_Dakota/Center'  ,       'name': 'America/North_Dakota/Center'},
  {'value':'America/North_Dakota/New_Salem',       'name': 'America/North_Dakota/New_Salem'},
  {'value':'America/Nuuk'                 ,       'name': 'America/Nuuk'},
  {'value':'America/Ojinaga'              ,       'name': 'America/Ojinaga'},
  {'value':'America/Panama'               ,       'name': 'America/Panama'},
  {'value':'America/Pangnirtung'          ,       'name': 'America/Pangnirtung'},
  {'value':'America/Paramaribo'           ,       'name': 'America/Paramaribo'},
  {'value':'America/Phoenix'              ,       'name': 'America/Phoenix'},
  {'value':'America/Port-au-Prince'       ,       'name': 'America/Port-au-Prince'},
  {'value':'America/Port_of_Spain'        ,       'name': 'America/Port_of_Spain'},
  {'value':'America/Porto_Velho'          ,       'name': 'America/Porto_Velho'},
  {'value':'America/Puerto_Rico'          ,       'name': 'America/Puerto_Rico'},
  {'value':'America/Punta_Arenas'         ,       'name': 'America/Punta_Arenas'},
  {'value':'America/Rainy_River'          ,       'name': 'America/Rainy_River'},
  {'value':'America/Rankin_Inlet'         ,       'name': 'America/Rankin_Inlet'},
  {'value':'America/Recife'               ,       'name': 'America/Recife'},
  {'value':'America/Regina'               ,       'name': 'America/Regina'},
  {'value':'America/Resolute'             ,       'name': 'America/Resolute'},
  {'value':'America/Rio_Branco'           ,       'name': 'America/Rio_Branco'},
  {'value':'America/Santarem'             ,       'name': 'America/Santarem'},
  {'value':'America/Santiago'             ,       'name': 'America/Santiago'},
  {'value':'America/Santo_Domingo'        ,       'name': 'America/Santo_Domingo'},
  {'value':'America/Sao_Paulo'            ,       'name': 'America/Sao_Paulo'},
  {'value':'America/Scoresbysund'         ,       'name': 'America/Scoresbysund'},
  {'value':'America/Sitka'                ,       'name': 'America/Sitka'},
  {'value':'America/St_Barthelemy'        ,       'name': 'America/St_Barthelemy'},
  {'value':'America/St_Johns'             ,       'name': 'America/St_Johns'},
  {'value':'America/St_Kitts'             ,       'name': 'America/St_Kitts'},
  {'value':'America/St_Lucia'             ,       'name': 'America/St_Lucia'},
  {'value':'America/St_Thomas'            ,       'name': 'America/St_Thomas'},
  {'value':'America/St_Vincent'           ,       'name': 'America/St_Vincent'},
  {'value':'America/Swift_Current'        ,       'name': 'America/Swift_Current'},
  {'value':'America/Tegucigalpa'          ,       'name': 'America/Tegucigalpa'},
  {'value':'America/Thule'                ,       'name': 'America/Thule'},
  {'value':'America/Thunder_Bay'          ,       'name': 'America/Thunder_Bay'},
  {'value':'America/Tijuana'              ,       'name': 'America/Tijuana'},
  {'value':'America/Toronto'              ,       'name': 'America/Toronto'},
  {'value':'America/Tortola'              ,       'name': 'America/Tortola'},
  {'value':'America/Vancouver'            ,       'name': 'America/Vancouver'},
  {'value':'America/Whitehorse'           ,       'name': 'America/Whitehorse'},
  {'value':'America/Winnipeg'             ,       'name': 'America/Winnipeg'},
  {'value':'America/Yakutat'              ,       'name': 'America/Yakutat'},
  {'value':'America/Yellowknife'          ,       'name': 'America/Yellowknife'},
  {'value':'Antarctica/Casey'             ,       'name': 'Antarctica/Casey'},
  {'value':'Antarctica/Davis'             ,       'name': 'Antarctica/Davis'},
  {'value':'Antarctica/DumontDUrville'    ,       'name': 'Antarctica/DumontDUrville'},
  {'value':'Antarctica/Macquarie'         ,       'name': 'Antarctica/Macquarie'},
  {'value':'Antarctica/Mawson'            ,       'name': 'Antarctica/Mawson'},
  {'value':'Antarctica/McMurdo'           ,       'name': 'Antarctica/McMurdo'},
  {'value':'Antarctica/Palmer'            ,       'name': 'Antarctica/Palmer'},
  {'value':'Antarctica/Rothera'           ,       'name': 'Antarctica/Rothera'},
  {'value':'Antarctica/Syowa'             ,       'name': 'Antarctica/Syowa'},
  {'value':'Antarctica/Troll'             ,       'name': 'Antarctica/Troll'},
  {'value':'Antarctica/Vostok'            ,       'name': 'Antarctica/Vostok'},
  {'value':'Arctic/Longyearbyen'          ,       'name': 'Arctic/Longyearbyen'},
  {'value':'Asia/Aden'                    ,       'name': 'Asia/Aden'},
  {'value':'Asia/Almaty'                  ,       'name': 'Asia/Almaty'},
  {'value':'Asia/Amman'                   ,       'name': 'Asia/Amman'},
  {'value':'Asia/Anadyr'                  ,       'name': 'Asia/Anadyr'},
  {'value':'Asia/Aqtau'                   ,       'name': 'Asia/Aqtau'},
  {'value':'Asia/Aqtobe'                  ,       'name': 'Asia/Aqtobe'},
  {'value':'Asia/Ashgabat'                ,       'name': 'Asia/Ashgabat'},
  {'value':'Asia/Atyrau'                  ,       'name': 'Asia/Atyrau'},
  {'value':'Asia/Baghdad'                 ,       'name': 'Asia/Baghdad'},
  {'value':'Asia/Bahrain'                 ,       'name': 'Asia/Bahrain'},
  {'value':'Asia/Baku'                    ,       'name': 'Asia/Baku'},
  {'value':'Asia/Bangkok'                 ,       'name': 'Asia/Bangkok'},
  {'value':'Asia/Barnaul'                 ,       'name': 'Asia/Barnaul'},
  {'value':'Asia/Beirut'                  ,       'name': 'Asia/Beirut'},
  {'value':'Asia/Bishkek'                 ,       'name': 'Asia/Bishkek'},
  {'value':'Asia/Brunei'                  ,       'name': 'Asia/Brunei'},
  {'value':'Asia/Chita'                   ,       'name': 'Asia/Chita'},
  {'value':'Asia/Choibalsan'              ,       'name': 'Asia/Choibalsan'},
  {'value':'Asia/Colombo'                 ,       'name': 'Asia/Colombo'},
  {'value':'Asia/Damascus'                ,       'name': 'Asia/Damascus'},
  {'value':'Asia/Dhaka'                   ,       'name': 'Asia/Dhaka'},
  {'value':'Asia/Dili'                    ,       'name': 'Asia/Dili'},
  {'value':'Asia/Dubai'                   ,       'name': 'Asia/Dubai'},
  {'value':'Asia/Dushanbe'                ,       'name': 'Asia/Dushanbe'},
  {'value':'Asia/Famagusta'               ,       'name': 'Asia/Famagusta'},
  {'value':'Asia/Gaza'                    ,       'name': 'Asia/Gaza'},
  {'value':'Asia/Hebron'                  ,       'name': 'Asia/Hebron'},
  {'value':'Asia/Ho_Chi_Minh'             ,       'name': 'Asia/Ho_Chi_Minh'},
  {'value':'Asia/Hong_Kong'               ,       'name': 'Asia/Hong_Kong'},
  {'value':'Asia/Hovd'                    ,       'name': 'Asia/Hovd'},
  {'value':'Asia/Irkutsk'                 ,       'name': 'Asia/Irkutsk'},
  {'value':'Asia/Jakarta'                 ,       'name': 'Asia/Jakarta'},
  {'value':'Asia/Jayapura'                ,       'name': 'Asia/Jayapura'},
  {'value':'Asia/Jerusalem'               ,       'name': 'Asia/Jerusalem'},
  {'value':'Asia/Kabul'                   ,       'name': 'Asia/Kabul'},
  {'value':'Asia/Kamchatka'               ,       'name': 'Asia/Kamchatka'},
  {'value':'Asia/Karachi'                 ,       'name': 'Asia/Karachi'},
  {'value':'Asia/Kathmandu'               ,       'name': 'Asia/Kathmandu'},
  {'value':'Asia/Khandyga'                ,       'name': 'Asia/Khandyga'},
  {'value':'Asia/Kolkata'                 ,       'name': 'Asia/Kolkata'},
  {'value':'Asia/Krasnoyarsk'             ,       'name': 'Asia/Krasnoyarsk'},
  {'value':'Asia/Kuala_Lumpur'            ,       'name': 'Asia/Kuala_Lumpur'},
  {'value':'Asia/Kuching'                 ,       'name': 'Asia/Kuching'},
  {'value':'Asia/Kuwait'                  ,       'name': 'Asia/Kuwait'},
  {'value':'Asia/Macau'                   ,       'name': 'Asia/Macau'},
  {'value':'Asia/Magadan'                 ,       'name': 'Asia/Magadan'},
  {'value':'Asia/Makassar'                ,       'name': 'Asia/Makassar'},
  {'value':'Asia/Manila'                  ,       'name': 'Asia/Manila'},
  {'value':'Asia/Muscat'                  ,       'name': 'Asia/Muscat'},
  {'value':'Asia/Nicosia'                 ,       'name': 'Asia/Nicosia'},
  {'value':'Asia/Novokuznetsk'            ,       'name': 'Asia/Novokuznetsk'},
  {'value':'Asia/Novosibirsk'             ,       'name': 'Asia/Novosibirsk'},
  {'value':'Asia/Omsk'                    ,       'name': 'Asia/Omsk'},
  {'value':'Asia/Oral'                    ,       'name': 'Asia/Oral'},
  {'value':'Asia/Phnom_Penh'              ,       'name': 'Asia/Phnom_Penh'},
  {'value':'Asia/Pontianak'               ,       'name': 'Asia/Pontianak'},
  {'value':'Asia/Pyongyang'               ,       'name': 'Asia/Pyongyang'},
  {'value':'Asia/Qatar'                   ,       'name': 'Asia/Qatar'},
  {'value':'Asia/Qostanay'                ,       'name': 'Asia/Qostanay'},
  {'value':'Asia/Qyzylorda'               ,       'name': 'Asia/Qyzylorda'},
  {'value':'Asia/Riyadh'                  ,       'name': 'Asia/Riyadh'},
  {'value':'Asia/Sakhalin'                ,       'name': 'Asia/Sakhalin'},
  {'value':'Asia/Samarkand'               ,       'name': 'Asia/Samarkand'},
  {'value':'Asia/Seoul'                   ,       'name': 'Asia/Seoul'},
  {'value':'Asia/Shanghai'                ,       'name': 'Asia/Shanghai'},
  {'value':'Asia/Singapore'               ,       'name': 'Asia/Singapore'},
  {'value':'Asia/Srednekolymsk'           ,       'name': 'Asia/Srednekolymsk'},
  {'value':'Asia/Taipei'                  ,       'name': 'Asia/Taipei'},
  {'value':'Asia/Tashkent'                ,       'name': 'Asia/Tashkent'},
  {'value':'Asia/Tbilisi'                 ,       'name': 'Asia/Tbilisi'},
  {'value':'Asia/Tehran'                  ,       'name': 'Asia/Tehran'},
  {'value':'Asia/Thimphu'                 ,       'name': 'Asia/Thimphu'},
  {'value':'Asia/Tokyo'                   ,       'name': 'Asia/Tokyo'},
  {'value':'Asia/Tomsk'                   ,       'name': 'Asia/Tomsk'},
  {'value':'Asia/Ulaanbaatar'             ,       'name': 'Asia/Ulaanbaatar'},
  {'value':'Asia/Urumqi'                  ,       'name': 'Asia/Urumqi'},
  {'value':'Asia/Ust-Nera'                ,       'name': 'Asia/Ust-Nera'},
  {'value':'Asia/Vientiane'               ,       'name': 'Asia/Vientiane'},
  {'value':'Asia/Vladivostok'             ,       'name': 'Asia/Vladivostok'},
  {'value':'Asia/Yakutsk'                 ,       'name': 'Asia/Yakutsk'},
  {'value':'Asia/Yangon'                  ,       'name': 'Asia/Yangon'},
  {'value':'Asia/Yekaterinburg'           ,       'name': 'Asia/Yekaterinburg'},
  {'value':'Asia/Yerevan'                 ,       'name': 'Asia/Yerevan'},
  {'value':'Atlantic/Azores'              ,       'name': 'Atlantic/Azores'},
  {'value':'Atlantic/Bermuda'             ,       'name': 'Atlantic/Bermuda'},
  {'value':'Atlantic/Canary'              ,       'name': 'Atlantic/Canary'},
  {'value':'Atlantic/Cape_Verde'          ,       'name': 'Atlantic/Cape_Verde'},
  {'value':'Atlantic/Faroe'               ,       'name': 'Atlantic/Faroe'},
  {'value':'Atlantic/Madeira'             ,       'name': 'Atlantic/Madeira'},
  {'value':'Atlantic/Reykjavik'           ,       'name': 'Atlantic/Reykjavik'},
  {'value':'Atlantic/South_Georgia'       ,       'name': 'Atlantic/South_Georgia'},
  {'value':'Atlantic/St_Helena'           ,       'name': 'Atlantic/St_Helena'},
  {'value':'Atlantic/Stanley'             ,       'name': 'Atlantic/Stanley'},
  {'value':'Australia/Adelaide'           ,       'name': 'Australia/Adelaide'},
  {'value':'Australia/Brisbane'           ,       'name': 'Australia/Brisbane'},
  {'value':'Australia/Broken_Hill'        ,       'name': 'Australia/Broken_Hill'},
  {'value':'Australia/Darwin'             ,       'name': 'Australia/Darwin'},
  {'value':'Australia/Eucla'              ,       'name': 'Australia/Eucla'},
  {'value':'Australia/Hobart'             ,       'name': 'Australia/Hobart'},
  {'value':'Australia/Lindeman'           ,       'name': 'Australia/Lindeman'},
  {'value':'Australia/Lord_Howe'          ,       'name': 'Australia/Lord_Howe'},
  {'value':'Australia/Melbourne'          ,       'name': 'Australia/Melbourne'},
  {'value':'Australia/Perth'              ,       'name': 'Australia/Perth'},
  {'value':'Australia/Sydney'             ,       'name': 'Australia/Sydney'},
  {'value':'Europe/Amsterdam'             ,       'name': 'Europe/Amsterdam'},
  {'value':'Europe/Andorra'               ,       'name': 'Europe/Andorra'},
  {'value':'Europe/Atlantic/'             ,       'name': 'Europe/Atlantic'},
  {'value':'Europe/Athens'                ,       'name': 'Europe/Athens'},
  {'value':'Europe/Belgrade'              ,       'name': 'Europe/Belgrade'},
  {'value':'Europe/Berlin'                ,       'name': 'Europe/Berlin'},
  {'value':'Europe/Bratislava'            ,       'name': 'Europe/Bratislava'},
  {'value':'Europe/Brussels'              ,       'name': 'Europe/Brussels'},
  {'value':'Europe/Bucharest'             ,       'name': 'Europe/Bucharest'},
  {'value':'Europe/Budapest'              ,       'name': 'Europe/Budapest'},
  {'value':'Europe/Busingen'              ,       'name': 'Europe/Busingen'},
  {'value':'Europe/Chisinau'              ,       'name': 'Europe/Chisinau'},
  {'value':'Europe/Copenhagen'            ,       'name': 'Europe/Copenhagen'},
  {'value':'Europe/Dublin'                ,       'name': 'Europe/Dublin'},
  {'value':'Europe/Gibraltar'             ,       'name': 'Europe/Gibraltar'},
  {'value':'Europe/Guernsey'              ,       'name': 'Europe/Guernsey'},
  {'value':'Europe/Helsinki'              ,       'name': 'Europe/Helsinki'},
  {'value':'Europe/Isle_of_Man'           ,       'name': 'Europe/Isle_of_Man'},
  {'value':'Europe/Istanbul'              ,       'name': 'Europe/Istanbul'},
  {'value':'Europe/Jersey'                ,       'name': 'Europe/Jersey'},
  {'value':'Europe/Kaliningrad'           ,       'name': 'Europe/Kaliningrad'},
  {'value':'Europe/Kiev'                  ,       'name': 'Europe/Kiev'},
  {'value':'Europe/Kirov'                 ,       'name': 'Europe/Kirov'},
  {'value':'Europe/Lisbon'                ,       'name': 'Europe/Lisbon'},
  {'value':'Europe/Ljubljana'             ,       'name': 'Europe/Ljubljana'},
  {'value':'Europe/London'                ,       'name': 'Europe/London'},
  {'value':'Europe/Luxembourg'            ,       'name': 'Europe/Luxembourg'},
  {'value':'Europe/Madrid'                ,       'name': 'Europe/Madrid'},
  {'value':'Europe/Malta'                 ,       'name': 'Europe/Malta'},
  {'value':'Europe/Mariehamn'             ,       'name': 'Europe/Mariehamn'},
  {'value':'Europe/Minsk'                 ,       'name': 'Europe/Minsk'},
  {'value':'Europe/Monaco'                ,       'name': 'Europe/Moscow'},
  {'value':'Europe/Moscow'                ,       'name': 'Europe/Oslo'},
  {'value':'Europe/Oslo'                  ,       'name': 'Europe/'},
  {'value':'Europe/Paris'                 ,       'name': 'Europe/Paris'},
  {'value':'Europe/Podgorica'             ,       'name': 'Europe/Podgorica'},
  {'value':'Europe/Prague'                ,       'name': 'Europe/Prague'},
  {'value':'Europe/Riga'                  ,       'name': 'Europe/Riga'},
  {'value':'Europe/Rome'                  ,       'name': 'Europe/Rome'},
  {'value':'Europe/Samara'                ,       'name': 'Europe/Samara'},
  {'value':'Europe/San_Marino'            ,       'name': 'Europe/San_Marino'},
  {'value':'Europe/Sarajevo'              ,       'name': 'Europe/Sarajevo'},
  {'value':'Europe/Saratov'               ,       'name': 'Europe/Saratov'},
  {'value':'Europe/Simferopol'            ,       'name': 'Europe/Simferopol'},
  {'value':'Europe/Skopje'                ,       'name': 'Europe/Skopje'},
  {'value':'Europe/Sofia'                 ,       'name': 'Europe/Sofia'},
  {'value':'Europe/Stockholm'             ,       'name': 'Europe/Stockholm'},
  {'value':'Europe/Tallinn'               ,       'name': 'Europe/Tallinn'},
  {'value':'Europe/Tirane'                ,       'name': 'Europe/Tirane'},
  {'value':'Europe/Ulyanovsk'             ,       'name': 'Europe/Ulyanovsk'},
  {'value':'Europe/Uzhgorod'              ,       'name': 'Europe/Uzhgorod'},
  {'value':'Europe/Vaduz'                 ,       'name': 'Europe/Vaduz'},
  {'value':'Europe/Vatican'               ,       'name': 'Europe/Vatican'},
  {'value':'Europe/Vienna'                ,       'name': 'Europe/Vienna'},
  {'value':'Europe/Vilnius'               ,       'name': 'Europe/Vilnius'},
  {'value':'Europe/Volgograd'             ,       'name': 'Europe/Volgograd'},
  {'value':'Europe/Warsaw'                ,       'name': 'Europe/Volgograd'},
  {'value':'Europe/Zagreb'                ,       'name': 'Europe/Zagreb'},
  {'value':'Europe/Zaporozhye'            ,       'name': 'Europe/Zaporozhye'},
  {'value':'Europe/Zurich'                ,       'name': 'Europe/Zurich'},
  {'value':'Indian/Antananarivo'          ,       'name': 'Indian/Antananarivo'},
  {'value':'Indian/Chagos'                ,       'name': 'Indian/Chagos'},
  {'value':'Indian/Christmas'             ,       'name': 'Indian/Christmas'},
  {'value':'Indian/Cocos'                 ,       'name': 'Indian/Cocos'},
  {'value':'Indian/Comoro'                ,       'name': 'Indian/Comoro'},
  {'value':'Indian/Kerguelen'             ,       'name': 'Indian/Kerguelen'},
  {'value':'Indian/Mahe'                  ,       'name': 'Indian/Mahe'},
  {'value':'Indian/Maldives'              ,       'name': 'Indian/Maldives'},
  {'value':'Indian/Mauritius'             ,       'name': 'Indian/Mauritius'},
  {'value':'Indian/Mayotte'               ,       'name': 'Indian/Mayotte'},
  {'value':'Indian/Reunion'               ,       'name': 'Indian/Reunion'},
  {'value':'Pacific/Apia'                 ,       'name': 'Pacific/Apia'},
  {'value':'Pacific/Auckland'             ,       'name': 'Pacific/Auckland'},
  {'value':'Pacific/Bougainville'         ,       'name': 'Pacific/Bougainville'},
  {'value':'Pacific/Chatham'              ,       'name': 'Pacific/Chuuk'},
  {'value':'Pacific/Chuuk'                ,       'name': 'Pacific/Easter'},
  {'value':'Pacific/Easter'               ,       'name': 'Pacific/Easter'},
  {'value':'Pacific/Efate'                ,       'name': 'Pacific/Efate'},
  {'value':'Pacific/Enderbury'            ,       'name': 'Pacific/Enderbury'},
  {'value':'Pacific/Fakaofo'              ,       'name': 'Pacific/Fakaofo'},
  {'value':'Pacific/Fiji'                 ,       'name': 'Pacific/Fiji'},
  {'value':'Pacific/Funafuti'             ,       'name': 'Pacific/Funafuti'},
  {'value':'Pacific/Galapagos'            ,       'name': 'Pacific/Gambier'},
  {'value':'Pacific/Gambier'              ,       'name': 'Pacific/Gambier'},
  {'value':'Pacific/Guadalcanal'          ,       'name': 'Pacific/Guadalcanal'},
  {'value':'Pacific/Guam'                 ,       'name': 'Pacific/Guam'},
  {'value':'Pacific/Honolulu'             ,       'name': 'Pacific/Honolulu'},
  {'value':'Pacific/Kiritimati'           ,       'name': 'Pacific/Kiritimati'},
  {'value':'Pacific/Kosrae'               ,       'name': 'Pacific/Kosrae'},
  {'value':'Pacific/Kwajalein'            ,       'name': 'Pacific/Kwajalein'},
  {'value':'Pacific/Majuro'               ,       'name': 'Pacific/Majuro'},
  {'value':'Pacific/Marquesas'            ,       'name': 'Pacific/Marquesas'},
  {'value':'Pacific/Midway'               ,       'name': 'Pacific/Midway'},
  {'value':'Pacific/Nauru'                ,       'name': 'Pacific/Nauru'},
  {'value':'Pacific/Niue'                 ,       'name': 'Pacific/Niue'},
  {'value':'Pacific/Norfolk'              ,       'name': 'Pacific/Norfolk'},
  {'value':'Pacific/Noumea'               ,       'name': 'Pacific/Noumea'},
  {'value':'Pacific/Pago_Pago'            ,       'name': 'Pacific/Pago_Pago'},
  {'value':'Pacific/Palau'                ,       'name': 'Pacific/Palau'},
  {'value':'Pacific/Pitcairn'             ,       'name': 'Pacific/Pitcairn'},
  {'value':'Pacific/Pohnpei'              ,       'name': 'Pacific/Pohnpei'},
  {'value':'Pacific/Port_Moresby'         ,       'name': 'Pacific/Port_Moresby'},
  {'value':'Pacific/Rarotonga'            ,       'name': 'Pacific/Rarotonga'},
  {'value':'Pacific/Saipan'               ,       'name': 'Pacific/Saipan'},
  {'value':'Pacific/Tahiti'               ,       'name': 'Pacific/Tahiti'},
  {'value':'Pacific/Tarawa'               ,       'name': 'Pacific/Tarawa'},
  {'value':'Pacific/Tongatapu'            ,       'name': 'Pacific/Tongatapu'},
  {'value':'Pacific/Wake'                 ,       'name': 'Pacific/Wake'},
  {'value':'Pacific/Wallis'               ,       'name': 'Pacific/Wallis'},
  {'value':'UTC'                          ,       'name': 'UTC'}
  ]