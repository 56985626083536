import React , { useState, useEffect, useCallback } from 'react'
import ls from 'local-storage';
import styled from 'styled-components';
import axios from 'axios';
import * as Constants from '../utils/Constants';
import { ReactComponent as TagSVG } from '../images/tag_white.svg';
import {ReactComponent as EditSVG } from '../images/edit.svg';
import Footers, { MenuSettingsPopup } from './Footers';
import Popup from 'reactjs-popup';
import { overlayStyle } from '../utils/Styles';

const EditMenu = styled(EditSVG)`
  object-fit: contain;
  cursor: pointer;
  margin-right: 5px;
  height: 18px;
  width: 18px;
  visibility: hidden;
`;

const DivEdit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  &:hover{
    ${EditMenu}{
      object-fit: contain;
      cursor: pointer;
      margin-right: 5px;
      height: 18px;
      width: 18px;
      visibility: visible;
    }
  }
`;

const PreviewWrappr = styled.div`
  height: auto;
`;

const DeviceIDContainer=styled.div`
  width: 100%;
  height: auto;
  background-color:#1E292F;
  padding-left: 8px;
  padding-top: 10px !important;
  margin-bottom: 10px;
  padding-right: 10px;
  &:hover{
    ${EditMenu}{
      object-fit: contain;
      cursor: pointer;
      margin-right: 5px;
      height: 18px;
      width: 18px;
      visibility: visible;
    }
  }
`;

const DeviceIDText=styled.p`
  text-align: left;
  font-size: 10px;
  color: #A8B5BD;
  white-space: wrap;
  word-break: break-all;
  padding-right: 15px;
  cursor: pointer;
  user-select: none;
`;

const DeviceTitleText=styled.p`
  text-align: left;
  font-size: 18px;
  color: #A8B5BD;
  white-space: wrap;
  word-break: break-all;
  overflow-x: auto;
  padding-right: 15px;
  cursor: pointer;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;

const TagList = styled.ul`
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 0 1px 0;
  margin: 8px 0 0 0;
  max-width: 600px;
  min-width: auto;
  scrollbar-width: thin;
  scrollbar-color: #A8B5BD7D transparent;
  ::-webkit-scrollbar {
    height: 3px;  
    width: 2px;            
    border-radius: 10px;
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: #A8B5BD7D;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #A8B5BD7D;
  }
  line-height: auto;
`;

const TagListName = styled.li`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(96, 96, 96);
  padding: 0 8px;
  font-size: 12px;
  list-style: none;
  border-radius: 15px;
  margin: 0 5px 2px 0;
  background: #1E292F;
  border: 0.5px solid #A8B5BD7D;

`;

const TagListTitle = styled.span`
  margin: 3px;
  padding-right: 2px;
  padding-left: 2px;
  color: #A8B5BD;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width:100px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  margin-top: 5px;
`;

const AppLogo = styled(TagSVG)`
  height: 60px;
  object-fit: contain;
  cursor: pointer;
`;

function UpperMenuDisplay(props) {
    const {mode, tag, previewData, deviceStateHeading, openSettings} = props;
    const [loading, setLoading] = useState(true);
    const [displayPrev, setDisplayPrev] = useState({});
    const [displayState, setDisplayState] = useState({});
    const [inputTag, setInputTag] = useState(''); 
    const [prevModal, setPrevModal] = useState(false);
    const [showWifiIcon, setShowWifiIcon] = useState(false);
    const [clickedTab, setClickedTab] = useState(0);

    const contentStyle = {
      margin: 'auto',
      background: 'rgb(255, 255, 255)',
      width: '510px',
      height: 'auto',
      padding: '5px',
      minWidth: '25%',
      border: '2px solid #d7d7d7'
    };
  
    const closePopup = () => {
      setPrevModal(false);
    };

    const checkWifiIconDisplay = useCallback((show)=>{
      setShowWifiIcon(show);
    } ,[]);
  
    const isTabChange = useCallback((tab)=>{
      setClickedTab(tab);
    } ,[]);

    const getDisplayData = useCallback(() => {
      axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/display/left_menu/all')
      .then(res => {
        if(res.data.data.heading){
          ls.set("Device_Heading",res.data.data["heading"])
        }
        let data = res.data.data;
        if("device_id" in res.headers){
          ls.set("UpperMenu_Device_id", res.headers["device_id"]);
        }
        
        if((ls.get('UpperMenu_Device_id') === null || ls.get('UpperMenu_Device_id') === undefined || ls.get('UpperMenu_Device_id') === "")){
          ls.set('UpperMenu_Device_id','')
        }

        if((ls.get('custom_Heading') === null || ls.get('custom_Heading') === undefined || ls.get('custom_Heading') === "")){
          ls.set('custom_Heading','')
        }
        // if("device_id" in res.headers){
        //   data.device_id = res.headers["device_id"];
        // }
        setDisplayPrev(data);
        setDisplayState(data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
    },[]);

      useEffect(() => {
        getDisplayData();
      },[getDisplayData]);

      useEffect(() => {
          if(mode) {
            setDisplayState(previewData);
            setInputTag(tag);
          } else {
            setDisplayState(displayPrev);
          }
      },[mode, displayPrev, previewData, tag]);
    
    return (
        <div className='upperMenuDisplay'>
        <PreviewWrappr theme={mode}>
            <DeviceIDContainer>
                {mode && <div style={{'display':'flex'}}><div  title={ls.get('custom_Heading') === '' ? ls.get('UpperMenu_Device_id') : ls.get("Device_Heading") === "%DeviceID%" ? '' :  ls.get("Device_Heading")} style={{'width': '90%'}}><DeviceTitleText>{ls.get('custom_Heading') === '' ? ls.get('UpperMenu_Device_id') : ls.get("Device_Heading") === "%DeviceID%" ? '' :  ls.get("Device_Heading")}</DeviceTitleText></div><DivEdit><EditMenu title='Customize' onClick={openSettings} /></DivEdit></div> }            
                {!mode && <div style={{'display':'flex'}}><div  title={ls.get('custom_Heading') === '' ? ls.get('UpperMenu_Device_id') : ls.get("Device_Heading") === "%DeviceID%" ? '' :  ls.get("Device_Heading")} style={{'width': '90%'}}><DeviceTitleText>{ls.get('custom_Heading') === '' ? ls.get('UpperMenu_Device_id') : ls.get("Device_Heading") === "%DeviceID%" ? '' :  ls.get("Device_Heading")}</DeviceTitleText></div><DivEdit><EditMenu title='Customize' onClick={openSettings} /></DivEdit></div> }  
                {displayState.show_device_id &&
                <DeviceIDText>
                    <span id='deviceId'>{ls.get('UpperMenu_Device_id') === undefined ? '' : ls.get('UpperMenu_Device_id')}</span><br/>
                </DeviceIDText>}
               {displayState &&
               <>
                {displayState.tags !== undefined && 
                  <TagList style={{'visibility': displayState.tags.length === 0 ? 'hidden' : 'visible'}}>
                      {displayState.tags.map((tagName,index) => (
                          <TagListName key={index}>
                            <IconWrapper>
                              <AppLogo />
                            </IconWrapper>
                            <TagListTitle title={tagName}>{tagName}</TagListTitle>
                          </TagListName>
                      ))}
                      {inputTag !== '' &&
                        <TagListName>
                          <IconWrapper>
                            <AppLogo />
                          </IconWrapper>
                          <TagListTitle title={inputTag}>{inputTag}</TagListTitle>
                      </TagListName>
                      }
                  </TagList>} 
                </>}
            </DeviceIDContainer>
        </PreviewWrappr>
        </div>
    )
}

export default UpperMenuDisplay