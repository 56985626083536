import React, { useState,ref, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import styled from 'styled-components';
import * as Constants from '../utils/Constants';
import QRCode from 'qrcode.react';
import ls from 'local-storage';
import swal from 'sweetalert';
import { ReactComponent as NoteSVG } from '../images/notes.svg';
import { ReactComponent as EdgeUISVG } from '../images/edge_ui.svg';
import { ReactComponent as PinnedSVG } from '../images/pinned.svg';
import { ReactComponent as UnPinnedSVG } from '../images/un_pinned.svg';
import { ReactComponent as WiFiSVG } from '../images/wifi.svg';
import TagsInput from './TagInput';
import { isEmpty } from '../utils/Utils';
import Loader from './Loader';
import { SelectionControl } from 'react-md';
import DeviceHealthStatus from './DeviceHealthStatus';
import UpperMenuDisplay from './UpperMenuDisplay';
import Footers from './Footers';
import { ReactComponent as LogoSVG } from '../images/logo.svg';
import {ReactComponent as EditSVG } from '../images/edit.svg';
import Popup from 'reactjs-popup';
import { overlayStyle } from '../utils/Styles';

import { TextFieldSmall, TextArea, Button, MessageLabel, TextFieldLabel, SelectField, PopupContainer, Row, PopupButtons, SectionHeader, SectionLabel } from '../utils/Styles';
import { useRef } from 'react';

const EditMenu = styled(EditSVG)`
float: right;
  object-fit: contain;
  cursor: pointer;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  visibility: hidden;
  @media screen and (max-width: 720px) {
    width: 16px;
  }
`;

const DivEdit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  @media screen and (max-width: 1000vh) and (max-height:1920vh){
    margin-bottom: 9.5vh;
  }
  @media screen and (max-width: 1920px) and (max-height: 1080px){
    margin-bottom: 10px;
  }
  &:hover{
    ${EditMenu}{
      visibility: visible;
      float: right;
    }
  }
`;

const NoteIcon = styled(NoteSVG)`
  object-fit: contain;
  cursor: pointer;
  margin-right: 5px;
  width: auto;

  @media screen and (max-width: 720px) {
    width: 10px;
  }
  @media screen and (max-width: 500px) {
    margin: 4px 6px -6px 0px !important;
  }
  
`;

const PinnedIcon = styled(PinnedSVG)`
  object-fit: contain;
  cursor: pointer;
  margin-right: 5px;
  width: auto;

  @media screen and (max-width: 720px) {
    width: 16px;
  }
`;
const UnPinnedIcon = styled(UnPinnedSVG)`
  object-fit: contain;
  cursor: pointer;
  margin-right: 5px;
  width: auto;

  @media screen and (max-width: 720px) {
    width: 16px;
  }
`;
const WiFiIcon = styled(WiFiSVG)`
  object-fit: contain;
  cursor: pointer;
  margin-right: 5px;  
  width: auto;

  @media screen and (max-width: 720px) {
    width: 10px;
  }
  @media screen and (max-width: 400px) {
    width: 10px;
    margin-bottom : -4px !important;
  }
`;

const TabContainer = styled.div`
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  margin-top: auto;
  height: 14vh;
`;

const NoteContainer = styled.p`
  color: #A8B5BD;
  font-family: inherit;
  font-size: 18.4px;
  padding: 5px 10px;
  inline-size: 100%;
  overflow-x: hidden;
  overflow-wrap: break-word;
  scrollbar-width: thin;
  scrollbar-color: #A8B5BD7D transparent;
  @media screen and (max-width: 1400px) {
    font-size: 13.4px;
  }
  
  ::-webkit-scrollbar {
    height: 2px;  
    width: 4px;            
    border-radius: 10px;
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #A8B5BD7D;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #A8B5BD7D;
  }
  @media screen and (max-width: 680px) {
    font-size: 0.9rem;
  }
`;

const TitleText = styled.span`
  color: #A8B5BD;
  margin-left: 2px;
  margin-right: 10px;
  font-family: inherit;
  margin-top: 3px;
  cursor: pointer;
  font-size: inherit;

  @media screen and (max-width: 1500px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }

  @media screen and (max-width: 720px) {
    font-size: 10px;
  }
`;

const TitleTextTab = styled.span`
  color: #A8B5BD;
  margin-left: 2px;
  font-family: inherit;
  font-size: inherit;

  @media screen and (max-width: 1500px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }

  @media screen and (max-width: 720px) {
    font-size: 10px;
  }
`;

const QRWrapper = styled.div`
  cursor: pointer;
`;

const PinnedInnerWrapper = styled.div`
  padding: 10px;
`;

const PinnedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  user-select:none;
`;

const UnPinnedInnerWrapper = styled.div`

`;

const UnPinnedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UnPinnerIconWrapper = styled.div`
  margin-top: 7px;
  display: flex;
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ResponsiveTextFieldLabel = styled(TextFieldLabel)`
  width: 150px;
  @media screen and (max-width: 450px) {
    width: 125px;
  }
  @media screen and (max-width: 400px) {
    width: 115px;
  }
  @media screen and (max-width: 375px) {
    width: 100px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  border-style: solid;
  border-width: 7px;
  border-color: #D0D0D0;
  border-radius: 4px;
  width: 322px;
  @media screen and (max-width: 1366px) and (max-height:768px) {
    width: 259px;
  }
  @media screen and (max-width: 920px) and (max-height:770px) {
    width: 241px;
  }
  @media screen and (max-width: 850px) {
    width: 227px;
  }
  @media screen and (max-width:775px) {
    width: 205px;
  }
  @media screen and (max-width:715px) {
    width: 194px;
  }
  @media screen and (max-width:680px) {
    width: 322px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const AppLogoDiv = styled.div`
  object-fit: contain;
  background-color: #1E292F;
  float: left;
  /* fill or stroke: #fefefe; you could add styles here if you wanted but logos it doesn't make sense */
`;

const AppLogo = styled(LogoSVG)`
  height: 60px;
  object-fit: contain;
  cursor: ${props => props.mode === true ? '' : 'pointer'};
`;

const FooterContainer = styled.div`
  margin-top: 40px;
  background-color: #1E292F;
  margin-bottom: 0;
`;

const RowStyle = styled(Row)`
  display: flex;
  flex-direction: column;
`;

const ResponsivePopupContainer = styled(PopupContainer)`
  @media screen and (max-width: 920px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 775px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 715px) {
    font-size: 0.84rem;
  }
  @media screen and (max-width: 680px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 680px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 620px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 570px) {
    font-size: 0.84rem;
  }
  @media screen and (max-width: 540px) {
    font-size: 0.8rem;
  }
`;

const DivEdit1 = styled.div`
  // margin: 22px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  &:hover{
    ${EditMenu}{
      visibility: visible;
      float: right;
    }
  }
`;

const Footer = (props) => {
  const {mode, previewData, isWifiShow, isTabChange, clickedTab, openSettings} = props;
  const [value, setValue] = useState();
  const [collapase, setCollapse] = useState(true);
  const [showwifi, setShowWifi] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [displayState, setDisplayState] = useState({});
  const [open, setOpen] = useState(false);
  const [prevModal, setPrevModal] = useState(false);
  const ref=useRef();

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: '510px',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: '2px solid #d7d7d7'
  };

  // const openSettings = () => {
  //   if(!mode) {
  //     setPrevModal(true);
  //   }
  // };

  const closePopup = () => {
    setPrevModal(false);
  };

  const getDisplayData = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/display/left_menu/all')
    .then(res => {
      setDisplayState(res.data.data);
    })
    .catch(error => {
      console.error(error);
    });
  },[]);

  useEffect(() => {
    getDisplayData();
  },[getDisplayData]);

  useEffect(() => {
    isWifiShow(showwifi);
  },[showwifi, isWifiShow]);

  useEffect(() => {
    if(clickedTab === 0) {
      setSelectedTab(0);
    } else {
      setSelectedTab(1);
    }
  },[clickedTab]);

  function handleOpen(){
    setOpen(false);
  }

  const checkWifiIconDisplay = useCallback((show)=>{
    
  } ,[]);

  function handleClosed(){
    if(!mode) {
      if(collapase) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }

  const handleUnpinned = (e) => {
    if(!mode) {
      e.stopPropagation();
      setCollapse(true);
      localStorage.setItem('collapase', 'true');
    }
  }

  const handlePinned = (e) => {
    if(!mode) {
      e.stopPropagation();
      setCollapse(false);
      localStorage.setItem('collapase', 'false');
    }
  }

  useOnClickOutside(ref, () => handleClosed(false));

  function useOnClickOutside(ref, handler) {
      useEffect(
        () => {
          const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
              return;
            }
    
            handler(event);
          };
    
          document.addEventListener("mousedown", listener);
          document.addEventListener("touchstart", listener);
          
          return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
          };
        },
        [ref, handler]
      );
  }

  useEffect(()=>{
    const collapses = localStorage.getItem('collapase');
    if(collapses === null) {
      setOpen(true);
      setCollapse(true);
      localStorage.setItem('collapase', 'true');
    } else if(collapses !== null && (displayState.notes === undefined || displayState.notes === '') && showwifi) {
      if(collapses === 'false') {
        setOpen(false);
        setCollapse(false);
        localStorage.setItem('collapase', 'false');
      }
    } else {
      if(mode) {
        setDisplayState(previewData);
        if(displayState.notes !== '') {
          setOpen(false);
          setCollapse(true);
        }
      } else {
        if(collapses === 'true') {
          setOpen(true);
          setCollapse(true);
        } else {
          setOpen(false);
          setCollapse(false);
        }
      }
    }
  },[displayState, showwifi, mode, previewData]);


  useEffect(() =>{
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/brcm/all')
      .then(res =>{
        let brcmData = res.data.data;
        if(brcmData.brcm_enabled === true){
          if(Object.keys(brcmData.ap_wifi_type).length === 2) {
            setShowWifi(true);
          } else if(brcmData.ap_wifi_type[0] === 'wifi_client') {
            setShowWifi(false);
          }  else if(brcmData.ap_wifi_type[0] === 'access_point') {
            setShowWifi(true);
          }
        } else {
          setShowWifi(false);
        }
        if("device_id" in res.headers){
          ls.set("device_id", res.headers["device_id"]);
        }
        if('password' in brcmData && brcmData.password === ''){
          return;
        }
        if('ssid' in brcmData && brcmData.ssid === 'default'){
          brcmData.ssid = ls.get('device_id') === undefined ? '' : ls.get('device_id');
        }
        
        setValue(`WIFI:S:${brcmData.ssid};T:${brcmData.auth_mode};P:${brcmData.password};;`);
      })
      .catch(err => {
        console.log(err);
      });
    }, []);

    const withoutWiFi = (e) => {
      if(showwifi) {
        e.stopPropagation();setSelectedTab(0);
      }
    };

    return(
      <div className='menuFooter' ref={ref} >
        {!open ?
        <>
        <DivEdit>
        <Tabs selectedIndex={selectedTab} >
          <TabList>
            <UnPinnedWrapper onClick={handleClosed}>
              <UnPinnedInnerWrapper>
                <Tab onClick={(e)=>{withoutWiFi(e)}}> <NoteIcon /><TitleTextTab>Notes</TitleTextTab></Tab>
                {Constants.AD_HW_VPU === 'MMAL' && <>{showwifi && <Tab onClick={(e)=>{ e.stopPropagation(); setSelectedTab(1);}}> <WiFiIcon /><TitleTextTab>WiFi QR</TitleTextTab></Tab>}</> }
              </UnPinnedInnerWrapper>
              <UnPinnerIconWrapper>
                <div onClick={(e) =>handlePinned(e)}>
                  {collapase && <PinnedIcon /> }
                </div>
                <div onClick={(e) => handleUnpinned(e)}>
                  {!collapase && <UnPinnedIcon />}
                </div>
              </UnPinnerIconWrapper>
            </UnPinnedWrapper>
          </TabList>
          <TabPanel onClick={handleClosed}>
            <TabContainer>
            {!mode &&<NoteContainer style={{'whiteSpace':'pre-wrap',userSelect:'none'}}>{displayState.notes} {prevModal.notes}</NoteContainer>}
              {mode && <NoteContainer style={{'whiteSpace':'pre-wrap',userSelect:'none'}}>{displayState.notes} {prevModal.notes}</NoteContainer>}
            </TabContainer>
          </TabPanel>
          <TabPanel onClick={handleClosed}>
            <TabContainer style={{'justify-content': 'center'}}>
            {showwifi && <QRWrapper> <QRCode value={value} includeMargin size={window.innerWidth >= 1920 ? 110 : (window.innerWidth >= 1366 ? 90 : 90)} /> </QRWrapper>}
            </TabContainer>
          </TabPanel>
          {!mode &&  <EditMenu title='Customize' onClick={openSettings} />}
        </Tabs>
        </DivEdit>
        </> :
        <DivEdit1>
        <PinnedWrapper onClick={handleOpen}>
            <PinnedInnerWrapper>
              <NoteIcon /><TitleText style={{'marginRight': window.innerWidth === 1920 ? '20px' : ''}}>Notes</TitleText>
              {Constants.AD_HW_VPU === 'MMAL' && <>{showwifi && <><WiFiIcon /><TitleText>WiFi QR</TitleText></>}</>}
            </PinnedInnerWrapper>
            <DivEdit>
            {!mode &&  <EditMenu title='Customize' onClick={(e)=>{ e.stopPropagation(); openSettings();}} />}
            </DivEdit>
        </PinnedWrapper>
        </DivEdit1>
        }
        
      </div>
    );
};

export const MenuSettingsPopup = (props) => {
  const {closePopup, checkWifiIconDisplay, isTabChange, clickedTab} = props;
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displayState, setDisplayState] = useState({});
  const [deviceState, setDeviceState] = useState('%DeviceID%');
  const [inputTag, setInputTag] = useState('');

  const getDisplayData = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/display/left_menu/all')
    .then(res => {
      setDisplayState(res.data.data);
      setDeviceState(res.data.data.heading === '%DeviceID%' ? '%DeviceID%' : 'custom');
      setLoading(false);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    });
  },[]);

  useEffect(() => {
    getDisplayData();
  },[getDisplayData]);

  const onClickPreview = useCallback(() => {
    if(deviceState === 'custom' && displayState.heading === '%DeviceID%') {
      swal({ text: '%DeviceID% is prohibited. Please select "Same as Device ID" if you want to show Device ID.', icon: 'error' });
      return;
    } else if (isEmpty(displayState.heading)) {
        swal("Please enter valid value for 'Heading'.", { icon: 'error' });
        return;
    } else if (displayState.heading === ls.get('device_id')) {
      swal({ text: 'Please select heading "Same as Device ID" if you want to show Device ID.', icon: 'error' });
      return;
    } else if(displayState.heading.length > 100) {
      swal('Maximum 100 characters allowed for heading. ', { icon: 'error'});
      return;
    } else if(displayState.notes.length > 1024) {
      swal('Maximum 1024 characters allowed for notes. ', { icon: 'error'});
      return;
    } else if (deviceState === 'custom' && displayState.heading.length === '%DeviceID%') {
      swal({ text: '%DeviceID% is prohibited. Please select heading "Same as Device ID" if you want to show Device ID.', icon: 'error' });
      return;
    } else if (deviceState === 'custom' && displayState.heading.length === '%DeviceID%') {
      swal({ text: '%DeviceID% is prohibited. Please select heading "Same as Device ID" if you want to show Device ID.', icon: 'error' });
      return;
    } else if(displayState.tags !== undefined) {
      if (displayState.tags.length > 10) {
        swal('Maximum 10 tags can be added', { icon: 'error'});
        return;
      }
    }
   
   
    
    setPreview(!preview);
    if(!preview){
      let data={...displayState}
      if(inputTag !== ''){
        data.tags.push(inputTag);
        setInputTag('')
      }
    }
  },[preview,inputTag, deviceState, displayState]);

  useEffect(() => {
  }, [inputTag])

  const onTagEnter = useCallback((tag) => {
    setInputTag(tag);
  },[]);

  const onSave = () => {
    let jsonData = Object.assign({}, displayState);
    let heading = jsonData.heading;
    let show_device_id = jsonData.show_device_id;
    let tags = jsonData.tags;
    let notes = jsonData.notes;
    if(tags !== undefined) {
      if (inputTag !== ''){
        jsonData.tags.push(inputTag);
      }
      if (jsonData.tags.length > 10) {
        swal('Maximum 10 tags can be added', { icon: 'error'});
        return;
      }
    } else {
      jsonData.tags = [];
      if (inputTag !== ''){
        jsonData.tags.push(inputTag);
      }
    }
    if(deviceState === 'custom'){
      ls.set('custom_Heading',true)
    }else{
      ls.set('custom_Heading','')
    }
    if(deviceState === 'custom' && heading === '') {
      if (isEmpty(heading)) {
        swal("Please enter valid value for 'Heading'.", { icon: 'error' });
        return;
      } 
    } else if (heading === ls.get('device_id')) {
      swal({ text: 'Please select heading "Same as Device ID" if you want to show Device ID.', icon: 'error' });
      return;
    } else if(heading.length > 100) {
      swal('Maximum 100 characters are allowed in heading, found ' + heading.length + ' characters.', { icon: 'error'});
      return;
    } else if(notes.length > 1024) {
      swal('Maximum 1024 characters are allowed in notes, found ' + notes.length + ' characters. ', { icon: 'error'});
      return;
    } else if (deviceState === 'custom' && heading === '%DeviceID%') {
      swal({ text: '%DeviceID% is prohibited. Please select heading "Same as Device ID" if you want to show Device ID.', icon: 'error' });
      return;
    } else if (deviceState === 'custom' && heading === '%DeviceID%') {
      swal({ text: '%DeviceID% is prohibited. Please select heading "Same as Device ID" if you want to show Device ID.', icon: 'error' });
      return;
    } else if(deviceState === 'custom' && !show_device_id) {
      jsonData.heading = heading;
    }

    let finalData = {};
    finalData.data = Object.assign({}, jsonData);
    finalData.action = 'diplay_info';
    finalData.source = 'edge_ui';
    axios.patch(Constants.EDGE_API_ENDPOINT + '/system/services/display/left_menu/all', finalData)
      .then(res => {
        swal('Menu updated successfully.', { icon: 'success' });
        setTimeout(function () { window.location.reload(); }, 2000);
      })
      .catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
  }

  const newList = useCallback((tags) => {
    setDisplayState((displayState) => ({...displayState, ["tags"]: tags}));
  },[]);

  const handleOnchange = (event) => {
    if(event.target.name === 'notes' || event.target.name === 'heading'){
      if (event.target.value.charAt(0) === ' ') {
        return event.target.value.replace(/[^\w]/g, '');
      } else {
        setDisplayState({...displayState, [event.target.name]: event.target.value});
      }
    } else {
      setDisplayState({...displayState, [event.target.name]: event.target.value});
    }
  };

  const handleSelectChange = (event) => {
    setDeviceState(event.target.value);
    if(event.target.value === '%DeviceID%') {
      let data = {...displayState}
      data.show_device_id= false;
      data.heading = '%DeviceID%';
      setDisplayState(data);
    } else {
      let data = {...displayState}
      if(data.heading === '%DeviceID%') {
        setDisplayState((displayState) => ({...displayState, ["heading"]: ''}));
      } else {
        setDisplayState((displayState) => ({...displayState, ["heading"]: ''}));
      }
    }
  }

  const enableToggle = (value, event) => {
    setDisplayState(displayState => ({...displayState, ["show_device_id"]: value}));
  }
  
  return(
    <ResponsivePopupContainer style={{'height':'auto'}}>
          <SectionHeader style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
            <SectionLabel>{preview ? 'Heading, Tags and Notes Preview' :'Configure Heading, Tags and Notes'}</SectionLabel>
          </SectionHeader>
          <Loader isLoading={loading} />
          {!preview ? 
          <>
          {!loading && displayState &&
          <>
          <Container>
            <Row style={{display: 'flex', alignItems: 'baseline'}}>
              <ResponsiveTextFieldLabel style={{'flex-shrink': '0'}}>Heading</ResponsiveTextFieldLabel>
              <SelectField id="version" name="version" style={{ 'margin-top': '10px', 'flex-grow': '1' }} value={deviceState} onChange={handleSelectChange}>
                <option value="%DeviceID%">Same as Device ID</option>
                <option value="custom">Custom</option>
              </SelectField>
            </Row>
            {deviceState === 'custom' &&
            <div style={{'display': 'flex'}}>
            <ResponsiveTextFieldLabel style={{'flex-shrink': '0'}}></ResponsiveTextFieldLabel>
            <div style={{flexGrow: '1'}}>
              <Row style={{'margin-top': '10px'}}>
                <TextFieldSmall id="heading" autoComplete="off" placeholder='Please enter heading text here...' value={displayState.heading} title={displayState.heading} onChange={handleOnchange} name="heading" style={{'width': '100%'}} />
              </Row>
              <Row style={{marginLeft: '5px',marginBottom:'-10px'}}>
                {displayState.heading.length > 100 &&  <MessageLabel style={{color: 'red', 'marginTop': '0', 'width':'auto'}}>{displayState.heading.length}/100 </MessageLabel>}
                {displayState.heading.length <= 100 && <MessageLabel style={{'marginTop': '0',  'width':'auto'}}>{displayState.heading.length}/100 </MessageLabel>}
              </Row>
            </div>
          </div>}
            {deviceState === '%DeviceID%' &&
            <Row style={{'margin-top': '10px', display: 'flex', alignItems: 'baseline'}}>
              <ResponsiveTextFieldLabel style={{'flex-shrink': '0'}}></ResponsiveTextFieldLabel>
              <TextFieldSmall id="heading" autoComplete="off" value={ls.get('device_id') === undefined ? '' : ls.get('device_id')} title={ls.get('device_id') === undefined ? '' : ls.get('device_id')} disabled name="heading" style={{'width': '100%'}} />
            </Row>}
            <Row style={{'display': 'flex', display: 'flex', alignItems: 'baseline'}}>
              <ResponsiveTextFieldLabel style={{'flex-shrink': '0'}}>Show Device ID</ResponsiveTextFieldLabel>
              <SelectionControl id='show_device_id' type='switch' name='show_device_id' labelBefore='true' disabled={deviceState !== 'custom' ? true : false} value={displayState.show_device_id} checked={displayState.show_device_id} onChange={enableToggle} style={{"marginTop":"auto", "display": "inline-block", "height": "25px", 'margin-left': '-8px'}} />
            </Row>
            <Row style={{'display': 'flex', display: 'flex'}}>
              <ResponsiveTextFieldLabel style={{'flex-shrink': '0'}}>Tags</ResponsiveTextFieldLabel>
              <div style={{width: '100%'}}>
                <TagsInput tags={displayState.tags} newTags={newList} enteredTag={onTagEnter}/>
              </div>
            </Row>
            <Row style={{'display': 'flex'}}>
              <ResponsiveTextFieldLabel style={{'flex-shrink': '0'}}></ResponsiveTextFieldLabel>
              <MessageLabel style={{'width': '100%', 'marginBottom':'0'}}>Enter the name of the tag in the above input field and press enter.</MessageLabel>
            </Row>
            <Row style={{'display': 'flex'}}>
              <ResponsiveTextFieldLabel style={{'flex-shrink': '0'}}>Notes</ResponsiveTextFieldLabel>
              <div style={{'marginTop':'20px', width: '100%', 'height':'auto', overflow:'auto'}}>
                <TextArea id='notes' autoComplete="off" name='notes' style={{'height': '150px'}} title={displayState.notes} value={displayState.notes} onChange={handleOnchange} />    
              </div>
            </Row>
            <Row style={{'display': 'flex'}}>
              <ResponsiveTextFieldLabel style={{'marginTop': '0', 'flex-shrink': '0'}}></ResponsiveTextFieldLabel>
              {displayState.notes.length > 1024 && <MessageLabel style={{'color': 'red', 'marginTop': '0', 'marginLeft': '5px', 'width':'100%'}}>{displayState.notes.length}/1024 </MessageLabel>}
              {displayState.notes.length <= 1024 && <MessageLabel style={{'marginTop': '0', 'marginLeft': '5px',  'width':'100%'}}>{displayState.notes.length}/1024 </MessageLabel>}
            </Row>
          </Container>
          <Row>
            <PopupButtons>
              <Button primary onClick={onClickPreview}>Preview</Button>
              <Button onClick={onSave} primary>Save</Button>
              <Button id="btnCancelInstance" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row></>}
          </> :
          <>
          {displayState && 
            <RowStyle>
              <div style={{'display': 'flex', 'justify-content': 'center', 'margin': '15px 10px 10px 10px'}}>
                <MenuContainer className="preview-menu">
                  <AppLogoDiv>
                    <AppLogo title=""/>
                    <DeviceHealthStatus mode={preview} previewData={displayState}/>
                    <UpperMenuDisplay mode={preview} deviceStateHeading={deviceState} tag={inputTag}  previewData={displayState}/>
                  </AppLogoDiv>
                  <FooterContainer>
                  <Footers mode={preview} previewData={displayState} isWifiShow={checkWifiIconDisplay} isTabChange={isTabChange} clickedTab={clickedTab} />
                  </FooterContainer>
                </MenuContainer>
                </div>
              <PopupButtons style={{'marginTop': Object.keys(displayState.tags).length > 0 ? '' : '15px', 'marginBottom': Object.keys(displayState.tags).length > 0 ? '' : '10px'}}>
                <Button onClick={onClickPreview}>Exit Preview</Button>
              </PopupButtons>
            </RowStyle>
          }</>}
      </ResponsivePopupContainer>
  );
}

export default Footer;