import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Wrapper, MandatoryLabel, SelectFieldSmall, Label, LeftDiv, Spacer, PopupButtons, TableContainer, overlayStyle, PopupContainer, CenterDiv, TextFieldLabel, MessageLabel, TextFieldSmall, Th, Td, TextField, TextArea, SelectField, Row, Column, RightDiv, SectionHeader, Button, SectionLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import { SelectionControl } from 'react-md';
import swal from "sweetalert";
import styled from 'styled-components';
import Loader from './Loader';
import Popup from 'reactjs-popup';
import { CircularProgress } from 'react-md';
import axiosRetry from 'axios-retry';


const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 3px;
  height: auto;
  @media screen and (max-width: 500px) {
    width: 220px;
    justify-content: flex-end;
    margin-left:0px;
    font-size: 8px;
  }
`;

const MessageContaier = styled.div`
  padding: 5px 5px 1px 5px;
`;

const ResponsiveFieldContainer = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

const ResponsiveField = styled.div`
  display: flex;
  align-items: baseline;
  @media screen and (max-width: 750px) {
    width:100%; 
  }
`;

const ResponsiveInput = styled(TextFieldSmall)`
  width: 250px;
  @media screen and (max-width: 750px) {
    width: 160px;
  }
  @media screen and (max-width: 650px) {
    width: 100px;
  }
  @media screen and (max-width: 500px) {
    width: 120px;
  }
`;

const ResponsiveTable = styled(TableContainer)`
  height: 270px
  @media screen and (max-width: 500px) {
    height: 230px;
  }
`;

const ResponsivePopup = styled.div`
  @media screen and (max-width: 750px) {
    width: 550px;
    height: 100%;
    overflow-y: auto;
  }
  @media screen and (max-width: 650px) {
    width: 450px;
  }
  @media screen and (max-width: 500px) {
    width: 350px;
  }
  @media screen and (max-width: 415px) {
    width: 300px;
  }
`;

const ContentDiv=styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const RoutingSettings = (props) => {
  const [routeData, setRouteData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [retry,setRetry]=useState(false)
  const [popupType, setPopupType] = useState('');
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const [interfaceList, setInterfaceList] = useState();

  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: popupType === 'ping'? '500px':'500px',
    padding: '5px',
    border: '2px solid #d7d7d7'
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const getRoutes = () => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/network/interfaces/show_routes/status/all')
    .then(res => {
      let ifData = res.data.data;
      let sortedData = [];
      Object.keys(ifData).map((idx) => {
        let data = {};
        let pData = ifData[idx];
        data.destination = pData[1].destination
        data.gateway = pData[1].gateway
        data.genmask = pData[1].genmask
        data.metric = pData[1].metric
        data.inf_name = pData[0]
        sortedData.push(data);
        return idx;
      });
      sortedData.sort(function(a,b){
        return a.metric - b.metric;
      });
      setRouteData(sortedData) 
      setLoading(false)
    }).catch(err => {
      setLoading(true)
      console.log(err);
    });
  }

  const testRoute = () => {
    setModalOpen(true);
    setPopupType('routeTest');
  };

  const onClickPing = () => {
    setModalOpen(true);
    setPopupType('ping');
  };

  const getConfiguredInterfacelList = () => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/network/interfaces/all') 
    .then(res => {
      const dataList = Object.keys(res.data.data.services)
      let sortedData = [];
      Object.keys(dataList).map((idx) => {
        let data = {};
        let pData = dataList[idx];
        data.name = pData;
        data.value = pData;
        sortedData.push(data);
        return idx;
      });
      setInterfaceList(sortedData);
    }).catch(err => {
      setLoading(true)
      console.log(err);
    });
  }

  useEffect(()=>{
    getRoutes();
    getConfiguredInterfacelList();
  },[]);

  const refreshRoutes = (event) => {
    setLoading(true);
    getRoutes();
  };

    return (
      <ContentDiv>
          <Wrapper style={{'marginTop':'5px'}}>
              <SectionHeader>
                  <SectionLabel>Routes</SectionLabel>
              </SectionHeader>
              { (routeData === undefined) &&
                <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
                  <CircularProgress />
                </div>
              }
              {(routeData) &&
              <>
                <Row>
                  <ActionButtonContainer>
                  <Button primary id="btnRefreshRoutes" name="btnRefreshRoutes" onClick={() => refreshRoutes()}>{loading ? 'Refreshing...' : 'Refresh'}</Button>
                    <Button primary id="btnPing" name="btnPing" onClick={onClickPing}>Ping</Button>
                    <Button primary id="btnTestRoute" name="btnTestRoute" onClick={testRoute}>Test Route</Button>
                    <Popup modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                      <TestRoutePopup closePopup={closePopup} popupType={popupType} interfaceList={interfaceList} />
                    </Popup>
                  </ActionButtonContainer>
                </Row>
                
                {Object.keys(routeData).length > 0 &&
                <TableContainer style={{overflowX: 'auto'}}>
                  <table style={{'width': '100%', 'padding':'11px', 'borderCollapse':'collapse'}}>
                    <tr style={{'background-color': '#1f303a','color': 'white'}}>
                      <Th style={{'padding': '10px', 'textAlign':'left', 'width': '200px'}}>Destination</Th>
                      <Th style={{'padding': '10px', 'width': '200px'}}>Gateway</Th>
                      <Th style={{'padding': '10px', 'width': '200px'}}>Genmask</Th>
                      <Th style={{'padding': '10px', 'width': '100px'}}>Metric</Th>
                      <Th style={{'padding': '10px', 'width': '120px'}}>Interface</Th>
                    </tr>
                    {Object.keys(routeData).map((idx) => {
                      let route_data = routeData[idx]
                      return(
                        <tr>
                          <Td style={{'padding': '10px', 'textAlign':'left'}}>{route_data.destination}</Td>
                          <Td>{route_data.gateway}</Td>
                          <Td>{route_data.genmask}</Td>
                          <Td>{route_data.metric}</Td>
                          <Td>{route_data.inf_name}</Td>
                        </tr>
                      )
                    })}

                  </table>
                </TableContainer>}
                <MessageContaier>
                  {isMobileBrowser ? 
                    <MessageLabel>  
                      <b>Metric: </b>It is an integer and smaller number means higher priority. Metric in a parentheses is assigned by the system automatically.
                    </MessageLabel> : 
                    <MessageLabel> 
                    <b>Metric: </b>It is an integer and smaller number means higher priority. Metric in a parentheses is assigned by the system automatically.
                    </MessageLabel>}
                  </MessageContaier>
                {(routeData && !loading) && Object.keys(routeData).length === 0 &&
                <CenterDiv>
                  <Label>No route found.</Label>
                </CenterDiv>}
              </>}
          </Wrapper>
      </ContentDiv>
    )
  }


const DEFAULT_PING_VALUE = {
  'hosts':'',
  'interface':'default',
  'timeout':'100'
}

const TestRoutePopup = (props) => {
  const { closePopup, popupType, interfaceList } = props;
  const [destination, setDestination] = useState('8.8.8.8');
  const [routesData, setRoutesData] = useState([]);
  const [loading, setLoading] = useState();
  const [pingLoading, setPingLoading] = useState();
  const [pingData, setPingData] = useState(DEFAULT_PING_VALUE);
  const [output, setOutput] = useState();
  const [disableSubmitPingButton, setSubmitPingDisableButton] = useState(false);
  const [disableResetPingButton, setDisableRestPingButton] = useState(false);
  const [disableSubmitTestButton, setDisableSubmitTestButton] = useState(false);
  const [disableResetTestButton, setDisableRestTestButton] = useState(false);

  const onSubmit = (destination) => {
    const value = validateIPaddress(destination);
      if(value) {
        getDestination();
      }
  }

  const getDestination = () => {
    setLoading(true);
    setDisableSubmitTestButton(true);
    axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/get_routes/' + destination + '/status/all') 
    .then(res => {
      let ifData = res.data.data[0];
      let data = {};
      let sortedData = [];
      let routesDataArray = [...routesData];
      Object.keys(ifData).map((idx) => {
        data.destination = destination;
        data.ifName = ifData[idx].device;
        data.gateway = ifData[idx].gateway;
        data.source = ifData[idx].source
        sortedData.push(data);
        return idx;
      })
      if(Object.keys(routesDataArray).length > 9) {
        routesDataArray.splice(0, 0, data);
        routesDataArray.splice(-1);
      } else {
        routesDataArray.splice(0, 0, data);
      }
      setRoutesData(routesDataArray);
      setLoading(false);
      setDisableSubmitTestButton(false);
    }) .catch(error => {
      console.error(error);
      setLoading(false);
      setDisableSubmitTestButton(false);
    });
  }

  function validateIPaddress(ipaddress) {
    let ipv6_regex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

    if(ipaddress === undefined) {
      swal('Please enter destination address', { icon: 'error'});
      return;
    } else {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
          return true
        } else if(/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(ipaddress)){
          return true;
        }
        swal('Please enter valid destination address', { icon: 'error'});
        return false
    } 
  } 

  function validateHostIP(ipaddress) {
    if(ipaddress === '') {
        return;
    } else {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
          return true
        }else if(/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(ipaddress)){
          return true;
        }
        return false
    } 
  } 

  function isValidHostName(ipaddress) {
    if(/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(ipaddress)){
      return true;
    } else {
      var res = ipaddress.match(/^((([0-9]{1,3}\.){3}[0-9]{1,3})|(([a-zA-Z0-9]+(([\-]?[a-zA-Z0-9]+)*\.)+)*[a-zA-Z]{2,}))$/);
      if(res) {
        if(!ipaddress.includes('.')) {
          return false;
        } else {
          return true;
        }
      } else {
        return false
      }
      }
    };

  const handleOnChange = (event) => {
    setDisableSubmitTestButton(false);
    if (event.target.name === 'destination' && event.target.value.includes(' ')) {     
      event.target.value = event.target.value.replace(/\s/g, '');    
    } else {
      if(event.target.name === 'destination' ) {
        event.target.value = event.target.value.replace(/[^.:\w]/g, '');
      } 
      setDestination(event.target.value);
    }
  }

  const pingHandleOnChange = (event) => {
    if ((event.target.name === 'timeout') && event.target.value.includes(' ')) {     
      event.target.value = event.target.value.replace(/\s/g, '');  
    } else if(event.target.name === 'hosts' && event.target.value.includes('..')) {
      event.target.value = event.target.value.replace('.');
    } else {
      if(event.target.name === 'timeout') {
        event.target.value = event.target.value.replace(/[^\d]/g, '');    
      } 
      setPingData({...pingData, [event.target.name]:  event.target.value});
    }
  }

  const onReset = () => {
    setDisableSubmitTestButton(false);
    setDestination('');
  }

  const onPingReset = () => {
    setPingData(DEFAULT_PING_VALUE);
    setOutput('')
  }

  const onPingSubmit = () => {
    setSubmitPingDisableButton(true);
    setOutput()
    setPingLoading(true);
    let jsonData = Object.assign({}, pingData);
    let hosts = jsonData.hosts;
    let timeout = jsonData.timeout;
    let arrHost = [];
    if(hosts === undefined || hosts === '') {
      swal({ text: 'Please enter host(s)', icon: 'error' });
        setPingLoading(false);
        setSubmitPingDisableButton(false);
        return;
    }
    if(timeout === undefined || timeout === '') {
      swal({ text: 'Please enter timeout', icon: 'error' });
        setPingLoading(false);
        setSubmitPingDisableButton(false);
        return;
    }
    if (hosts !== undefined) {
      let invalidhosts = [];
      let host = hosts.split(',');
      arrHost.push(host);
      for (let i = 0; i < host.length; i++) {
        var regExp = /[a-zA-Z]/g;
        
        if(regExp.test(host[i])){
          const value = isValidHostName(host[i].trim());
          if(!value) {
            invalidhosts.push(host[i].trim());
            swal({ text: 'Invalid host address "' + invalidhosts + '"', icon: 'error' });
            setSubmitPingDisableButton(false);
            setPingLoading(false);
            return;
          } 
        } else {
          const value = validateHostIP(host[i].trim());
          if(!value) {
            invalidhosts.push(host[i].trim());
            swal({ text: 'Invalid host IP "' + invalidhosts + '"', icon: 'error' });
            setSubmitPingDisableButton(false);
            setPingLoading(false);
            return;
          } 
        }
      }
    }
    if(hosts.split(',').length > 10) {
      swal('Maximum 10 numbers of hosts can be added', { icon: 'error'});
      setPingLoading(false);
      setSubmitPingDisableButton(false);
      return;
    }
    if(timeout > 5000) {
      swal('Please enter timeout value <= 5000ms', { icon: 'error'});
      setPingLoading(false);
      setSubmitPingDisableButton(false);
      return;
    }

    let finalJson ={};
    finalJson.action = 'ping'
    finalJson.source = 'edge_ui'
    finalJson.data = {};
    finalJson.data.interface = jsonData.interface;
    finalJson.data.hosts = arrHost[0]
    finalJson.data.timeout = jsonData.timeout;

    axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/all',finalJson )
    .then(res => {
      setOutput(res.data.data)
      setPingLoading(false)
      setSubmitPingDisableButton(false);
    }) .catch(error => {
      setPingLoading(false);
      if (error.response) {
        var errorObj = error.response.data;
        setSubmitPingDisableButton(false);
        swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
      } else {
        setSubmitPingDisableButton(false);
        swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
      }
    })
  }

  useEffect(() => {
    if(output && pingData.hosts && pingData.timeout && pingData.interface) {
      setDisableRestPingButton(false);
    } else {
      setDisableRestPingButton(true);
    }
  },[output, pingData]);

  useEffect(() => {
    if(destination) {
      setDisableRestTestButton(false);
    } else {
      setDisableRestTestButton(true); 
    }
  },[destination]);

  const OnhandleKeyPress = (event, destination) => {
      if (event.key === "Enter") {
        onSubmit(destination);
      }
  }
  return(
    <ResponsivePopup>
      <PopupContainer>
        {popupType === 'ping' && 
          <>
            <SectionHeader style={{ 'display': 'flex' }}>
              <SectionLabel>Ping</SectionLabel>
            </SectionHeader>
            <Container>
            <Row style={{'display':'flex', 'width':'100%','flexDirection': 'column'}}>
                <ResponsiveField>
                  <MandatoryLabel style={{'width':'80px', 'flex-shrink': '0'}}>Hosts</MandatoryLabel>
                  <TextFieldSmall id="hosts" autoComplete="off" placeholder='8.8.8.8, google.com' value={pingData.hosts} onChange={pingHandleOnChange} name="hosts" style={{'width':'100%', 'textTransform':'lowercase'}} />
                </ResponsiveField>
                <ResponsiveFieldContainer>
                <ResponsiveField>
                  <MandatoryLabel style={{'width':'80px', 'flex-shrink': '0'}}>Timeout</MandatoryLabel>
                  <TextFieldSmall id="timeout" autoComplete="off" name="timeout" maxLength='4' style={{'width':'100%'}} value={pingData.timeout} onChange={pingHandleOnChange}/>
                  <TextFieldLabel style={{'width':'30px', 'marginLeft':'8px', 'fontSize':'12px', 'marginRight':'10px'}}>ms</TextFieldLabel>
                </ResponsiveField>
                <ResponsiveField>                  
                  <TextFieldLabel style={{'width':'80px', 'flex-shrink': '0'}}>Interface</TextFieldLabel>
                  <SelectFieldSmall id='interface' name='interface' style={{'width':'100%'}} value={pingData.interface} onChange={pingHandleOnChange}>
                    <option value='default'>auto</option>
                    <SelectOptions items={interfaceList}/>
                  </SelectFieldSmall>
                </ResponsiveField>
                </ResponsiveFieldContainer>
                  <PopupButtons style={{'marginTop':'20px'}}>
                    <Button primary id='btndisabled' style={{'margin':'0'}} disabled={disableSubmitPingButton} onClick={onPingSubmit}>Submit</Button>
                  </PopupButtons>
                <div>
                <TextFieldLabel style={{'width':'70px', 'marginBottom':'10px', 'marginTop':'0'}}>Output</TextFieldLabel>
                  <div style={{'height': '150px',  'border': '1px solid #ddd', 'padding':'15px', 'overflow':'auto' }}>
                    {pingLoading && 
                    <div style={{'textAlign': 'center', 'paddingTop':'10px'}}> 
                      <label>Loading...</label>
                      <CircularProgress/>
                    </div>}
                    {(output )&& output.map((idx) => (
                      <p style={{'textTransform':'lowercase'}}>{idx}</p>
                    ))}
                  </div>
                </div>
              </Row>
              <Row>
                <PopupButtons>
                  <Button primary id="btndisabled"  disabled={disableResetPingButton} onClick={onPingReset}>Reset</Button>
                  <Button id="btnCancelIface" danger onClick={()=> {closePopup();}}>Cancel</Button>
                </PopupButtons>
              </Row>
            </Container>
          </>}
        {popupType === 'routeTest' && 
          <>
            <SectionHeader style={{ 'display': 'flex' }}>
              <SectionLabel>Test Route</SectionLabel>
            </SectionHeader>
            <Container>
              <Row style={{'display':'flex', 'width':'100%', 'align-items': 'baseline', 'flex-wrap': 'wrap'}}>
                <div>
                  <MandatoryLabel style={{'width':'100px'}}>Destination</MandatoryLabel>
                  <ResponsiveInput style={{'textTransform':'lowercase'}} id="destination" autoComplete="off" placeholder='8.8.8.8' value={destination} onKeyPress={(e) => OnhandleKeyPress(e, destination)} name="destination" onChange={handleOnChange} />
                </div>
                <ButtonContainer>
                  <Button primary autoFocus disabled={loading || disableSubmitTestButton} id='btndisabled' onClick={() => onSubmit(destination)}>
                    <div style={{'display': 'flex'}}>
                    {loading && <span>Submit</span>}
                    {!loading && <span>Submit</span>}
                    {loading && (
                      <div class="loader" style={{'marginLeft':'10px', 'marginTop': '2px'}}></div>
                      )}
                    </div>
                  </Button>
                  <Button primary id="btndisabled" style={{'marginLeft':'10px'}} name="btndisabled" disabled={disableResetTestButton} onClick={onReset}>Clear</Button>
                </ButtonContainer>
              </Row>
              <ResponsiveTable className='tableFixHead' style={{'marginTop':'10px'}}>
                <table style={{'min-width': '100%', 'borderSpacing': '0', 'tableLayout': 'fixed', 'borderCollapse': 'separate'}}>
                  <tr style={{'background-color': '#1f303a', 'borderSpacing': '0','color': 'white', 'position': 'sticky', 'top': '0'}}>
                    <Th>Destination</Th>
                    <Th>Gateway</Th>
                    <Th>Interface</Th>
                    <Th>Source</Th>
                  </tr>
                  { Object.keys(routesData).map((index) => {
                     return(
                      <tr>
                      <Td style={{'padding': '9px', 'textTransform':'lowercase'}}>{(routesData[index].destination === null || routesData[index].destination=== '' ) ? '-' : routesData[index].destination}</Td>
                      <Td>{(routesData[index].gateway === null || routesData[index].gateway === '' ) ? '-' : routesData[index].gateway}</Td>
                      <Td>{(routesData[index].ifName === null || routesData[index].ifName === '' ) ? '-' : routesData[index].ifName}</Td>
                      <Td>{(routesData[index].source === null || routesData[index].source === '' ) ? '-' : routesData[index].source}</Td>
                    </tr>
                     );
                  }
                 )}
                </table>
              </ResponsiveTable>
              <Row>
                <PopupButtons>
                  <Button id="btnCancel" name="btnCancel" onClick={closePopup}>Cancel</Button>
                </PopupButtons>
              </Row>
            </Container>
          </>}
      </PopupContainer>
    </ResponsivePopup>
  );
}

export default RoutingSettings;