import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import { Row, Button, SectionHeader, SectionLabel, CopyButton, TextArea, CenterDiv, TextField, Label, TableContainer, MessageLabel, SelectField, MandatoryLabel, Td, Th, overlayStyle, PopupContainer, TextFieldLabel, TextFieldSmall, PopupButtons } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { clickToCopy, isEmpty, dateFormat, timeZone } from '../utils/Utils';
import SelectOptions from '../components/SelectOptions';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import { Tabs, Tab, TabList, TabPanel, TabProvider } from 'react-web-tabs';
import PasswordField from './PasswordField.js';
import styled from 'styled-components';
import { ReactComponent as EmailDeleteIcon } from '../images/email_delete.svg';
import ls from 'local-storage';
import axiosRetry from 'axios-retry';
import moment from 'moment-timezone';
import { ReactComponent as CopySVG } from '../images/copy.svg';
import { convertToTimeZone } from 'date-fns-timezone';
import { format, toDate } from 'date-fns';



const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const OutBoxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const RightDiv = styled.div`
  margin-left: auto;
`;

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const InputFieldContainer = styled.div`
  display: flex;
  align-items: baseline;
  & > label {
    width: 170px;
    flex-shrink: 0;
    @media screen and (max-width: 750px) {
      width: 105px;
    }
    @media screen and (max-width: 425px) {
      width: 80px;
    }
  }
  & > input {
    max-width: 205px;
  }
  & > select {
    max-width: 205px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 550px) {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.68rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 0.65rem;
  }
`;

const ContentDiv = styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const SmallTextField = styled(TextField)`
  width: 60px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DeleteButtonContainer = styled.div`
  padding-top: 7px;
  padding-left: 10px;
`;

const TabContainer = styled.div`
  padding: 0px 20px 20px 20px;
  @media screen and (max-width: 750px) {
    width: 375px;
  }
  @media screen and (max-width: 500px) {
    width: 335px;
  }
  @media screen and (max-width: 425px) {
    width: 280px;
  }
`;

const FieldSet = styled.fieldset` 
  margin-top: 20px; 
  margin-left: -1px;
`;

const FieldSetInnerContainer = styled.div`
  padding-bottom: 15px;
  @media screen and (max-width: 500px) {
    width: 280px;
  }
  @media screen and (max-width: 425px) {
    width: 225px;
  }
`;

const DataDirectoryContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px; 
`;

const EmailDeleteButton = styled(EmailDeleteIcon)`
  height: 20px;
  width: 20px;
  margin: 4px 0px 0px 8px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.8;

  @media screen and (max-width:750px){
    margin: 1px 0px 0px 8px;
  }
  @media screen and (max-width:750px){
    height: 17px;
    margin: 0px 0px 0px 8px;
  }
`;

const CopyButtonEmail = styled(CopySVG)`
  height: 18px;
  width: 18px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
  @media screen and (max-width: 550px) {
    height: 18px;
    width: 18px;
    margin: -4px 2px 6px 22px !important;
  }
`;


const EmailClient = () => {
  const [isEditPopup, setEditPopup] = useState(true);
  const [emailClientData, setEmailClientData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState({});
  const [loading, setLoading] = useState(false);
  const [testEmail, setTestEmail] = useState(false);
  const [retry,setRetry]=useState(false);
  const [CurTimezone, setCurTimeZone]=useState();
  const [standaredTZ, setStandardTZ]=useState();
  const deviceId = ls.get('device_id') === undefined ? '' : ls.get('device_id');

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: 'px solid #d7d7d7'
  };

  const instNameStyle = {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: window.innerWidth >= 1920 ? '300px' : '200px'
  };

  const getConvertedBrowerDate = () => {
    const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const code = moment.tz(tzid).format('z');
    const time = format(new Date(), "yyyy/MM/dd HH:mm:ss ") + code;
    return time;
  };

  const editInstance = () => {
    setEditPopup(true);
    setModalOpen(true);
    setTestEmail(false);
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewInstance = (event) => {
    setEditPopup(false);
    // setSelectedInstance(undefined);
    setModalOpen(true);
    setTestEmail(false);
  };

  const sendEmail = () => {
    setTestEmail(true);
    setModalOpen(true);
    let data = Object.assign({}, selectedInstance);
    if(data.default_from_addr !== '' || data.default_from_addr !== undefined ) {
      data.from = data.default_from_addr;
    }
    if(data.default_to_addr !== '' || data.default_to_addr !== undefined ) {
      data.to = data.default_to_addr;
    }  
    setSelectedInstance(data);
  }

  const radioButtonOnChange = (name) => {
    let emailData = Object.assign({}, emailClientData.stacks[name].services.email_sender1);
    let date = getConvertedBrowerDate();
    emailData.instance_id = name;
    emailData.nickname = emailClientData.stacks[name].nickname;
    emailData.subject = `Test e-mail from ${deviceId}.`;
    emailData.body = `This is a test message from e-mail client ${name} on ${deviceId}.\nThe current time is ${date} on the browser of the sender.`
    setSelectedInstance(emailData);
    setEditPopup(true);
  };

  const postAction = (emailClientName, jsonData, value) => {

    axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/' + emailClientName + '/all', jsonData)
      .then(res => {
        if ((jsonData.action === Constants.SDK_ACTIONS.ENABLE || jsonData.action === Constants.SDK_ACTIONS.DISABLE)) {
          swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
        } else if (jsonData.action === Constants.SDK_ACTIONS.CLEAR_OUTBOX) {
          swal('Outbox cleared successfully.', { icon: 'success' });
        }
        setTimeout(function () { window.location.reload(); }, 2000);
      })
      .catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
  };

  const enableToggle = (value, event) => {
    let emailClientName = event.target.name;
    var jsonData = {
      'namespace': Constants.NAMSPACES.SDK,
      'type': Constants.SDK_SERVICE_TYPES.EMAIL_CLIENT,
      'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE
    };
    postAction(emailClientName, jsonData, value);
  };

  const clearOutbox = (name) => {
    swal({
      text: 'Are you sure to clear all pending messages in the outbox?',
      buttons: {
        cancel: 'No',
        Yes: true,
      },
      dangerMode: true,
      icon: 'warning'
    })
      .then((isConfirm) => {
        if (isConfirm) {
          let emailClientName = name;
          var jsonData = {
            'namespace': Constants.NAMSPACES.SDK,
            'type': Constants.SDK_SERVICE_TYPES.EMAIL_CLIENT,
            'action': Constants.SDK_ACTIONS.CLEAR_OUTBOX
          };
          postAction(emailClientName, jsonData);
        }
      });
  };

  const getEmailClient = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/sdk/email_clients/all')
      .then(res => {
        let data = res.data.data;
        if(Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        } 
        let sortedData = [];
        if ('stacks' in data) {
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            sortedData.push(stackData);
            return stackName;
          });
          sortedData.sort(function (a, b) {
            const dateFormatB = b.created_time.split(' ');
            const dateFormatA = a.created_time.split(' ');
            // let dateA = dateFormatA[0] + ' ' + dateFormatA[1];
            // let dateB = dateFormatB[0] + ' ' + dateFormatB[1];
            return dateFormatB - dateFormatA;
          });
        }
        data.sorted_list = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].name;
          let instData = Object.assign({}, data.stacks[name].services.email_sender1);
          instData.instance_id = name;
          instData.nickname = data.sorted_list[0].nickname;
          let date = getConvertedBrowerDate();
          instData.subject = `Test e-mail from ${deviceId}.`;
          instData.body = `This is a test message from e-mail client ${name} on ${deviceId}.\nThe current time is ${date} on the browser of the sender.`
          setSelectedInstance(instData);
          setEditPopup(true);
        }
        client.get('/sdk/email_clients/status/all')
          .then(res => {
            let emailClientStatusData = res.data.data;
            if ('stacks' in emailClientStatusData) {
              let emailClientStatusList = emailClientStatusData.stacks;
              Object.keys(emailClientStatusList).map((eName) => {
                if (eName in data.stacks) {
                  data.stacks[eName].status = emailClientStatusList[eName];
                }
                return eName;
              });
              setEmailClientData(data);
            } else {
              setEmailClientData(data);
            }
            setLoading(false);
          })
          .catch(err => {
            console.log(err);
            setEmailClientData(data);
            setLoading(false);
          });
      }).catch(error => {
        console.log(error);
        setEmailClientData([]);
        setLoading(false);
      });
  }, []);

  const refreshInstance = (event) => {
    setLoading(true);
    document.getElementById('btnRefreshInstance').textContent = 'Refreshing...';
    getEmailClient();
  };

  function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  useEffect(() => {
    if (document.getElementById('btnRefreshInstance')) {
      document.getElementById('btnRefreshInstance').textContent = 'Refresh';
    }
  }, [emailClientData]);

  useEffect(() => {
    getEmailClient();
  }, [getEmailClient]);

  const getClockStatus = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    client.get('/system/clock/status/all')
    .then(res => {
      let data=res.data.data.timezone;
      let splitTimeZone = data.split(' (');
      setCurTimeZone(splitTimeZone[0])
      setStandardTZ(splitTimeZone[1])
      let splitZone = splitTimeZone[1].split(',');
      setStandardTZ(splitZone[0])
    }).catch(error => {
      console.error('clock data');
    });
  },[]);

  useEffect(()=>{
    getClockStatus()
  },[])

  const getDateFormate = (Date,CurTimezone) => {     
    try {
      const zonedTargetDate = convertToTimeZone(Date, { timeZone: CurTimezone });
      return zonedTargetDate;
    } catch (err) {
      console.error(err);
      // return new Date(Date);
    }
  };

  return (
    <ContentDiv>
      { (emailClientData === undefined) &&
        <div className='loading-msg'>
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress />
        </div>
      }
      { emailClientData &&
        <Wrapper style={{ 'padding': '20px' }}>
          <Row>
            <SectionHeader>
              <SectionLabel>Email Client</SectionLabel>
            </SectionHeader>
          </Row>
          <Row>
            <ButtonContainer>
              {emailClientData.stacks && Object.keys(emailClientData.stacks).length > 0 &&
                <>
                  <Button primary id='btnEditInstance' name='btnEditInstance' onClick={() => editInstance()}>Edit</Button>
                  <Button primary id='btnsendEmail' name='btnsendEmail' onClick={() => sendEmail()}>Send Test Email</Button>
                </>
              }
              {emailClientData.stacks && Object.keys(emailClientData.stacks).length > 0 &&
                <Button primary id='btnRefreshInstance' name='btnRefreshInstance' disabled={loading} onClick={() => refreshInstance()}>Refresh</Button>
              }
              <Button primary id='btnAddInstance' name='btnAddInstance' onClick={() => addNewInstance()}>Add</Button>
              <Popup modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                <EmailClientPopup isEditPopup={isEditPopup}  closePopup={closePopup} instanceData={isEditPopup  || testEmail ?   selectedInstance : undefined} emailClientData={emailClientData} testEmailSend={testEmail} />
              </Popup>
            </ButtonContainer>
          </Row>
          {emailClientData.stacks && Object.keys(emailClientData.stacks).length > 0 &&
            <>
              <TableLayout>
                <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                  <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                    <Th type='radio' id='select_radio' style={{ 'cursor': 'pointer', 'width': '50px' }} name='select_radio' ></Th>
                    <Th style={{'text-align': 'left', 'width':'20%'}}>Nickname <br /> (Instance ID)</Th>
                    <Th>Status</Th>
                    <Th>Outbox </Th>
                    <Th>Last Sent</Th>
                    <Th>Created</Th>
                    <Th>AWS Region</Th>
                    <Th>Data Directory</Th>
                    <Th>Enabled</Th>
                  </tr>
                  {Object.keys(emailClientData.sorted_list).map((index) => {
                    let instName = emailClientData.sorted_list[index].name;
                    let emailData = emailClientData.sorted_list[index].services.email_sender1;
                    let nickname =  emailClientData.stacks[instName].nickname ?  emailClientData.stacks[instName].nickname : '';
                    let formattedDate = emailClientData.stacks[instName].created_time.split(' ');
                    var newDate = new Date(formattedDate*1000);
                    let calculatedDate = getDateFormate(newDate,CurTimezone);
                    if(calculatedDate === undefined){
                      return ;
                    }
                    let date = format(new Date(calculatedDate), 'yyyy/MM/dd');
                    let time = format(new Date(calculatedDate), 'HH:mm:ss');
                    let ecStatus = 'recovering';
                    let ecOutboxInfo = '0 email(s) - 0 KB';
                    let lastSentTime = '';
                    let lastSentDate = '-';
                    let lastSentStandard = ''
                    if ('status' in emailClientData.stacks[instName]) {
                      ecStatus = emailClientData.stacks[instName].status.running_status.status;
                      let statusServices = emailClientData.stacks[instName].status.services;
                      if ('email_sender1' in statusServices && 'health_status' in statusServices.email_sender1) {
                        let svcHealthStatus = statusServices.email_sender1.health_status.status_detail;
                        let emailCount = svcHealthStatus.outstanding_emails_count;
                        let obSize = formatBytes(parseInt(svcHealthStatus.outstanding_emails_size) + parseInt(svcHealthStatus.outstanding_attachments_size));
                        if(isNaN(obSize)) {
                          obSize = 0;
                        }
                        if(emailCount === '' ) {
                          emailCount = 0;
                        }
                        ecOutboxInfo = emailCount + ' email(s) - ' + obSize + ' KB';
                      }
                    }
                    if ('status' in emailClientData.stacks[instName]) {
                      let lastSentServices = emailClientData.stacks[instName].status.services;
                      if ('email_sender1' in lastSentServices && 'health_status' in lastSentServices.email_sender1) {
                        let lastTime = lastSentServices.email_sender1.health_status.status_detail.last_sent;
                        let formattedDate = lastTime.split(' ');
                        var newFormattedDate = new Date(formattedDate*1000);
                        let newCalculatedDate = getDateFormate(newFormattedDate,CurTimezone);
                        let date = format(new Date(newCalculatedDate), 'yyyy/MM/dd');
                        let time = format(new Date(newCalculatedDate), 'HH:mm:ss');

                        if(newCalculatedDate === '' || newCalculatedDate === undefined) {
                          lastSentDate = '-'
                        } else {
                          lastSentDate = date;
                          lastSentTime = time;
                          lastSentStandard = standaredTZ;
                        }
                      }
                    }

                    let eRegion = '';
                    Constants.AWS_REGIONS.map((region, index) => {
                      if (region.value === emailData.aws_region)
                        return eRegion = Constants.AWS_REGIONS[index].name;
                    }
                    )
                    let result = eRegion.split( '(' )
                    let continent = result[0];
                    let region = result[1];

                    return (
                      <tr>
                        <Td><input type='radio' id='select_radio' style={{ 'cursor': 'pointer' }} defaultChecked={index === '0'} name='select_radio' value={emailData.instance_id} onClick={() => radioButtonOnChange(emailData.instance_id)}></input></Td>
                        <Td>
                          <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                              <div style={instNameStyle} title={nickname}>{nickname}</div>
                              <div style={instNameStyle} title={instName}>({instName})</div>
                          </div>
                        </Td>
                        <Td style={{ 'text-align': 'center', 'white-space': 'nowrap' }}>
                          {emailData.status === '-' ?
                            '-' :
                            <label className={ecStatus + '-sdk'}>
                              {getDisplayLabel(Constants.STATUS_TYPES, ecStatus)}
                            </label>}
                        </Td>
                        <Td>
                          <OutBoxContainer>
                            {ecOutboxInfo === '-' ?
                              '-' :
                              <>
                                {ecOutboxInfo}
                                <EmailDeleteButton primary onClick={() => clearOutbox(emailData.instance_id)}>Email</EmailDeleteButton>
                              </>}
                          </OutBoxContainer>
                        </Td>
                        <Td>{lastSentDate}<br />{lastSentTime} {lastSentDate === '-' ? '' : lastSentStandard }</Td>
                        <Td>{date}<br />{time} {standaredTZ}</Td>
                        <Td>{continent}<br />({region}</Td>
                        <Td>
                          <Container>
                            <DataDirectoryContainer id={instName + 'data_directory'} style={{ 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', 'max-width': '200px' }}>
                              {Constants.EMAIL_DATA_DIR + '/' + emailData.instance_id}
                            </DataDirectoryContainer>
                            <CopyButtonEmail primary onClick={() => clickToCopy(instName + 'data_directory')} className={'small-button'} style={{ 'marginLeft': 'auto' }}>Copy</CopyButtonEmail>
                          </Container>
                        </Td>
                        <Td><SelectionControl type='switch' style={{ 'display': 'block', 'paddingTop': '10px' }} id={emailData.instance_id} name={emailData.instance_id} value={emailClientData.stacks[instName].enabled} checked={emailClientData.stacks[instName].enabled} onChange={enableToggle} /></Td>
                      </tr>
                    );
                  })}
                </table>

              </TableLayout>
              <div style={{'font-size': '0.9em',  'color': 'grey', 'display': 'flex', 'flex-direction': 'column', 'width': '100%'}}>
                <TextFieldLabel style={{ 'width': 'auto', 'text-size-adjust': '100%'}}><strong>Data Directory</strong><br />It is the path inside sdk volume where respective email client would look for messages to send. To send a message, first create a file whose name is transferring.SOME_NAME.eml in this data directory (e.g. transferring.test_email.eml). Once you are done to write your message contents in transferring.SOME_NAME.eml, then rename this file to SOME_NAME.eml (e.g. test_email.eml). By this way, email client will not accidentally send a message in the middle of your writing to it.</TextFieldLabel>
                <TextFieldLabel style={{ 'width': 'auto', 'margin-top': '10px', 'text-size-adjust': '100%' }}>
                  The expected format of the email messages in the directory is shown as below. Replace bold letters for your demands.<br />
                </TextFieldLabel>
                <TextFieldLabel style={{'border': '1px solid #D3D3D3', 'padding': '10px', 'width': '100%', 'max-width': '400px', 'margin-top': '10px'}}>
                Content-Type: <strong> text/plain; charset=UTF-8 </strong><br />
                Content-Transfer-Encoding: <strong>ANSI </strong><br />
                From: <strong>john.doe@example.com </strong><br />
                Subject: <strong>Subject of your email </strong><br />
                To: <strong>test1@example.com, test2@example.com </strong><br />
                {/* Cc: <strong>test3@example.com, test4@example.com </strong><br />
                Bcc: <strong>test5@example.com, test6@example.com  </strong><br /><br /> */}
                <strong>BODY TEXT OF EMAIL WILL COME HERE </strong><br /><br />
                <strong>Thanks &amp; Regards,</strong><br />
                <strong>John Doe</strong>
              </TextFieldLabel>
              </div>
            </>
      }
      {emailClientData && Object.keys(emailClientData.stacks).length === 0 &&
        <CenterDiv>
          <Label>No instance found.</Label>
        </CenterDiv>
      }
        </Wrapper>
      }
    </ContentDiv >
  );
};

function getDefaultEmailClientValues() {
  return Constants.EMAIL_CLIENT_DEFAULT_VALUE;
}

const EmailClientPopup = (props) => {
  const { isEditPopup, closePopup, instanceData, emailClientData, testEmailSend } = props;
  const [emailClientSettings, setClientEmailSettings] = useState(instanceData === undefined ? getDefaultEmailClientValues() : instanceData);
  const [validation, setValidation] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [inpval, setInpval] = useState({})

  useEffect(()=>{
    // const getuserArr = localStorage.getItem("user_details");
    // const a = JSON.parse(getuserArr);
  //  if(getuserArr !== null){
  //   setClientEmailSettings({...emailClientSettings, from:a[0].from, to:a[0].to});
  //  }
   if(testEmailSend){
    const getuserArr = localStorage.getItem("user_details");
    const a = JSON.parse(getuserArr);
    if(a==null){
     return;
    }
    else if(a !==null || a !== undefined){
      if (a[0].from !== undefined && a[0].to !== undefined) {
        setClientEmailSettings({ ...emailClientSettings, from: a[0].from, to: a[0].to });
      }
    }
    //  if (a[0].from !== undefined && a[0].to !== undefined) {
    //    setClientEmailSettings({ ...emailClientSettings, from: a[0].from, to: a[0].to });
    //  }
  //  if(getuserArr !== null){
  //   setClientEmailSettings({...emailClientSettings, from:a[0].from, to:a[0].to});
  //  }
   }
    
  },[]);

  useEffect(() => {
    if (emailClientSettings.instance_id === '') {
      if (emailClientData.sorted_list.length !== undefined) {
        setClientEmailSettings({ ...emailClientSettings, instance_id: 'emailclient' + (emailClientData.sorted_list.length + 1) });
      } else {
        setClientEmailSettings({ ...emailClientSettings, instance_id: 'emailclient1' });
      }
    }
  }, []);
  const handleOnChange = (event) => {
    const { value, name } = event.target;
    // setInpval(() => {
    //     return {
    //         ...inpval,
    //         [name]: value
    //     }
    // })
    const getuserArr = localStorage.getItem("user_details");
   
    if (event.target.name === 'instance_id') {
      if (event.target.value.charAt(0) === '_') {
        return event.target.value.replace(/[^\w]/g, '');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    } else if (event.target.name === 'days') {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '');
    } else if ((event.target.name === 'aws_access_key' || event.target.name === 'aws_secret_key') && event.target.value.includes(' ')) {
      event.target.value = event.target.value.trim();
    } else if ((event.target.name === 'aws_access_key' || event.target.name === 'aws_secret_key') && event.target.value.includes('$')) {
      event.target.value = event.target.value.replace('$', '');
    } else if(event.target.name === 'aws_secret_key'){
      event.target.value = event.target.value.replace(/[{}()~*?"'\\^$|#\s]/g, '');
    } else if(event.target.name === 'nickname') {
      if (event.target.value.charAt(0) === ' ') {
        return event.target.value.replace(/[^\w]/g, '');
      }
    }
    setClientEmailSettings({ ...emailClientSettings, [event.target.name]: event.target.value });
  };

  const validateEmail = (email) => {
    if(email === '') return true;
    let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }


  const onVerify = () => {
    let jsonData = Object.assign({}, emailClientSettings);
    let emailClientName = jsonData.instance_id;
    let from = jsonData.default_from_addr;
    let to = jsonData.default_to_addr;
    let awsRegion = jsonData.aws_region;
    let awsAccessKey = jsonData.aws_access_key;
    let awsSecretKey = jsonData.aws_secret_key;

    if (isEmpty(awsRegion)) {
      swal('Please enter "Region" for validation', { icon: 'error' });
      return;
    }
    if (isEmpty(awsAccessKey)) {
      swal('Please enter "Access Key" for validation', { icon: 'error' });
      return;
    }
    if (isEmpty(awsSecretKey)) {
      swal('Please enter "Secret Key" for validation', { icon: 'error' });
      return;
    }
    if (from !== undefined) {
      if (!validateEmail(from)) {
        swal({ text: 'Invalid From email ' + from, icon: 'error' });
        return;
      }
    }
    if (isEmpty(from) || from === undefined || from === '' ) {
      swal('Please enter "From" email address for validation', { icon: 'error' });
      return;
    }
    if (isEmpty(to) || to === undefined ||to === '') {
      swal('Please enter "To" email address for validation', { icon: 'error' });
      return;
    }
    if (from !== undefined) {
      if (!validateEmail(from)) {
        swal({ text: 'Invalid From email ' + from, icon: 'error' });
        return;
      }
    }
    if (to !== undefined) {
      let invalidEmails = [];
      let emailsTo = to.split(',');
      for (let i = 0; i < emailsTo.length; i++) {
        if (!validateEmail(emailsTo[i].trim())) {
          invalidEmails.push(emailsTo[i].trim());
          swal({ text: 'Invalid To email ' + invalidEmails, icon: 'error' });
          return;
        }
      }
    }
    let finalJson = {}
    finalJson.namespace = Constants.NAMSPACES.SDK
    finalJson.type = Constants.SDK_SERVICE_TYPES.EMAIL_CLIENT
    finalJson.action = 'email_id_validation'
    finalJson.from = from;
    finalJson.to = to;
    finalJson.aws_access_key_id = awsAccessKey;
    finalJson.aws_secret_access_key = awsSecretKey;
    finalJson.region_name = awsRegion;

    axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/email_validation', finalJson)
    .then(res => {
      let data = res.data;
      if(res.data.status_code === '10100') {
        swal(data.message, {icon: 'error'});
      } else {
        swal(data.message, {icon: 'success'});
      }
    })
    .catch(error => {
      if (error.response) {
        var errorObj = error.response.data;
        swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
      } else {
        swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
      }
    });

  }
  const sendEmail = (event) => {
    event.preventDefault();
  
    let jsonData = Object.assign({}, emailClientSettings);
    let emailClientName = jsonData.instance_id;
    let from = jsonData.from;
    let to = jsonData.to;
    let cc = jsonData.cc;
    let bcc = jsonData.bcc;
    let subject = jsonData.subject;
    let body = jsonData.body;
    let localValues=Object.keys({
      from:emailClientSettings.from,
      to:emailClientSettings.to
    });
    
    if(localValues.length===0){
      let localObject={
        from:jsonData.from,
        to:jsonData.to
      }
      localStorage.setItem("user_details", JSON.stringify([localObject]));
    }else{
      localStorage.setItem("user_details", JSON.stringify([{
        from:emailClientSettings.from,
        to:emailClientSettings.to
      }]));
    }

    let invalidEmails = [];

    if (isEmpty(from)) {
      swal('Please enter "From" email address', { icon: 'error' });
      return;
    }
    if (isEmpty(to)) {
      swal('Please enter "To" email address', { icon: 'error' });
      return;
    }
    if (isEmpty(subject)) {
      swal('Please enter "Subject"', { icon: 'error' });
      return;
    }
    if (isEmpty(body)) {
      swal('Please enter "Email Body"', { icon: 'error' });
      return;
    }

    if (from !== undefined) {
      if (!validateEmail(from)) {
        swal({ text: 'Invalid From email ' + from, icon: 'error' });
        return;
      }
    }

    if (to !== undefined) {
      invalidEmails = [];
      let emailsTo = to.split(',');
      for (let i = 0; i < emailsTo.length; i++) {
        if (!validateEmail(emailsTo[i].trim())) {
          invalidEmails.push(emailsTo[i].trim());
          swal({ text: 'Invalid To email ' + invalidEmails, icon: 'error' });
          return;
        }
      }
    }
    if (cc !== undefined && cc !== '') {
      invalidEmails = [];
      let emailCC = cc.split(',');
      for (let i = 0; i < emailCC.length; i++) {
        if (!validateEmail(emailCC[i].trim())) {
          invalidEmails.push(emailCC[i].trim());
          swal({ text: 'Invalid Cc email ' + invalidEmails, icon: 'error' });
          return;
        }
      }
    } else {
      cc = '';
    }

    if (bcc !== undefined && bcc !== '') {
      invalidEmails = [];
      let emailBCC = bcc.split(',');
      for (let i = 0; i < emailBCC.length; i++) {
        if (!validateEmail(emailBCC[i].trim())) {
          invalidEmails.push(emailBCC[i].trim());
          swal({ text: 'Invalid Bcc email ' + invalidEmails, icon: 'error' });
          return;
        }
      }
    } else {
      bcc = '';
    }


    let finalJson = {};
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = Constants.SDK_SERVICE_TYPES.EMAIL_CLIENT;
    finalJson.action = Constants.SDK_ACTIONS.SEND_TEST_EMAIL;
    finalJson.from = from;
    finalJson.to = to;
    finalJson.cc = cc;
    finalJson.bcc = bcc;
    finalJson.subject = subject;
    finalJson.body = body;

  
    setLoading(true);
    document.getElementById('sendingEmail').textContent = 'Sending...';
    axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/' + emailClientName + '/all', finalJson)
      .then(res => {
        if (res.data.status_code === '10100') {
          setLoading(false);
          document.getElementById('sendingEmail').textContent = 'Send';
          swal('Email sending failed. ', { icon: 'error' });
        } else {
          swal('Email sent successfully.', { icon: 'success' });
          setLoading(false);
          document.getElementById('sendingEmail').textContent = 'Send';
          setTimeout(function () { window.location.reload(); }, 2000);
        }
      })
      .catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
  }

  const saveInstance = (event) => {
    let jsonData = Object.assign({}, emailClientSettings);
    let emailClientName = jsonData.instance_id;
    let awsRegion = jsonData.aws_region;
    let awsAccessKey = jsonData.aws_access_key;
    let awsSecretKey = jsonData.aws_secret_key;
    let retentionDays = jsonData.days;
    let action = isEditPopup ? 'updated' : 'added';

    if (isEmpty(emailClientName)) {
      swal('Please enter instance id', { icon: 'error' });
      return;
    }
    if (isEmpty(awsRegion)) {
      swal('Please enter region', { icon: 'error' });
      return;
    }
    if (isEmpty(awsAccessKey)) {
      swal('Please enter access key', { icon: 'error' });
      return;
    }
    if (isEmpty(awsSecretKey)) {
      swal('Please enter secret key', { icon: 'error' });
      return;
    }
    if (isEmpty(retentionDays)) {
      swal('Please enter day(s)', { icon: 'error' });
      return;
    }
    delete jsonData.subject;
    delete jsonData.body;
    let finalJson = {};
    finalJson.nickname = jsonData.nickname;
    finalJson.enabled = emailClientData.stacks[emailClientName] === undefined ? true : emailClientData.stacks[emailClientName].enabled;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = Constants.SDK_SERVICE_TYPES.EMAIL_CLIENT;
    finalJson.services = {};
    delete jsonData.nickname;
    finalJson.services.email_sender1 = Object.assign({}, jsonData);

    if (action === 'added') {
      axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/' + emailClientName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/' + emailClientName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteInstance = (event) => {
    let emailClientName = emailClientSettings.instance_id;
    swal({
      text: 'Are you sure you want to delete this instance: ' + emailClientName + '?',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
      .then((isConfirm) => {
        if (isConfirm) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/' + emailClientName + '/all')
            .then(res => {
              swal('Instance delete request submitted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      });
  };

  const clickToCopyAWSSESFields = () => {
    const textarea = document.createElement('textarea');
    textarea.id = 'copyTextArea';
    let eRegion = document.getElementById('aws_region').value;
    Constants.AWS_REGIONS.map((region, index) => {
      if (region.value === emailClientSettings.aws_region)
        return eRegion = Constants.AWS_REGIONS[index].name;
    }
    )
    textarea.value = 'AWS SES Configuration \n' + document.getElementById('aws_region_label').innerText + ': ' + eRegion + '\n' + document.getElementById('aws_access_key_label').innerText + ': ' + document.getElementById('aws_access_key').value + '\n' + document.getElementById('aws_secret_key_label').innerText + ': ' + document.getElementById('aws_secret_key').value + '\n';
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.width = '0px';
    textarea.style.height = '0px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.getElementById('copyTextArea').remove();
  };

  const onTabChange = (tabId) => {
    if (tabId === 'general') {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }

  useEffect(() => {
    if (document.getElementById('sendingEmail')) {
      document.getElementById('sendingEmail').textContent = 'Send';
    }
  }, [emailClientData]);

  return (
    <div>
      {testEmailSend ?
      <PopupContainer>
      <SectionHeader style={{ 'display': 'flex' }}>
        <SectionLabel>Send Test Email</SectionLabel>
      </SectionHeader>
          <TabContainer>
            <form autoComplete="on">
            <InputFieldContainer>
              <MandatoryLabel>From</MandatoryLabel>
              <TextFieldSmall id="from" name="from" placeholder="e.g. abc@xyz.com" value={emailClientSettings.from} onChange={handleOnChange} style={{ 'width': '100%', 'max-width': '250px'}}/>
            </InputFieldContainer>
            <InputFieldContainer>
              <MandatoryLabel>To</MandatoryLabel>
              <TextFieldSmall id="to" name="to" placeholder="e.g. test1@example.com, test2@example.com" value={emailClientSettings.to} onChange={handleOnChange}  style={{ 'width': '100%', 'max-width': '250px'}}/>
            </InputFieldContainer>
            <InputFieldContainer>
              <MandatoryLabel>Subject</MandatoryLabel>
              <TextFieldSmall id="subject" name="subject" value={emailClientSettings.subject} onChange={handleOnChange}  style={ {'width': '100%', 'max-width': '250px'}}/>
            </InputFieldContainer>
            <Row>
              <MandatoryLabel>Email Body</MandatoryLabel>
              <div style={{ 'margin': '10px 5px 0px 0px', 'width': '100%' }}>
                <TextArea id="body" name="body" value={emailClientSettings.body} onChange={handleOnChange} style={{'height':'140px'}} />
              </div>
            </Row>
            </form>          
          </TabContainer>
      <Row>
        <PopupButtons>
            <Button primary onClick={sendEmail} disabled={loading} id='sendingEmail' >Send</Button> 
            <Button id='btnCancleEmail' danger onClick={() => { closePopup(); }}>Cancel</Button>
        </PopupButtons>
      </Row>
    </PopupContainer> : 
    <PopupContainer>
    <SectionHeader style={{ 'display': 'flex' }}>
      <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Instance</SectionLabel>
      {isEditPopup &&
        <DeleteButtonContainer style={{ 'marginLeft': 'auto' }}>
          <Button danger id='btnDeleteInsatnce' name='btnDeleteInsatnce' style={{ 'margin': '0px' }} onClick={deleteInstance} >Delete</Button>
        </DeleteButtonContainer>
      }
    </SectionHeader>
    <TabProvider defaultTab='general' style={{ 'padding': '6px' }} onChange={(tabId) => onTabChange(tabId)}>
      <TabList>
        <Tab tabFor='general' className={'rwt__tab'}>General</Tab>
        <Tab tabFor='advanced' className={'rwt__tab'}>Advanced</Tab>
      </TabList>
      <TabPanel tabId='general'>
        <TabContainer>
          <InputFieldContainer>
            <MandatoryLabel>Instance ID</MandatoryLabel>
            <TextFieldSmall id='instance_id' autoComplete='off' name='instance_id' style={{ 'marginLeft': '24px', 'flexGrow': '1' }} value={emailClientSettings.instance_id} onChange={handleOnChange} disabled={isEditPopup} maxLength='63' />
          </InputFieldContainer>
          <InputFieldContainer>
            <TextFieldLabel>Nickname</TextFieldLabel>
            <TextFieldSmall id='nickname' autoComplete='off' name='nickname' style={{ 'marginLeft': '24px', 'flexGrow': '1', 'maxWidth': '205px' }} value={emailClientSettings.nickname} onChange={handleOnChange} maxLength='100' />
          </InputFieldContainer>
          <Row>
            <FieldSet>
              <legend>AWS SES Configuration</legend>
              {isEditPopup && <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}><CopyButton primary onClick={() => clickToCopyAWSSESFields()}>Copy</CopyButton></div>}
              <FieldSetInnerContainer>
                <InputFieldContainer>
                  <MandatoryLabel id='aws_region_label'>Region</MandatoryLabel>
                  <SelectField id="aws_region" name="aws_region" style={{ 'margin-top': '10px', 'flexGrow': '1', }} value={emailClientSettings.aws_region} onChange={handleOnChange}>
                    <SelectOptions items={Constants.AWS_REGIONS} />
                  </SelectField>
                </InputFieldContainer>
                <InputFieldContainer>
                  <MandatoryLabel id='aws_access_key_label'>Access Key</MandatoryLabel>
                  <TextFieldSmall id='aws_access_key' autoComplete='off' name='aws_access_key' style={{'flexGrow': '1'}} value={emailClientSettings.aws_access_key} onChange={handleOnChange} maxLength='255' />
                </InputFieldContainer>
                <InputFieldContainer style={{paddingRight: '10px'}}>
                  <MandatoryLabel id='aws_secret_key_label'>Secret Key</MandatoryLabel>
                  <PasswordField id='aws_secret_key' type='text' name='aws_secret_key' width='100%' setInput={handleOnChange} value={emailClientSettings.aws_secret_key} maxLength='255' />
                </InputFieldContainer>
                <InputFieldContainer>
                  <TextFieldLabel id='default_from_addr'>From</TextFieldLabel>
                  <TextFieldSmall id='default_from_addr' autoComplete='off' name='default_from_addr' style={{'flexGrow': '1'}} value={emailClientSettings.default_from_addr} maxLength='255' onChange={handleOnChange}  />
                </InputFieldContainer>
                <InputFieldContainer>
                  <TextFieldLabel id='default_to_addr'>To</TextFieldLabel>
                  <TextFieldSmall id='default_to_addr' autoComplete='off' name='default_to_addr' style={{'flexGrow': '1'}} value={emailClientSettings.default_to_addr} maxLength='255' onChange={handleOnChange} />
                </InputFieldContainer>
              </FieldSetInnerContainer>
            </FieldSet>
          </Row>
        </TabContainer>
      </TabPanel>
      <TabPanel tabId='advanced'>
        <TabContainer style={{height:'350px'}}>
          <Row style={{ 'width': '100%', 'max-width': '400px' }}>
            <TextFieldLabel style={{ 'color': 'grey', 'display': 'flex-wrap', 'width': '100%' }}>If emails are not sent to this amount of time, they will expire and deleted to avoid the local storage full.</TextFieldLabel>
          </Row>
          <Row>
            <MandatoryLabel style={{ 'width': '100px' }}>Day(s)</MandatoryLabel>
            <SmallTextField id='days' name='days' style={{ 'marginLeft': '15px' }} value={emailClientSettings.days} onChange={handleOnChange} maxLength='2' />
          </Row>
          <Row>
            <TextFieldLabel style={{ 'width': '100px' }}>Hour(s)</TextFieldLabel>
            <SelectField className='schedule-hours' id='hours' name='hours' value={emailClientSettings.hours} onChange={handleOnChange}>
              <SelectOptions items={Constants.HOURS} />
            </SelectField>
          </Row>
          <Row>
            <TextFieldLabel style={{ 'width': '100px' }}>Minute(s)</TextFieldLabel>
            <SelectField className='schedule-hours' id='minutes' name='minutes' value={emailClientSettings.minutes} onChange={handleOnChange}>
              <SelectOptions items={Constants.MINUTES} />
            </SelectField>
          </Row>
        </TabContainer>
      </TabPanel>
    </TabProvider>
    <Row style={{'display': 'flex', 'justifyContent': 'center'}}>
      <PopupButtons>
        {validation && <Button primary onClick={onVerify}>Validate Configurations</Button>}
        <Button primary onClick={saveInstance} >Save</Button>
        <Button id='btnCancleEmail' danger onClick={() => { closePopup(); }}>Cancel</Button>
      </PopupButtons>
    </Row>
  </PopupContainer>}
    </div>
  );
};

export default EmailClient;

