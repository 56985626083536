import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Wrapper, Label, LeftDiv, Spacer, TextFieldLabel, MessageLabel, TextFieldSmall, Th, Td, TextField, TextArea, SelectField, Row, Column, RightDiv, SectionHeader, Button, SectionLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { SelectionControl } from 'react-md';
import swal from "sweetalert";
import styled from 'styled-components';
import Loader from './Loader';
import axiosRetry from 'axios-retry';

const ResponsiveColumn = styled(Column)`
  padding: 0;
  margin: 0px 0 10px 20px;
  width: 300px;
  display: flex;
  @media screen and (max-width: 400px) {
      width: 250px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 10px;
`;

const TableContainer = styled.div`
  white-space: nowrap;
`;

const TableLayout = styled(TableContainer)`
  margin-top: 10px;
  width: 100%;
`;

const ErrorLabel = styled.label`
  display:inline-block;
  width: auto;
  color: red;
  margin-top: 21px;
`;

const EmptyLabel = styled.label`
  display:inline-block;
  width: auto;
  margin-top: 21px;
`;

const ContentDiv=styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const DNSSettings = (props) => {
    const [dns, setDns] = useState();
    const [configure, setConfigure] = useState();
    const [dnsLoading, setDnsLoading] = useState(true);
    const [readOnly, setReadOnly] = useState(true);
    const [interfaceList, setInterfaceList] = useState();
    const [interfaceError, setInterfaceError] = useState(true);
    const [interfaceLoading, setInterfaceLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [deleteDNS, setDeleteDNS]=useState(false);

    useEffect(()=>{
      var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
      } else {
        setIsMobileBrowser(isMobileBrowser);
      }
    },[]);

    const getDNS = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{
            retries: 15,
            retryDelay: (retryCount, error) => {
                if(retryCount === 15) {
                  swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
          });
        client.get('/system/network/interface/dns/all')
        .then(res => {
            if(res.data.data.action === 'DeleteStack') {
                setReadOnly(false);
                setDeleteDNS(false);
                setDnsLoading(false);
                setConfigure(true);
            }else {
                if((res.data.data.global_dns.preferred_dns === ' ' && res.data.data.global_dns.alternate_dns === ' ') || (res.data.data.global_dns.preferred_dns === '' && res.data.data.global_dns.alternate_dns === '')) {
                    setConfigure(true);
                    setReadOnly(false);
                    setDnsLoading(false);
                } else {
                    setDns(res.data.data.global_dns);
                    setConfigure(false);
                    setDnsLoading(false);
                }
            }
        }).catch(error => {
            console.log(error);
            setConfigure(true);
            setReadOnly(false);
            setDnsLoading(false);
        });
    }

    const getDNSStatus = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{
            retries: 15,
            retryDelay: (retryCount, error) => {
                if(retryCount === 15) {
                  swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
          });
          client.get('/system/network/interface/dns/status/all')
        .then(res => {
            setInterfaceList(res.data.data[0]);
            setInterfaceError(false);
            setInterfaceLoading(false);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setInterfaceError(true);
            setInterfaceLoading(false);
            setLoading(false);
            setInterfaceList();
            swal({ text: 'Could not fetch DNS information.', icon: 'error' });   
        });
    }

    const deleteGlobalDNS = () => {
        swal({text: "\n\n Are you sure you want to delete this global DNS?",
              buttons: ['No', 'Yes'],
              dangerMode: true
        })
        .then((isConfirm) => {
            if (isConfirm) {
                axios.delete(Constants.EDGE_API_ENDPOINT + '/system/network/interface/dns/all')
                    .then(res => {
                        swal("Global DNS setting deleted successfully.", { icon: "success"});
                        getDNS();
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        }else{
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
            }
        });
    }

    const configuredHandle = () => {
        setDns(Constants.DNS_DEFAULT_VALUE);
        setConfigure(false);
    }

    const handleOnChange = (event) => {
        if (!readOnly) {
            if ((event.target.name === 'preferred_dns' || event.target.name === 'alternate_dns' ) && event.target.value.includes(' ')) {     
                event.target.value = event.target.value.replace(/\s/g, '');    
            } else {
                if(event.target.name === 'preferred_dns' || event.target.name === 'alternate_dns' ) {
                    event.target.value = event.target.value.replace(/[^.:\w]/g, '');   
                } 
                setDns({ ...dns, [event.target.name]: event.target.value });
            }
        }
    }

    function ValidateIPaddress(ipaddress) {
        if(ipaddress === '') {
            return true;
        } else {
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
                return true
            }else if(/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(ipaddress)){
                return true;
            }
            swal('Please enter valid DNS IP ', { icon: 'error'});
            return false
        }
        
    }

    const editDNS = () => {
        setReadOnly(false);
        setDeleteDNS(true)
    }

    const saveDNS = () => {
        let jsonData = Object.assign({}, dns);
        if(jsonData.preferred_dns == undefined || jsonData.alternate_dns === undefined){
            jsonData.preferred_dns = ''
            jsonData.alternate_dns = ''
        }
        if(jsonData.preferred_dns === '' && jsonData.alternate_dns === '') {
            swal('Please enter atleast one "DNS"', { icon: 'error'});
            return;
        }
        if(!ValidateIPaddress(jsonData.preferred_dns)) {
            return;
        }
        if(!ValidateIPaddress(jsonData.alternate_dns)) {
            return;
        }
        jsonData.type = 'network'
        axios.patch(Constants.EDGE_API_ENDPOINT + '/system/network/interface/dns/all', jsonData)
            .then(res => {
                swal('Global DNS saved successfully', { icon: 'success', timer: '2000'});
                setReadOnly(true)
            })
            .catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
                } else {
                    swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
                }
            });
    }

    const refreshInterfaces = (event) => {
        setLoading(true);
        console.log(document.getElementById('btnRefresh').textContent, 'length')
        document.getElementById('btnRefresh').textContent = 'Refreshing...';
        console.log(document.getElementById('btnRefresh').textContent, 'length')
        getDNSStatus();
      };
    
      useEffect(() => {
        if (document.getElementById('btnRefresh')) {
          document.getElementById('btnRefresh').textContent = 'Refresh';
        }
      }, [interfaceList]);

      const refreshInterfacesError = (event) => {
        setLoading(true);
        console.log(document.getElementById('btnRefreshError').textContent, 'lengtherror')
        document.getElementById('btnRefreshError').textContent = 'Refreshing...';
        console.log(document.getElementById('btnRefreshError').textContent, 'length')
        getDNSStatus();
      };
    
      useEffect(() => {
        if (document.getElementById('btnRefreshError')) {
          document.getElementById('btnRefreshError').textContent = 'Refresh';
        }
      }, [loading, interfaceList]);
       
      useEffect(()=>{
        getDNS();
        getDNSStatus();
    },[]);

    return (
        <>
            <ContentDiv>
                <Wrapper style={{'padding': '5px'}}>
                    <SectionHeader>
                        <SectionLabel>Current DNS used by Interfaces</SectionLabel>
                    </SectionHeader>
                    <Loader isLoading={interfaceLoading} />
                    {interfaceError && !interfaceLoading &&
                    <Row style={{width: 'auto'}}>
                        <Column  style={{'display': 'flex', 'float': 'left', padding:'0', 'marginLeft': '20px', width: 'auto'}} >
                            <Button primary id="btnRefreshError" name="btnRefreshIfaces" style={{'marginLeft':'0'}} disabled={loading} onClick={() => refreshInterfacesError()}>Refresh</Button>
                        </Column>
                    </Row>}
                    {interfaceList && Object.keys(interfaceList).length === 0 &&
                    <Row style={{width: 'auto'}}>
                        <Column  style={{'display': 'flex', 'float': 'left', padding:'0', 'marginLeft': '20px', width: 'auto'}} >
                            <EmptyLabel style={{'width': 'auto'}}>No Interface found.</EmptyLabel>
                            <Button primary id="btnRefresh" name="btnRefreshIfaces" disabled={loading} onClick={() => refreshInterfaces()}>Refresh</Button>
                        </Column>
                    </Row>}
                    {interfaceList && Object.keys(interfaceList).length > 0 && 
                    <Row>
                        <ResponsiveColumn style={{'justifyContent':'flex-end'}}>
                            <Button primary id="btnRefresh" name="btnRefreshIfaces" style={{'marginBottom': '0'}} disabled={loading} onClick={() => refreshInterfaces()}>Refresh</Button>
                        </ResponsiveColumn>
                    </Row>}
                    {interfaceList && Object.keys(interfaceList).length > 0 && 
                    <Row>
                        <ResponsiveColumn>
                            <TableLayout>
                                <table style={{ 'width': '100%', 'border-collapse': 'collapse'}}>
                                    <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                                        <Th style={{'padding': '10px','text-align': 'left'}}>Interface</Th>
                                        <Th style={{'padding': '10px'}}>DNS</Th>
                                    </tr>
                                    {Object.keys(interfaceList).map((key)=>{
                                        return(
                                            <tr>
                                                <Td style={{'padding': '10px', 'text-align': 'left'}}>{key}</Td>
                                                <Td style={{'padding': '10px'}} id={key}>{interfaceList[key] === '' ? '-' : interfaceList[key]}</Td>
                                            </tr>
                                        )
                                    })}
                                </table>
                            </TableLayout>
                        </ResponsiveColumn>
                    </Row>}
                    {!interfaceLoading && 
                    <Row>
                    <div style={{'marginLeft': '20px'}}>
                        <MessageLabel style={{'text-size-adjust': '100%'}}>
                        <b>Note : </b>Current DNS of interface is affected by <br /> following settings:<br/>
                        <div style={{'marginLeft': '20px', 'marginTop': '5px'}}>
                            <li>Global DNS</li>
                            <li>Static DNS setting of Interface</li>
                            <li>DHCP Server configuration</li>
                        </div>
                        </MessageLabel>
                    </div>
                    </Row>}
                </Wrapper>
            </ContentDiv>
            <Spacer />
            <ContentDiv>
                <Wrapper style={{'padding': '5px'}}>
                    <SectionHeader>
                    <SectionLabel>Global DNS</SectionLabel>
                    </SectionHeader>
                    <Loader isLoading={dnsLoading} />
                    {configure && !dnsLoading &&
                        <div style={{'marginLeft': '5px'}}>
                            <Row>
                                <Label style={{'margin-left': '15px', 'margin-top': '10px' }}>Global DNS is not configured.</Label>
                            </Row>
                            <Row>
                                <Button primary onClick={configuredHandle}>Configure</Button>
                            </Row>
                        </div>}
                        {dns && !dnsLoading && !configure &&
                            <>
                            {
                               !readOnly && deleteDNS &&
                                <ResponsiveColumn style={{'justifyContent': 'flex-end','marginBottom':'-15px'}}>
                                    <Button id="deleteDNS" name="deleteDNS" danger onClick={deleteGlobalDNS}>Delete</Button>
                                </ResponsiveColumn>
                            }
                           <Row>
                                <ResponsiveColumn style={{flexDirection: 'column'}}>
                                    <div style={{display:'flex', 'marginTop': '15px'}}>
                                        <TextFieldLabel id='preferred_dns' style={{'margin-top': '10px'}}>Preferred DNS</TextFieldLabel>
                                        <TextFieldSmall id="preferred_dns" autoComplete="off" name="preferred_dns" readOnly={readOnly} placeholder='8.8.8.8' value={dns.preferred_dns} onChange={handleOnChange} style={{'width': '100%', 'minWidth': '100px', 'textTransform':'lowercase'}} />
                                    </div>
                                    <div style={{display:'flex', 'marginTop': '15px'}}>
                                        <TextFieldLabel id='alternate_dns' style={{'margin-top': '10px'}}>Alternate DNS</TextFieldLabel>
                                        <TextFieldSmall id="alternate_dns" autoComplete="off" name="alternate_dns" readOnly={readOnly} placeholder='8.8.4.4' value={dns.alternate_dns} onChange={handleOnChange} style={{'width': '100%', 'minWidth': '100px', 'textTransform':'lowercase'}} />
                                    </div>
                                </ResponsiveColumn>
                            </Row>
                            <Row>
                            {readOnly ?
                                <ResponsiveColumn style={{'justifyContent': 'flex-end'}}>
                                    <Button primary onClick={editDNS}>Edit</Button>
                                </ResponsiveColumn> :
                                <ResponsiveColumn style={{'justifyContent': 'flex-end'}}>
                                    <Button primary onClick={saveDNS}>Save</Button>
                                    <Button id='btnCancle' danger onClick={() =>  window.location.reload()}>Cancel</Button>
                                </ResponsiveColumn>}
                            </Row>
                        </>}
                </Wrapper>
            </ContentDiv>
        </>
    )
}

export default DNSSettings;