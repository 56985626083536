import React, { useState, useEffect,useMemo, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import { Column, SelectField, Row, Label, Wrapper, Button, SectionHeader, SectionLabel, RightDiv, Link, MessageLabel, TableContainer, Td, Th, overlayStyle, PopupButtons, PopupContainer, TextFieldLabel, TextFieldSmall, SelectFieldLabel, SelectFieldSmall } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import swal from 'sweetalert';
import PasswordField from './PasswordField.js';
import Popup from 'reactjs-popup';
import { Multiselect } from 'multiselect-react-dropdown';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import {validateIpAddress } from './NetworkSettings.js';
import axiosRetry from 'axios-retry';

const MessageContaier = styled.div`
  padding: 5px 5px 1px 5px;
`;

const ResponsiveButton = styled(Button)`
  margin: 0;
  @media screen and (max-width: 900px) {
    font-size: 0.8em;
  }
  @media screen and (max-width: 830px) {
    font-size: 0.7em;
  }
`;

const ResponsiveTextFieldLabel = styled(TextFieldLabel)`
  width: 170px;
  @media screen and (max-width: 500px) {
    width: 120px;
  }
`;

const ResponsiveTextFieldLabel1 = styled(TextFieldLabel)`
  width: 109px;
  @media screen and (max-width: 900px) {
    width: 83px;
  }
  @media screen and (max-width: 700px) {
    width: 67px;
    
  }
  @media screen and (max-width: 600px) {
    width: 65px;
  }
  @media screen and (max-width: 500px) {
    width: 50px;
    margin-right:5px;
  }
  @media screen and (max-width: 400px) {
    width: 50px;
    margin-right:5px;
  }
`;

const ResponsiveTextFieldLabel2 = styled(TextFieldLabel)`
  width: 170px;
 
  @media screen and (max-width: 500px) {
    width: 55px;
  }
`;
const ResponsiveTextFieldLabel1Name=styled(TextFieldLabel)`
width: 74px;
@media screen and (max-width: 900px) {
  width: 57px;
}
@media screen and (max-width: 700px) {
  width: 46px;
}
@media screen and (max-width: 600px) {
  width: 65px;
}
@media screen and (max-width: 500px) {
  width: 55px;
}
@media screen and (max-width: 400px) {
  width: 55px;
}
`;

const ResponsiveTextFieldLabel1Name2=styled(TextFieldLabel)`
width: 170px;
@media screen and (max-width: 500px) {
  width: 55px;
}
`;

const ResponsiveSelectFieldLabel = styled(SelectFieldLabel)`
  width: 170px;
  @media screen and (max-width: 500px) {
    width: 120px;
  }
`;

const BondPopupContainer = styled.div`
  padding: 0px 20px 20px 20px;
  height: 355px;
  width: 440px;
  @media screen and (max-width: 500px) {
    width: 350px;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
  }
  @media screen and (max-width: 400px) {
    width: 300px;
  }
`;

const WifiRow = styled(Row)`
  displa: flex;
  align-items: center;
`;

const ResponsiveTextFieldSmall = styled(TextFieldSmall)`
  flex-grow: 1;
  width:100%
`;

const WifiPopupContainer = styled.div`
  @media screen and (max-width: 1200px) {
    width: 900px;
  }
  @media screen and (max-width: 1000px) {
    width: 800px;
  }
  @media screen and (max-width: 900px) {
    width: 650px;
  }
  @media screen and (max-width: 700px) {
    width: 550px;
  }
  @media screen and (max-width: 600px) {
    width: 475px;
  }
  @media screen and (max-width: 500px) {
    width: 375px;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
  }
  @media screen and (max-width: 400px) {
    width: 300px;
  }
`;
const ResponsiveInputFields1=styled.div`
@media screen and (max-width: 500px) {
  height:55px;
  display:flex;
  width: 430px;
  padding:0px 10px 0px 10px;
  width: 583px;
}
@media screen and (max-width: 400px) {
  height:55px;
  display:flex;
  width: 430px;
  padding:0px 10px 0px 10px;
  width: 583px;
}
`;
const ResponsiveInputFields2=styled.div`
@media screen and (max-width: 500px) {
  height:50px;
  display:flex;
  width: 430px;
  padding:0px 10px 0px 10px;
  margin:0px 0px 30px 0px;
  width: 583px;
}
@media screen and (max-width: 400px) {
  height:50px;
  display:flex;
  width: 430px;
  padding:0px 10px 0px 10px;
  margin:0px 0px 30px 0px;
  width: 583px;
}
`;

const ContentDiv=styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ResponsivePopupContainer = styled.div`
  width:  100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  margin-left:50px;
  z-index: 5;
  & > div {
    @media screen and (max-width: 750px) {
      transform: scale(0.8);
      background: transparent !important;
    }
  }
`;

const TextFieldLabel1 = styled(Label)`
  width: 100px;
  margin-top: 25px;
  @media screen and (max-width: 500px) {
    width: 55px;
  }
  @media screen and (max-width: 400px) {
    width: 55px;
  }
`;
const SelectField1 = styled.select`
  width: 205px;
  font-size: 1em;
  padding: 6px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${props => props.error ? 'red' : 'lightgray'};
  margin: 0;
  @media screen and (max-width: 500px) {
    width: 150px;
    margin:0px 15px 0px 0px;
  }
  @media screen and (max-width: 400px) {
    width: 150px;
    margin:0px 15px 0px 0px;
  }
  &:disabled {
    color: #000000;
    background: #f2f2f2;
  };
  &:enabled {
    background: #ffffff;
  }
  &:focus {
    outline: none;
    border-color: rgb(137, 188, 225);
  }
`;

const getSsidData = () => {
  return {
    'ssid': '',
    'security_type': 'WPA2-PSK',
    'security_key': '',
    'priority': '',
    'auto_connect': true,
    'scan_ssid': false
  };
};

const getDefaultSsidData = () => {
  let data = {
    'port_name': '',
    'nickname': '',
    'sorted_list': []
  };
  return data;
};

const getBondData = () => {
  return {
    'port_name': '',
    'nickname': '',
    'members': [],
    'mode': 'active-backup',
    'failover_mac_policy': 'follow',
    'link_monitor_type': 'mii',
    'mii_interval': '100',
    'arp_interval': '1000',
    'arp_targets': '',
    'updelay': '1000',
  };
};

const NetworkPorts = (props) => {
  const [readOnly, setReadOnly] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [portsData, setPortsData] = useState();
  const [selectedPort, setSelectedPort] = useState();
  const [selectedPortType, setSelectedPortType] = useState();
  const [apEnabled, setApEnabled] = useState(false);
  const [refreshPopup, setRefreshPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popUpLoading, setPopupLoading] = useState(false);
  const [retry,setRetry]=useState(false)
  const [isLinkDetails, setIsLinkDetails] = useState(false);
  const [linkDetailsData, setLinkDetailsData] = useState();
  const [portList, setPortList] = useState();
  const [brcmEnable, setBrcmEnable] = useState(false);
  const [ap_wifi_type, setap_wifi_type] = useState();
  const [port_name, setPortName] = useState();
  const [portNameList, setPortNameList] = useState();

  const getPortName = useCallback((name) => {
    console.log(name, 'wifiSettings.port_name111')
    setPortName(name);
  },[]);

  useEffect(()=>{
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/ports')
    .then(res => {
      const list = res.data.data;
      setPortNameList(list);
    });
  },[]);

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: '2px solid #d7d7d7'
  };
  const radioButtonOnChange = useCallback((portName) => {
    let pData = Object.assign({}, portsData.services[portName]);
    pData.port_name = portName;
    setPortName(portName);
    let sortedData = [];
    if ('ssids' in pData){
      Object.keys(pData.ssids).map((ssidName) => {
        let data = pData.ssids[ssidName];
        data.ssid = ssidName;
        sortedData.push(data);
        return ssidName;
      });
      sortedData.sort(function(a, b) {
        return b.priority - a.priority;
      });
      pData.sorted_list = sortedData;
    } else {
      sortedData.sort(function(a, b) {
        return b.priority - a.priority;
      });
      pData.sorted_list = sortedData;
    }
    if(!('type' in pData)){
      if('status' in pData && pData.status.type === 'wlan'){
        pData.sorted_list = [];
      }
    }
    setSelectedPort(pData);
    setReadOnly(false);
    setLinkDetailsData(portName);
  }, [portsData]);

  const editPort = (event) => {
    setReadOnly(false);
    if(selectedPort){
      if('status' in selectedPort){
        setSelectedPortType(selectedPort.status.type);
      } else if ('type' in selectedPort){
        setSelectedPortType(selectedPort.type);
      }
    } else {
      return;
    }
    setModalOpen(true);
    setIsLinkDetails(false);
  };

  const closePopup = useCallback(() => {
    setModalOpen(false);
    if(port_name != undefined) {
      radioButtonOnChange(port_name, true);
    }
  }, [port_name]);

  const addPort = (portType) => {
    setReadOnly(true);
    setSelectedPortType(portType);
    setSelectedPort(undefined);
    setModalOpen(true);
    setIsLinkDetails(false);
  };

  const showLinkDetails = () => {
    setModalOpen(true);
    setIsLinkDetails(true);
    setReadOnly(false);
  };

  const getConfiguredInterfacelList = (portData) => {
    const dataList = Object.keys(portData.services);
    let sortedData = [];
    Object.keys(dataList).map((idx) => {
      let data = {};
      let pData = dataList[idx];
      data.name = pData;
      data.value = pData;
      sortedData.push(data);
      return idx;
    });
    setPortList(sortedData);
  };

  const getPorts = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    let portData = {};
    client.get('/system/network/ports/all')
      .then(res => {
        portData = res.data.data;
        if(portData){
          client.get('/system/network/ports/status')
            .then(res =>{
              let data = res.data.data;
              if (data && 'port_list' in data){
                let portStatusList = data.port_list;
                Object.keys(portStatusList).map((portName) => {
                  if(portName in portData.services){
                    portData.services[portName].status = portStatusList[portName];
                  } else {
                    portData.services[portName] = {};
                    portData.services[portName].status = portStatusList[portName];
                  }
                  return portName;
                });
              }

              if(props.hostVPU === 'MMAL'){
                client.get('/system/network/brcm/all')
                  .then(res =>{
                    let brcmData = res.data.data;
                    setap_wifi_type(brcmData.ap_wifi_type);
                    setBrcmEnable(brcmData.brcm_enabled);
                    if('ap0' in portData.services){
                      delete portData.services.ap0;
                    }
                    if(brcmData && brcmData.brcm_wifi === false && brcmData.brcm_enable_at_reboot === false && brcmData.ap_enabled === true){
                      // Remove wlan0 if use as access point is enabled
                      if('wlan0' in portData.services){
                        delete portData.services.wlan0;
                      }
                      setApEnabled(true);
                    }
                    setPortsData(portData);
                    getConfiguredInterfacelList(portData);
                    setPopupLoading(false);
                    setLoading(false);
                  })
                  .catch(err => {
                    console.log(err);
                    setPopupLoading(false);
                    setLoading(false);
                  });
              } else {
                setPortsData(portData);
                getConfiguredInterfacelList(portData);
                setPopupLoading(false);
                setLoading(false);
              }
            })
            .catch(err => {
              console.log(err);
              setPopupLoading(false);
              setLoading(false);
            });
        } else {
          setPopupLoading(false);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        setPopupLoading(false);
        setLoading(false);
      });
  }, [props.hostVPU]);

  const refreshCallback = () => {
    setRefreshPopup(true);
    setPopupLoading(true);
    document.getElementById('btnRefreshSSID').textContent = 'Refreshing...';
    getPorts();
  };

  const refreshPorts = useCallback(async(event) => {
    setLoading(true);
    document.getElementById('btnRefreshPort').textContent = 'Refreshing...';
    let jsonData = { 'action': 'RefreshStatus', 'source': 'EdgeUI' };
    await axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/ports/all', jsonData)
      .then(res => {
        getPorts();
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }, [getPorts]);

  useMemo(()=>{
    document.addEventListener('keydown', (e) => {
      if(e.keyCode == 116){
         e.preventDefault()
          refreshPorts();
      }
  });
  },[getPorts])
    

  useEffect(() =>{
    if(document.getElementById('btnRefreshPort')){
      document.getElementById('btnRefreshPort').textContent = 'Refresh';
    }
    if(document.getElementById('btnRefreshSSID')){
      document.getElementById('btnRefreshSSID').textContent = 'Refresh';
    }
    if(refreshPopup){
      radioButtonOnChange(selectedPort.port_name);
      setRefreshPopup(false);
    }
  }, [portsData, radioButtonOnChange, refreshPopup, selectedPort]);

  useEffect(() =>{
    getPorts();
  }, [getPorts]);

  return (
    <ContentDiv>
      {(portsData === undefined) &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress/>
        </div>
      }
      { (portsData) &&
        <Wrapper style={{'marginTop':'5px'}}>
          <SectionHeader>
            <SectionLabel>Ports (Layer 2)</SectionLabel>
          </SectionHeader>
          <Row>
            <div style={{'display': 'flex', 'gap': '10px', 'justify-content': 'flex-end', 'flex-wrap': 'wrap', 'margin': '1em 0'}}>
              { !readOnly &&
              <ResponsiveButton primary id="btnEditPort" disabled={readOnly} name="btnEditPort" onClick={() => editPort()}>Edit</ResponsiveButton>
              }
              {!readOnly &&
                <ResponsiveButton primary id="btnLinkDetails" name="btnLinkDetails" onClick={()=> showLinkDetails()}>Link Details</ResponsiveButton>
              }
              <ResponsiveButton primary id="btnRefreshPort" name="btnRefreshPort" disabled={loading} onClick={() => refreshPorts()}>Refresh</ResponsiveButton>
              <ResponsiveButton primary id="btnAddEthernetPort" name="btnAddEthernetPort" onClick={() => addPort('ether')}>Add Ethernet Port</ResponsiveButton>
              <ResponsiveButton primary id="btnAddWlanPort" name="btnAddWlanPort" onClick={() => addPort('wlan')}>Add Wi-Fi Port</ResponsiveButton>
              <ResponsiveButton primary id="btnAddBondPort" name="btnAddBondPort" onClick={() => addPort('bond')}>Add Bond Port</ResponsiveButton>
              {
                modalOpen && 
                <ResponsivePopupContainer onClick={closePopup}>
                  <Popup modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle}}>
                    <PortPopup readOnly={readOnly} portNameList={portNameList} getPortName={getPortName} closePopup={closePopup} ap_wifi_type={ap_wifi_type} brcmEnable={brcmEnable} linkDetailsData={linkDetailsData} isLinkDetails={isLinkDetails} portList={portList} selectedPort={selectedPort} apEnabled={apEnabled} availablePorts={props.availablePorts} interfacesData={props.interfacesData} selectedPortType={selectedPortType} refreshCallback={refreshCallback} refreshLoading={popUpLoading} />
                  </Popup>
                </ResponsivePopupContainer>
              }
            </div>
          </Row>
          <TableContainer style={{'overflow-x': 'auto'}}>
            <table style={{'width': '100%', 'padding':'11px', 'borderCollapse':'collapse'}}>
              <tr style={{'background-color': '#1f303a','color': 'white'}}>
                <Th style={{'width':'5%'}}></Th>
                <Th style={{'width':'22%', 'text-align': 'left'}}>Name</Th>
                <Th style={{'width':'12%'}}>Status</Th>
                <Th>MAC Address</Th>
                <Th>Type</Th>
                <Th>Link</Th>
              </tr>
              { Object.keys(portsData.services).map((pName) => {
                let pData = portsData.services[pName];
                let pType = '';
                let pMac = '';
                let pStatus = '';
                let pLink = '';
                let signal = 0;
                let pQualityTitle = '';
                let nickname = pData.nickname ? ' (' + pData.nickname + ')' : '';
                let nickPortName = decodeURIComponent(pName) + nickname;
                if(pData.status){
                  //ToDo: Type for Bond Member ??
                  if (pData.status.type in Constants.PORT_TYPES) {
                    pType = Constants.PORT_TYPES[pData.status.type];
                  }/* else if (pData.status.type === 'bond-member'){
                    let bondStatus = pData.bond_status ? '(' + pData.bond_status + ')' : '';
                    pType = pData.bond + bondStatus;
                    //ToDo: pType should be Bond-Member or something else ????
                  }*/
                  pMac = pData.status.mac;
                  // Check if this port is a bond member. If yes then show bond name and bond status
                  pStatus = ('bond' in pData.status && 'bond_status' in pData.status) ? pData.status.bond_status + '(' + pData.status.bond + ')' : '';
                  if(pStatus === ''){
                    if(pData.status.type === 'ether'){
                      pStatus = ('type' in pData) ? 'Ready' : 'Not Configured';
                    } else if(pData.status.type === 'wlan'){
                      if('ssids' in pData && Object.keys(pData.ssids).length > 0){
                        pStatus = 'Ready';
                      } else {
                        pStatus = 'Not Configured';
                        pLink = 'No';
                      }
                      if(!('link' in pData.status)){
                        pLink = 'No';
                      }
                    }
                  }

                  if(pData.status.link === 'no') {
                    pLink = 'No';
                  } else if(pData.status.link === 'yes') {
                    if(pData.status.type === 'ether') {
                      let duplex = pData.status.duplex ? '(' + pData.status.duplex + ')' : '';
                      let speed = pData.status.speed ?  pData.status.speed : '';
                      pLink = speed + duplex;
                      if(pLink === ''){
                        pLink = 'Yes';
                      } 
                    } else if(pData.status.type === 'wlan' && pStatus !== 'Not Configured'){
                      let frequency = pData.status.frequency ? '(' + pData.status.frequency + ')' : '';
                      pLink = pData.status.connected_ssid + frequency;
                      pQualityTitle = pData.status.quality;
                      // Frequency is x/70, so multyply x with 1.4286 convert it into percentage
                      let pQuality = parseInt(pData.status.quality.split('/')[0] * 1.4286);
                      if(pQuality <= 0){
                        signal = 0;
                      } else if(pQuality <= 25){
                        signal = 1;
                      } else if(pQuality <= 50){
                        signal = 2;
                      } else if(pQuality <= 75){
                        signal = 3;
                      } else if(pQuality <= 100){
                        signal = 4;
                      }
                    } else if(pData.status.type === 'wlan' && pStatus === 'Not Configured'){
                      pLink = 'No';
                    } else if(pData.status.type === 'bond'){
                      let duplex = pData.status.duplex ? '(' + pData.status.duplex + ')' : '';
                      pLink = pData.status.speed + duplex;
                    } else {
                      pLink = 'Yes';
                    }
                  }
                } else {
                  // 1. ethernet should be Unavailable If available in config db but not available in status db
                  // 2. wlan should be Unavailable when SSIDs in config db but not in status-db
                  if(pData.type === 'wlan'){
                    pStatus = 'Unavailable';
                  } else if(pData.type === 'ether'){
                    pStatus = 'Unavailable';
                  }
                  if(pData.type in Constants.PORT_TYPES) {
                    pType = Constants.PORT_TYPES[pData.type];
                  }
                }
                // bond should Ready if members available else Not Configured
                if(pData.type === 'bond'){
                  // For Ready and Not Configured
                  if('members' in pData && Object.keys(pData.members).length > 0){
                    pStatus = 'Ready';
                  } else {
                    pStatus = 'Not Configured';
                  }
                }

                return(
                  <tr>
                    <Td><input type="radio" id="select_port" style={{'cursor':'pointer'}} name="select_port" value={decodeURIComponent(pName)} onClick={()=> radioButtonOnChange(pName)} ></input></Td>
                    <Td style={{'text-align':'left','padding':'10px','white-space': 'nowrap','overflow': 'hidden','text-overflow': 'ellipsis','cursor':'pointer', 'max-width': '200px'}} title={nickPortName}>{nickPortName}</Td>
                    <Td>{pStatus === '' ? '-': pStatus}</Td>
                    <Td>{pMac === '' ? '-': pMac}</Td>
                    <Td>{pType === '' ? '-': pType}</Td>
                    <Td>{pLink === '' ? '-': pLink} {(pType === 'Wi-Fi' && signal!== 0) ? <img src={require('../images/signal_' + signal + '.png')} alt='' style={{'width': '24px', 'marginRight': '10px'}} title={pQualityTitle}/> : ''}</Td>
                  </tr>
                );
              })}
            </table>
          </TableContainer>
          <MessageContaier>
            <MessageLabel style={{'text-size-adjust': '100%'}}>
              <b>Ready:</b> Port is configured and ready to use.<br/>
              <b>Not Configured:</b> Port is found, but it must be configured to become Ready.<br/>
              { props.hostVPU === 'MMAL' &&
                <span><b>Unavailable:</b> Port is configured, but it is not found in the system or onboard Wi-Fi (BRCM) is disabled.<br/></span>
              }
              { props.hostVPU !== 'MMAL' &&
                <span><b>Unavailable:</b> Port is configured, but it is not found in the system.<br/></span>
              }
              <b>Active:</b> Port is a member of a bonding port and it is in use.<br/>
              <b>StandBy:</b> Port is a member of a bonding port and it is not in use.
            </MessageLabel>
          </MessageContaier>
        </Wrapper>
      }
    </ContentDiv>
  );
};


const PortPopup =(props) => {
  const [index, setIndex] = useState(0);
  const { readOnly, closePopup, selectedPort, getPortName, ap_wifi_type, apEnabled, brcmEnable, availablePorts, interfacesData, selectedPortType, refreshLoading, isLinkDetails, linkDetailsData, portList, portNameList } = props;
  const [wifiSettings, setWifiSettings] = useState(selectedPort === undefined ? getDefaultSsidData() : selectedPort);
  const [bondSettings, setBondSettings] = useState(selectedPort === undefined ? getBondData() : selectedPort);
  const [selectedRow, setSelectedRow] = useState();
  const [refreshTable, setRefreshTable] = useState(false);
  const [availableBondMembers, setAvailableBondMembers] = useState([]);
  const [portType, setPortType] = useState(selectedPortType);
  const [selectLinkPort, setSelectLinkPort] = useState();
  const [linlLoading, setLinkLoading] = useState(false);
  const [linkData, setLinkData] = useState();
  const [includeWlan, setIncludeWlan] = useState(true);

  const data =  [ {'Supported link modes':['10baseT/Half 10baseT/Full', '100baseT/Half 100baseT/Full', '1000baseT/Full'], 
    'Supported pause frame use':'No', 'Supports auto-negotiation':'Yes' } ];

  const onCancel = useCallback(() => {
    getPortName(wifiSettings.port_name);
    closePopup();
  },[]);

  useEffect(() => {
    if(selectedPortType === 'wlan'){
      axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/ports')
        .then(res => {
          const portData = res.data.data;
          if(portData.includes('wlan0')) {
            setIncludeWlan(true);
          } else {
            setIncludeWlan(false);
          }
        });
    }
  },[selectedPortType]);

  useEffect(() =>{
    if(selectedPortType === 'wlan'){
      setWifiSettings(selectedPort === undefined ? getDefaultSsidData() : selectedPort);
    }
  }, [selectedPort, selectedPortType]);
 
  useEffect(() =>{
    if(selectedPortType === 'wlan'){
      let pData = selectedPort === undefined ? getDefaultSsidData() : selectedPort;
      setWifiSettings(pData);
      setIndex(Object.keys(pData.sorted_list).length);
    }
    let availableMembers = [];
    Object.keys(availablePorts.services).map((portName) => {
      if(availablePorts.services[portName].type !== 'bond'){
        availableMembers.push(decodeURIComponent(portName));
      }
      return portName;
    });
    setAvailableBondMembers(availableMembers);
  },[availablePorts.services, selectedPort, selectedPortType]);

  const handleAddRow = () => {
    if(Object.keys(wifiSettings.sorted_list).length >= 10){
      swal('You can add maximum 10 ssid.', {icon: 'error'});
      return;
    }
    setRefreshTable(false);
    let newIndex = index + 1;
    setIndex(newIndex);
    wifiSettings.sorted_list.push(getSsidData());
    setWifiSettings(wifiSettings);
  };

  const handleDeleteSSID  = () =>  {
    wifiSettings.sorted_list.splice(selectedRow, 1);
    setWifiSettings(wifiSettings);
    setRefreshTable(true);
  };

  const radioOnChangeInPopup = (idx) => {
    setRefreshTable(false);
    setSelectedRow(idx);
  };

  const radioOnChangeLinkMonitor = (monitorType) => {
    setBondSettings({...bondSettings, 'link_monitor_type': monitorType});
  };

  const updateWifiSetting = (id, name, value) => {
    let idx = id.substring(id.indexOf('-') + 1);
    if(name === 'port_name' || name === 'nickname'){
      setWifiSettings({...wifiSettings, [name]: value});
    } else {
      let data = { ...wifiSettings };
      if(data.sorted_list[idx]){
        data.sorted_list[idx][name] = value;
        setWifiSettings(data);
      }
    }
  };

  const handleToggleChange = (value, event) => {
    updateWifiSetting(event.target.id, event.target.name, value);
  };

  const handleOnChange = (event) => {
    if (event.target.name === 'port_name') {
      if (event.target.value.charAt(0) === '_' || !isNaN(event.target.value.charAt(0))) {
        event.target.value = event.target.value.slice(1);
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
      updateWifiSetting(event.target.id, event.target.name, event.target.value.toLowerCase());
    }
    let pElement = document.getElementById(event.target.id);
    if(pElement){
      pElement.style.borderColor = 'lightgray';
    }
    if(event.target.name === 'security_key' && event.target.value.includes('$')) {
      event.target.value = event.target.value.replace('$', '');
    }
    if(event.target.name === 'port_name'){
      updateWifiSetting(event.target.id, event.target.name, event.target.value.toLowerCase());
    } else if(event.target.name === 'security_type'){
      let idx = event.target.id.substring(event.target.id.indexOf('-') + 1);
      
      if(event.target.value === 'NONE'){
        document.getElementById('security_key-' + idx).style.borderColor = 'lightgray';
        document.getElementById('security_key-' + idx).disabled = true;
      }
      else if(event.target.value === "NONE" && event.target.security_type){
        updateWifiSetting('security_key-' + idx, 'security_key-' + idx, '');
      } else {
        document.getElementById('security_key-' + idx).disabled = false;
      }
      updateWifiSetting(event.target.id, event.target.name, event.target.value);
    } else {
      updateWifiSetting(event.target.id, event.target.name, event.target.value);
    }
  };

  const handleIntegerOnChange = (event) => {
    if(event.target.name === 'scan_ssid') {
      let regEx = /^[0-1]*$/;
      if(regEx.exec(event.target.value)){
        updateWifiSetting(event.target.id, event.target.name, event.target.value);
      }
    } else {
      let regEx = /^[1-9][0-9]*$/;
      if(regEx.exec(event.target.value)){
        updateWifiSetting(event.target.id, event.target.name, event.target.value);
      } else {
        if(event.target.value === '' || event.target.value === '0'){
          updateWifiSetting(event.target.id, event.target.name, event.target.value);
        }
      }
    }
  };

  const handleBondOnChange = (event) => {
    if(event.target.name === 'mode' && event.target.value === 'active-backup'){
      let data = {...bondSettings, [event.target.name]: event.target.value};
      if(data.failover_mac_policy === ''){
        data.failover_mac_policy = 'none';
      }
      setBondSettings(data);
    } else if(event.target.name === 'port_name'){
      if (event.target.value.charAt(0) === '_' || !isNaN(event.target.value.charAt(0))) {
        event.target.value = event.target.value.slice(1);
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
      setBondSettings({...bondSettings, [event.target.name]: event.target.value.toLowerCase()});
    } else {
      setBondSettings({...bondSettings, [event.target.name]: event.target.value});
    }
  };

  const onMultiSelectSelect = (selectedList, selectedItem) => {
    setBondSettings({...bondSettings, 'members': selectedList});
  };
  
  const onMultiSelectRemove = (selectedList, removedItem) => {
    setBondSettings({...bondSettings, 'members': selectedList});
  };

  const connectDisconnectSSID = (event) => {
    let selectedSSID = wifiSettings.sorted_list[selectedRow];
    if(selectedSSID === undefined){
      swal('Please select access point.', {icon: 'error'});
      return;
    }
    if(!(selectedSSID.ssid in wifiSettings.ssids)){
      // ssid not exist in config db. User might adding new ssid and trying to connect/disconnect
      swal('Please save changes before this operation.', {icon: 'error'});
      return;
    }
    let portName = wifiSettings.port_name;
    var jsonData = {
      'connected_ssid': wifiSettings.connected_ssid === selectedSSID.ssid ? '' : selectedSSID.ssid
    };

    axios.patch(Constants.EDGE_API_ENDPOINT + '/system/network/ports/' + portName + '/all', jsonData)
      .then(res => {
        swal('Access point update request submitted successfully.', {icon: 'success'});
      })
      .catch(error => {
        if(error.response){
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
        }else{
          swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
        }
      });
  };

  const deletePort = (event) => {
    if(selectedPortType === 'wlan' && !includeWlan) {
      swal('Port "wlan0" cannot be deleted as it is not configured yet.', {icon: 'error'});
      return;
    } else {
      let portName = wifiSettings.port_name;
      if(portName in interfacesData.services){
        swal('Port ' + portName + ' cannot be deleted. To delete this port, first delete the interface configured for this port.', {icon: 'error'});
        return;
      }
      if('status' in selectedPort && 'bond' in selectedPort.status){
        if(selectedPort.status.bond !== ''){
          swal('Port ' + portName + ' cannot be deleted because it is a member of a bond. To delete this port, first remove this port from bond ' + selectedPort.status.bond, {icon: 'error'});
          return;
        }
      }
  
      swal({text: 'Are you sure you want to delete this port?',
        buttons: ['No', 'Yes'],
        dangerMode: true,
        icon: 'warning'
      })
        .then((isConfirm) => {
          if (isConfirm) {
            axios.delete(Constants.EDGE_API_ENDPOINT + '/system/network/ports/' + portName + '/all')
              .then(res => {
                swal('Port deleted successfully.', { icon: 'success'});
                setTimeout(function(){ window.location.reload(); }, 2000);
              })
              .catch(error => {
                if(error.response){
                  var errorObj = error.response.data;
                  swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
                } else {
                  swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
                }
              });
          }
        });
    }
  };


  const savePort = (event) => {
    if(portType === 'ether'){
      let portName = document.getElementById('port_name').value;
      let jsonData = {};
      jsonData.nickname = document.getElementById('nickname').value;
      jsonData.type = 'ether';
      if(portName === ''){
        swal('Please enter port name.', { icon: 'error'});
        return;
      }
      let action = readOnly ? 'added' : 'updated';
      if(portNameList.includes(portName) && action === 'added') {
        swal('Port name already exist. Please try with another name.', { icon: 'error' });
        return;
      }
      axios.put(Constants.EDGE_API_ENDPOINT + '/system/network/ports/' + portName + '/all', jsonData)
        .then(res => {
          swal('Port ' + action + ' successfully.', { icon: 'success'});
          setTimeout(function(){ window.location.reload(); }, 2000);
        })
        .catch(error => {
          if(error.response){
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
          }else{
            swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
          }
        });
    } else if(portType === 'wlan'){
      let action = readOnly ? 'added' : 'updated';
      let portName = document.getElementById('port_name').value;
      if(portName === ''){
        swal('Please enter port name', { icon: 'error'});
        return;
      }
      if(portNameList.includes(portName) && action === 'added') {
        swal('Port name already exist. Please try with another name.', { icon: 'error' });
        return;
      }
      if(ap_wifi_type !== undefined) {
        if(ap_wifi_type.includes('wifi_client') && portName === 'wlan0' && brcmEnable && action === 'added'){
          swal('Port name \'wlan0\' is not allowed when \'Onboard Wi-Fi\' is enabled.', {icon: 'error'});
          return;
        }
        if(ap_wifi_type.includes('access_point') && portName === 'ap0' && brcmEnable && action === 'added'){
          swal('Port name \'ap0\' is not allowed when \'Onboard Wi-Fi\' is enabled.', {icon: 'error'});
          return;
        }
      }
      // if(brcmEnable && portType === 'wlan' && action === 'added'){
      //   swal('Port name \'' + portName +'\' is not allowed when \'Onboard Wi-Fi\' is enabled.', {icon: 'error'});
      //   return;
      // }
      setRefreshTable(false);
      let jsonData = {};
      let ssids = {};
      let ssidDataKeys = Object.keys(wifiSettings.sorted_list);
      

      for(var k=0; k < wifiSettings.sorted_list.length; k++){
        if(document.getElementById('ssid_row-' + k)){
          let dataSSid = {...wifiSettings.sorted_list[k]};
          let priority = dataSSid.priority === null || dataSSid.priority === '' ||  dataSSid.priority === NaN ? '' : parseInt(dataSSid.priority);
          dataSSid.priority = priority;
          let ssidData = Object.assign({}, dataSSid);
          let ssid = ssidData.ssid;
          if(ssid === ''){
            swal('Please enter ssid', {icon: 'error'});
            document.getElementById('ssid-' + k).style.borderColor = 'red';
            return;
          }
          
          if (ssidData.security_type == 'NONE') {
            if (ssidData.security_key || ssidData.security_key === '')  {
              delete ssidData.security_key;
            }
          }
          if(ssidData.security_type !== 'NONE'){
            if(ssidData.security_key === ''){
              swal('Please enter key', {icon: 'error'});
              document.getElementById('security_key-' + k).style.borderColor = 'red';
              return;
            }
            if(ssidData?.security_key ===undefined){
              swal('Please enter key', {icon: 'error'});
              return;
            }
            if( ssidData?.security_key?.length < 8 || ssidData?.security_key?.length > 63 ){
              swal('Key validation failed: length should be between 8 to 63.', {icon: 'error'});
              document.getElementById('security_key-' + k).style.borderColor = 'red';
              return;
            }
          }
          delete ssidData.ssid;
          ssidData.scan_ssid = ssidData.scan_ssid;
          ssids[ssid] = ssidData;
        }
      }

      for(var i=0; i < wifiSettings.sorted_list.length; i++){
        let ssid = wifiSettings.sorted_list[i].ssid;
        for(var j=i+1; j < wifiSettings.sorted_list.length; j++){
            if(wifiSettings.sorted_list[i].ssid === wifiSettings.sorted_list[j].ssid){
              swal('Duplicate ssid.', {icon: 'error'});
              document.getElementById('ssid-' + j).style.borderColor = 'red';
              return;
            }
        }
      }

      jsonData.type = 'wlan';
      jsonData.nickname = wifiSettings.nickname;
      jsonData.ssids = ssids;
      jsonData.connected_ssid = wifiSettings.connected_ssid ? wifiSettings.connected_ssid : '';

      axios.put(Constants.EDGE_API_ENDPOINT + '/system/network/ports/' + portName + '/all', jsonData)
        .then(res => {
          swal('Port ' + action +' successfully.', { icon: 'success'});
          setTimeout(function(){ window.location.reload(); }, 2000);
        })
        .catch(error => {
          if(error.response){
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
          }else{
            swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
          }
        });
    } else if(portType === 'bond'){
      let jsonData = Object.assign({}, bondSettings);
      let portName = jsonData.port_name;
      let action = readOnly ? 'added' : 'updated';
      if(portName === ''){
        swal('Please enter port name', { icon: 'error'});
        return;
      }
      if(portNameList.includes(portName) && action === 'added') {
        swal('Port name already exist. Please try with another name.', { icon: 'error' });
        return;
      }
      jsonData.type = 'bond';
      delete jsonData.port_name;
      if(jsonData.members.length === 0){
        swal('Please select bond members.', {icon: 'error'});
        return;
      }
      if(jsonData.members.length < 2){
        swal('Minimum 2 bond members are required.', {icon: 'error'});
        return;
      }
      if(jsonData.mode !== 'active-backup'){
        jsonData.failover_mac_policy = '';
      }
      if(jsonData.interval === ''){
        swal('Please enter monitoring interval.', {icon: 'error'});
        return;
      }
      if(jsonData.link_monitor_type === 'arp'){
        if(jsonData.arp_targets === ''){
          swal('Please enter ARP target IPs.', {icon: 'error'});
          return;
        } else {
          let arpIPs = jsonData.arp_targets.split(',');
          console.log(arpIPs);
          for(let i=0; i< arpIPs.length; i++){
            console.log(arpIPs[i]);
            if(!validateIpAddress('arp_targets', arpIPs[i])){
              swal('ARP target IPs should be valid IP address(es). Multiple IP must be separated by commas.', {icon: 'error'});
              return;
            }
          }
        }
        if(jsonData.mode === 'balance-alb'){
          swal('ARP monitoring is not supported for balance-alb mode.', {icon: 'error'});
          return;
        }
      } else {
        jsonData.arp_targets = '';
      }
      
      if(jsonData.updelay === ''){
        jsonData.updelay = '1000';
      }

      if(jsonData.mii_interval === ''){
        jsonData.mii_interval = '100';
      }

      if(jsonData.arp_interval === ''){
        jsonData.arp_interval = '1000';
      }

      axios.put(Constants.EDGE_API_ENDPOINT + '/system/network/ports/' + portName + '/all', jsonData)
        .then(res => {
          swal('Port ' + action +' successfully.', { icon: 'success'});
          setTimeout(function(){ window.location.reload(); }, 2000);
        })
        .catch(error => {
          if(error.response){
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
          }else{
            swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
          }
        });
    }
  };

  useEffect(() =>{
    setSelectLinkPort(linkDetailsData);
  }, [linkDetailsData]);

  const handleOnChangePort = (event) => {
    setSelectLinkPort(event.target.value);
  };

  useEffect(()=> {
    setLinkData('');
    setLinkLoading(true);
    let portName = linkDetailsData;
    let jsonData = {};
    jsonData.action = 'link_details';
    jsonData.source = 'edge_ui';
    axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/ports/' + portName, jsonData)
      .then(res => {
        setLinkData(res.data.data);
        setLinkLoading(false);
      })
      .catch(error => {
        if(error.response){
          var errorObj = error.response.data;
          setLinkLoading(false);
          swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
        }else{
          setLinkLoading(false);
          swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
        }
      });
  },[linkDetailsData]);

  const onSubmit = () => {
    setLinkData('');
    setLinkLoading(true);
    let portName = selectLinkPort;
    let jsonData = {};
    jsonData.action = 'link_details';
    jsonData.source = 'edge_ui';
    axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/ports/' + portName, jsonData)
      .then(res => {
        setLinkData(res.data.data);
        setLinkLoading(false);
      })
      .catch(error => {
        if(error.response){
          var errorObj = error.response.data;
          setLinkLoading(false);
          swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
        }else{
          setLinkLoading(false);
          swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
        }
      });
  };

  const onReset = () => {
    setLinkData('');
    setSelectLinkPort(linkDetailsData);
  };

  return (
    <>
      {!isLinkDetails ? 
        <div>
          <PopupContainer>
            <SectionHeader style={{'display':'flex'}}>
              <SectionLabel>{readOnly ? 'Add' : 'Edit' } {Constants.PORT_TYPES[portType]} Port</SectionLabel>
              { readOnly ||
              <div style={{'marginLeft': 'auto'}}>
                <Button danger id="btnDeletePort" name="btnDeletePort" style={{'margin': '0px 10px 0px 0px'}} onClick={deletePort}>Delete</Button>
              </div>
              }
            </SectionHeader>
            <div>
              { (wifiSettings && portType === 'wlan') &&
          <WifiPopupContainer>
            <WifiRow>
              <ResponsiveInputFields1>
              <Column style={{'margin-bottom': '30px', display: 'flex', alignItems: 'baseline'}}>
                <ResponsiveTextFieldLabel1Name style={{flexShrink: '0'}}>Name</ResponsiveTextFieldLabel1Name>
                <ResponsiveTextFieldSmall style={{maxWidth: '205px'}} autoComplete="off" id="port_name" name="port_name" value={decodeURIComponent(wifiSettings.port_name)} onChange={handleOnChange} disabled={!readOnly} maxLength="15"/>
              </Column>
              </ResponsiveInputFields1>
              <ResponsiveInputFields2>
              <Column style={{'margin-bottom': '30px', display: 'flex', alignItems: 'baseline'}}>
                <ResponsiveTextFieldLabel1 style={{flexShrink: '0'}}>Nickname</ResponsiveTextFieldLabel1>
                <ResponsiveTextFieldSmall style={{maxWidth: '205px'}} autoComplete="off" id="nickname" name="nickname" value={wifiSettings.nickname} onChange={handleOnChange} maxLength='100' />
              </Column>
              </ResponsiveInputFields2>
            </WifiRow>
            <SectionHeader>
              <SectionLabel>Access Points</SectionLabel>
            </SectionHeader>
            <Row>
              <RightDiv style={{'display': 'inline-block', 'marginRight': '20px'}}>
                {(selectedRow && readOnly === false && (wifiSettings.sorted_list[selectedRow] && wifiSettings.ssids && wifiSettings.sorted_list[selectedRow].ssid in wifiSettings.ssids)) &&
                    <Button primary onClick={connectDisconnectSSID} >
                      {(wifiSettings.status && wifiSettings.sorted_list[selectedRow] && wifiSettings.sorted_list[selectedRow].ssid === wifiSettings.status.connected_ssid) ? 'Disconnect' : 'Connect'}
                    </Button>
                }
                { selectedRow &&
                <Button danger id="btnDeleteAccessPoint" name="btnDeleteAccessPoint" onClick={() => handleDeleteSSID()}>Delete Access Point</Button>}
                <Button primary id="btnAccessPoint" name="btnAccessPoint" onClick={() => handleAddRow()}>Add Access Point</Button>
                {readOnly === false &&
                    <Button primary id="btnRefreshSSID" name="btnRefreshSSID" disabled={refreshLoading} onClick={props.refreshCallback}>Refresh</Button>
                }
              </RightDiv>

            </Row>
            <div style={{'padding': '0px 20px'}}>
            <TableContainer style={{'height': '210px', 'overflow-x': 'auto'}}>
              { (refreshTable || wifiSettings) &&
              <table style={{'min-width': '100%', 'borderCollapse':'collapse'}}>
                <tr style={{'background-color': '#1f303a','color': 'white'}}>
                  <Th></Th>
                  <Th>SSID</Th>
                  <Th>Security</Th>
                  <Th>Key</Th>
                  <Th>Priority</Th>
                  <Th style={{'width': '120px'}}>Hidden AP</Th>
                  <Th style={{'width': '120px'}}>Auto Connect</Th>
                  { readOnly === false && <Th>Status</Th> }
                </tr>
                { Object.keys(wifiSettings.sorted_list).map((idx) => (
                  <tr id={'ssid_row-' + idx}>
                    <Td>
                      <input type="radio" id={'ssid_radio-' + idx} name={'ssid_radio'} style={{'cursor':'pointer'}} value={idx} onChange={handleOnChange} onClick={()=> radioOnChangeInPopup(idx)}></input>
                    </Td>
                    <Td>
                      <TextFieldSmall id={'ssid-' + idx} name={'ssid'} autoComplete="off" maxLength="32" style={{'width':'200px'}} onChange={handleOnChange} value={wifiSettings.sorted_list[idx].ssid} /></Td>
                    <Td>
                      <SelectFieldSmall id={'security_type-' + idx} name={'security_type'} onChange={handleOnChange} style={{'width':'120px'}} value={wifiSettings.sorted_list[idx].security_type}>
                        { Object.keys(Constants.WPA_SECURITY_TYPES).map((idx) => {
                          return(<option value={Constants.WPA_SECURITY_TYPES[idx].value}>{Constants.WPA_SECURITY_TYPES[idx].name}</option>);
                        })}
                      </SelectFieldSmall>
                    </Td>
                    <Td>
                      <input type="password" style={{'display': 'none'}} />
                      <PasswordField id={'security_key-' + idx} name={'security_key'} type="text" value={wifiSettings.sorted_list[idx].security_key} maxLength="63" setInput={handleOnChange} autoComplete="off" width="170px"/>
                    </Td>
                    <Td>
                      <TextFieldSmall id={'priority-' + idx} name={'priority'} value={wifiSettings.sorted_list[idx].priority} style={{'width':'110px'}} onChange={handleIntegerOnChange} maxLength="5" autoComplete="off"/>
                    </Td>
                    <Td>
                      <SelectionControl id={'scan_ssid-' + idx} name={'scan_ssid'} checked={wifiSettings.sorted_list[idx].scan_ssid} label= {wifiSettings.sorted_list[idx].scan_ssid === true ? 'Yes' : 'No'} type="switch" style={{'padding':'10px'}} onChange={handleToggleChange} labelBefore="true" />
                    </Td>
                    <Td>
                      <SelectionControl id={'auto_connect-' + idx} name={'auto_connect'} checked={wifiSettings.sorted_list[idx].auto_connect} label= {wifiSettings.sorted_list[idx].auto_connect === true ? 'Yes' : 'No'} type="switch" style={{'padding':'10px'}} onChange={handleToggleChange} labelBefore="true" />
                    </Td>
                    { readOnly === false &&
                    <Td style={{'width':'120px'}}>{(wifiSettings.status && wifiSettings.sorted_list[idx].ssid !=='' && wifiSettings.sorted_list[idx].ssid === wifiSettings.status.connected_ssid) ? 'Connected' : 'Not Connected'} </Td>
                    }
                  </tr>
                ))}
              </table>
              }
              <MessageContaier>
                <MessageLabel style={{'white-space': 'break-spaces'}}>
                  <b>Priority: </b>The available access point of the highest priority will be connected.  It is an integer and bigger number means higher priority.<br/>
                  <b>Hidden AP: </b>This device connects to the access point even if it's hidden (SSID cloaking).  Directed probe requests will be sent to this access point, instead of null probe requests.
                </MessageLabel>
              </MessageContaier>
            </TableContainer>
            </div>
          </WifiPopupContainer>
              }
              { portType === 'ether' &&
            <div style={{'padding': '0px 20px 20px 20px'}}>
              <Row style={{display: 'flex', alignItems: 'baseline'}}>
                <ResponsiveTextFieldLabel1Name2 style={{flexShrink: '0'}}>Name</ResponsiveTextFieldLabel1Name2>
                <ResponsiveTextFieldSmall style={{width: '100%', flexGrow: '1'}} autoComplete="off" id="port_name" name="port_name" value={wifiSettings.port_name} onChange={handleOnChange} disabled={!readOnly} maxLength="15"/>
              </Row>
              <Row style={{display: 'flex', alignItems: 'baseline'}}>
                <ResponsiveTextFieldLabel2 style={{flexShrink: '0'}}>Nickname</ResponsiveTextFieldLabel2>
                <ResponsiveTextFieldSmall style={{width: '100%', flexGrow: '1'}} autoComplete="off" id="nickname" name="nickname" value={wifiSettings.nickname} onChange={handleOnChange} maxLength='100' />
              </Row>
            </div>
              }
              { portType === 'bond' &&

          <Tabs defaultTab="general" style={{'padding': '6px'}}>
            <TabList>
              <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
              <Tab tabFor="link_monitor" className={'rwt__tab'}>Monitoring</Tab>
            </TabList>
            <TabPanel tabId="general">
              <BondPopupContainer>
                <Row style={{display: 'flex', alignItems: 'baseline'}}>
                  <ResponsiveTextFieldLabel style={{flexShrink: '0'}}>Name</ResponsiveTextFieldLabel>
                  <TextFieldSmall style={{width: '100%', flexGrow: '1'}} id="port_name" name="port_name" value={bondSettings.port_name} onChange={handleBondOnChange} disabled={!readOnly} maxLength="15"/>
                </Row>
                <Row style={{display: 'flex', alignItems: 'baseline'}}>
                  <ResponsiveTextFieldLabel style={{flexShrink: '0'}}>Nickname</ResponsiveTextFieldLabel>
                  <TextFieldSmall style={{width: '100%', flexGrow: '1'}} autoComplete="off" id="nickname" name="nickname" value={bondSettings.nickname} onChange={handleBondOnChange} maxLength='100' />
                </Row>
                <Row style={{display: 'flex', alignItems: 'baseline'}}>
                  <ResponsiveSelectFieldLabel style={{flexShrink: '0'}}>Mode</ResponsiveSelectFieldLabel>
                  <SelectFieldSmall style={{width: '100%', flexGrow: '1'}} id="mode" name="mode" value={bondSettings.mode} onChange={handleBondOnChange} >
                    <option value="active-backup">active-backup</option>
                    <option value="balance-alb">balance-alb</option>
                    <option value="balance-rr">balance-rr</option>
                  </SelectFieldSmall>
                </Row>
                <Row id="failover_mac_policy-row" style={{'display': bondSettings.mode === 'active-backup' ? 'flex': 'none', alignItems: 'baseline'}}>
                  <ResponsiveSelectFieldLabel style={{flexShrink: '0'}}>Failover MAC Policy</ResponsiveSelectFieldLabel>
                  <SelectFieldSmall style={{width: '100%', flexGrow: '1'}} id="failover_mac_policy" name="failover_mac_policy" value={bondSettings.failover_mac_policy} onChange={handleBondOnChange} >
                    <option value="none">None</option>
                    <option value="active">Active</option>
                    <option value="follow">Follow</option>
                  </SelectFieldSmall>
                </Row>
                <Row>
                  <SelectFieldLabel>Members</SelectFieldLabel>
                </Row>
                <Row style={{'width': '100%'}}>
                  <Multiselect
                    options={availableBondMembers}
                    selectedValues={bondSettings.members}
                    onSelect={onMultiSelectSelect}
                    onRemove={onMultiSelectRemove}
                    showCheckbox={true}
                    isObject={false}
                    placeholder="Select Member(s)"
                    emptyRecordMsg="No Member(s) Available"
                  />
                </Row>
              </BondPopupContainer>
            </TabPanel>
            <TabPanel tabId="link_monitor">
              <BondPopupContainer>
                <Row style={{display: 'flex', alignItems: 'baseline'}}>
                  <ResponsiveTextFieldLabel style={{flexShrink: '0'}}>Mechanism</ResponsiveTextFieldLabel>
                  <div style={{'marginLeft': '-5px', 'marginRight': '30px'}}>
                    <input type="radio" id="link_monitor_type" name="link_monitor_type" value="mii" checked={bondSettings.link_monitor_type === 'mii' || false} onClick={()=> radioOnChangeLinkMonitor('mii')} />MII
                  </div>
                  <div style={{'color': 'gray'}}>
                    <input type="radio" id="link_monitor_type" name="link_monitor_type" value="arp" checked={bondSettings.link_monitor_type === 'arp' || false} onClick={()=> radioOnChangeLinkMonitor('arp')} disabled={true}/>ARP
                  </div>
                </Row>
                <Row style={{'display': bondSettings.link_monitor_type === 'mii' ? 'flex' : 'none', alignItems: 'baseline'}}>
                  <ResponsiveTextFieldLabel style={{flexShrink: '0'}}>Interval (ms)</ResponsiveTextFieldLabel>
                  <TextFieldSmall style={{width: '100%', flexGrow: '1'}} autoComplete="off" id="mii_interval" name="mii_interval" value={bondSettings.mii_interval} onChange={handleBondOnChange} placeholder="100"/>
                </Row>
                <Row style={{'display': bondSettings.link_monitor_type === 'arp' ? 'flex' : 'none', alignItems: 'baseline'}}>
                  <ResponsiveTextFieldLabel style={{flexShrink: '0'}}>Interval (ms)</ResponsiveTextFieldLabel>
                  <TextFieldSmall style={{width: '100%', flexGrow: '1'}} autoComplete="off" id="arp_interval" name="arp_interval"  value={bondSettings.arp_interval} onChange={handleBondOnChange} placeholder="1000"/>
                </Row>
                <div style={{'display': bondSettings.link_monitor_type === 'mii' ? 'block' : 'none'}}>
                  <Row style={{'display': 'flex', alignItems: 'baseline'}}>
                    <ResponsiveTextFieldLabel style={{flexShrink: '0'}}>Link up delay (ms)</ResponsiveTextFieldLabel>
                    <TextFieldSmall style={{width: '100%', flexGrow: '1'}} autoComplete="off" id="updelay" name="updelay" value={bondSettings.updelay} onChange={handleBondOnChange} placeholder="1000"/>
                  </Row>
                  <MessageLabel>
                    The link up delay value should be a multiple of the interval value; if not, it will be rounded down to the nearest multiple.
                  </MessageLabel>
                </div>
                <Row style={{'display': bondSettings.link_monitor_type === 'arp' ? 'flex' : 'none', alignItems: 'baseline'}}>
                  <ResponsiveTextFieldLabel style={{'marginTop': '25px', flexShrink: '0'}}>Target IPs</ResponsiveTextFieldLabel>
                  <TextFieldSmall style={{width: '100%', flexGrow: '1'}} autoComplete="off" id="arp_targets" name="arp_targets" title={bondSettings.arp_targets} value={bondSettings.arp_targets} onChange={handleBondOnChange} placeholder={'10.10.32.110,10.10.32.114'}/>
                </Row>
              </BondPopupContainer>
            </TabPanel>
          </Tabs>
              }
              <Row>
                { readOnly === true ?
                  <PopupButtons>
                    <Button id="btnSavePort" onClick={savePort} primary >Save</Button>
                    <Button id="btnCancelPort" danger onClick={()=> closePopup()}>Cancel</Button>
                  </PopupButtons>
                  :
                  <PopupButtons>
                    <Button id="btnSavePort" onClick={savePort} primary >Save</Button>
                    <Button id="btnCancelPort" danger onClick={onCancel}>Cancel</Button>
                  </PopupButtons>
                }
              </Row>
            </div>
          </PopupContainer>
        </div> : 
        <>
          <PopupContainer>
            <SectionHeader style={{'display':'flex'}}>
              <SectionLabel>Link Details</SectionLabel>
            </SectionHeader>
            <div style={{'padding': '0px 20px 20px 20px', 'height': '390px'}}>
              <Row>
                <TextFieldLabel1>Select Port</TextFieldLabel1>
                <SelectField1 id="port" name="port" style={{ 'margin-top': '10px'}} value={selectLinkPort} onChange={handleOnChangePort}>
                  <SelectOptions items={portList} />
                </SelectField1>
                <Button id="btnSubmit" primary onClick={onSubmit}>Submit</Button>
              </Row>
              <TextFieldLabel style={{'width':'100px', 'marginTop': '0'}}>Output</TextFieldLabel>
              <div style={{'height': '210px', 'border': '1px solid #ddd', 'padding':'15px', 'overflow':'auto', 'marginTop':'10px'}}>
                {linlLoading && 
            <div className="loading-msg">
              <p>Loading...</p>
              <CircularProgress/>
            </div>}
                {(linkData )&& linkData.map((idx) => {
                  return(
                    <p><pre style={{'fontSize':'11px','height':'10px'}}>{idx.replace(/ /g, '\u00a0')}</pre></p>
                  );})}
              </div>
              <Row>
                <PopupButtons>
                  <Button id="btnReset" primary onClick={onReset}>Reset</Button>
                  <Button id="btnCancelPort" danger onClick={()=> {closePopup();}}>Cancel</Button>
                </PopupButtons>
              </Row>
            </div>
          </PopupContainer>
        </>}
    </>
  );
};

export default NetworkPorts;
