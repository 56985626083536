import React, {useState, useRef, useMemo, useEffect, useCallback} from 'react'
import { MapContainer, TileLayer, Marker, useMap, Tooltip } from 'react-leaflet';
import '../index.css';
import 'leaflet/dist/leaflet.css';
import L, {Icon} from 'leaflet';
import markerIconMap from '../images/marker-icon.png';
import markerIconGreen from '../images/green-camera.svg';
import markerIconGrey from '../images/grey-camera.svg';
import markerIconBlue from '../images/blue-camera.svg';
import markerIconRed from '../images/red-camera.svg';
import markerIconYellow from '../images/yellow-camera.svg';
import markerIconBlack from '../images/black-camera.svg';
import { TabPanel } from 'react-web-tabs';
import { SelectionControl } from 'react-md';
import {Row, TextFieldLabel, TextFieldSmall, Button} from '../utils/Styles';
import styled, { css } from 'styled-components';
import axios from 'axios';
import swal from "sweetalert";
import * as Constants from '../utils/Constants';
import { isEqual } from 'lodash';
import TabLeafletConsumer from './TabLeafletConsumer';
import { useHistory } from 'react-router-dom';
import {CircularProgress } from 'react-md';


const ResponsiveRow = styled(Row)`
    display: flex;
    // @media screen and (max-width: 1200px) {
    //     flex-direction: column;
    // }
    @media screen and (max-width: 340px) {
        align-items: center;
    }
`;

const FetchBrowserLocationDiv = styled.div`
  margin-top: 22px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  > div {
      margin: -1px 10px 0 0; // Removed margin-bottom of 5px
      @media screen and (max-width:900px){
          width: 278px;
      }
      @media screen and (max-width:700px){
          width: 228px;
      }
      @media screen and (max-width:450px){
          width: 170px;
      }
      @media screen and (max-width:390px){
          width: 140px;
      }
  }
  > button {
      margin: 0;
      width: max-content;
  }
  @media screen and (max-width:390px){
      gap: 0 3px;
  }
  ${({isDisabled}) => isDisabled && css`
      opacity: 0.4;
      > button {
          pointer-events: none;
      }
  `}
`;

const DirectionInputs = styled.div`
    display: flex;
    gap: 0 20px;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

const DeviceSettings = styled.div`
    ${({isDisabled}) => isDisabled && css`
        opacity: 0.4;
        pointer-events: none;
        ${FetchBrowserLocationDiv} {
            opacity: 1;
        }
    `}
`;

const ToggleContainer = styled.div`
    > div > label {
        flex-direction: row-reverse;
        > div {
            margin-left: 0;
        }
    }
    ${({isDisabled}) => isDisabled && css`
        pointer-events: none;
    `}
`;

const ResponsiveFieldset = styled.fieldset`
  margin: 15px 0 0 10px;
  > div {
    padding: 10px 15px;
  }
`;

const DeviceCheckboxContainer = styled.div`
  @media screen and (max-width: 390px) {
    font-size: 0.52rem;
  }
  ${({isDisabled}) => isDisabled && css`
      opacity: 0.4;
  `}
`;

const InputCheck=styled.input`
  @media screen and (max-width: 390px) {
      font-size: 0.52rem;
  }
`;

const RightDivEdit=styled.div`
margin:0px 0px 0px 20px;
`;

function Leaflet(props) {
    const {locationInputs = {}, isViewPage, isSafie, showMap, stackName, readonly, recorderState, streamStatus, updateCamera, locationData, parentCallback} = props;
    const center = {
      lat: '35.65283',
      lng: '139.83947',
    }
    const [position, setPosition] = useState(center);
    const markerRef = useRef(null);
    const [isSafieLocationAvailable, setIsSafieLocationAvailable] = useState(locationInputs.latitude !== 'NA');
    const [inputs, setInputs] = useState(locationInputs);
    const [readOnly, setReadOnly] = useState(readonly);
    const [safieDeviceName, setSafieDeviceName] = useState(recorderState.camera_name);
    const prevCameraRef = useRef();
    const prevLocationInputsRef = useRef(null);
    const [isMounted, setIsMounted] = useState(true);
    const [isMounted2, setIsMounted2] = useState(true);
    const [isMapConsumerLoaded, setIsMapConsumerLoaded] = useState(false);
    const [deviceCheckbox, setDeviceCheckbox] = useState(localStorage.getItem('deviceCheckbox') === null ? true : localStorage.getItem('deviceCheckbox') === 'true');
    const [mapViewType, setMapViewType] = useState('NoDevice');
    const [deviceLocationData, setDeviceLocationData] = useState({});
    const [loading, setLoading] = useState(false);  
    const currentMapView = useRef();
    const history = useHistory();
    const mapMaxBounds = new L.LatLngBounds(
      new L.LatLng(90, 180),
      new L.LatLng(-90, -180)
    );

    useEffect(() => {
      if (isMounted) {
        if (isSafieLocationAvailable) {
          setInputs(prev => {return {...prev, ...locationInputs}});
        } else {
          setInputs(prev => {return {...prev, latitude: position.lat, longitude: position.lng}})
        }
        setIsMounted(false);
      }
    }, [isMounted, position, isSafieLocationAvailable, locationInputs]);

    useEffect(() => {
      if(isSafie) {
        setTimeout(() => {
          setSafieDeviceName(recorderState.camera_name);
          prevCameraRef.current = recorderState.camera_name;
          if(updateCamera !== undefined || updateCamera !== '') {
            setSafieDeviceName(updateCamera);
          }
        }, 2000);
      }
    },[isViewPage, updateCamera, isSafie, recorderState, parentCallback]);

    function round5(value) {
      return (Math.round(value * 1e5) / 1e5).toFixed(5);
    }

    useEffect(() => {
      if(!isEqual(prevLocationInputsRef.current, locationInputs)){
        prevLocationInputsRef.current = locationInputs;
        if (isViewPage) {
          setIsSafieLocationAvailable((locationInputs.latitude !== 'NA') && (locationInputs.longitude !== 'NA'));
        }
        if((locationInputs.latitude !== 'NA') && (locationInputs.longitude !== 'NA')) {
            const center = {
                lat: locationInputs.latitude,
                lng: locationInputs.longitude,
            }
            if (isMounted2) {
              if ((center.lat !== undefined && center.lat !== '') && (center.lng !== undefined && center.lng !== '')) {
                setPosition(center);
              } else {
                setPosition(prev => prev);
              }
              setIsMounted2(false);
            }
        } else {
          setPosition(prev => prev);
        }
      }
    },[isMounted2, isViewPage, locationInputs])

    const getLocationApi = useCallback(() => {
      axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_location')
      .then(res => {
        const data = res.data.data;
        setDeviceLocationData(data);
        setIsMapConsumerLoaded(true);
      })
      .catch(err => {
        setIsMapConsumerLoaded(true);
          console.log(err);
      });
    }, []);

    useEffect(() => {
        getLocationApi();
    }, [getLocationApi]);

    const eventHandlers = useMemo(
      () => ({
        dragend(e) {
          const marker = markerRef.current;
          if (marker != null) {
            let temp;
            setPosition(prev => {
              const {lat, lng} = e.target._latlng;
              temp = prev;
              if (lat > 84.75) {
                temp.lat = 84.75;
              } else if (lat < -85.5) {
                temp.lat = -85.5;
              } else {
                temp.lat = lat;
              }
              
              if (lng > 180) {
                temp.lng = 180;
              } else if (lng < -180) {
                temp.lng = -180;
              } else {
                temp.lng = lng;
              }
              return JSON.parse(JSON.stringify(temp));
            });
            let data = {...inputs};
            data.longitude = round5(temp.lng).toString();
            data.latitude = round5(temp.lat).toString();
            setInputs(data);
            if(!isViewPage){
              parentCallback(data);
            }
          }
        },
      }),
      [inputs, isViewPage, parentCallback],
    )

    const onToggle = useCallback((value, event) => {
      event.persist();
      setInputs(prev => {
        let data = {};
        if (prev.latitude === undefined || prev.latitude === '') {
          data = {latitude: '35.65283'};
        }
        if (prev.longitude === undefined || prev.longitude === '') {
          data = {...data, longitude: '139.83947'};
        }
        if (!isViewPage) {
          parentCallback({...prev, ...data, [event.target.id]: value});
        }
        return {...prev, ...data, [event.target.id]: value};
      });
    }, [isViewPage, parentCallback]);

    const onCheckboxChange = (event) => {
      event.persist();
      if(event.target.checked) {
        setLoading(true);
        let jsonData = {
          "status_code": "string",
          "message": "string",
          "data": {"device_id": safieDeviceName === '' ? recorderState.camera_name : safieDeviceName}
        }
        axios.post(Constants.EDGE_API_ENDPOINT + '/devices/safie/location', jsonData)
          .then((res) => {
            let dataSafie = res.data.data;
            if(dataSafie.gps_status === 'on') {
              let data = {...inputs, sync_safie_location: true};
              setInputs(data);
              swal({ text: 'Safie GPS API is available but the geolocation cannot be determined.', icon: 'error' });
              setIsSafieLocationAvailable(false);
              if(!isViewPage){
                props.parentCallback(data);
              }
              return;
            } else if(dataSafie.gps_status === 'off') {
              let data = {...inputs, sync_safie_location: true};
              setInputs(data);
              swal({ text: 'Safie GPS API is available but turned off.  Please check the camera settings.', icon: 'error' });
              setIsSafieLocationAvailable(false);
              if(!isViewPage){
                props.parentCallback(data);
              }
              return;
            } else if(dataSafie.gps_status === 'active') {
              let data = {...inputs, longitude: round5(dataSafie.location.longitude), latitude: round5(dataSafie.location.latitude), sync_safie_location:  true};
              setInputs(data);
              setPosition({lat: data.latitude, lng: data.longitude});
              setIsSafieLocationAvailable(true);
              if(!isViewPage){
                props.parentCallback(data);
              }
              // swal('Latitude: '+  round5(dataSafie.location.latitude)  +'\nLongitude: ' + round5(dataSafie.location.longitude) + '\nTime '+ dataSafie.time,{ timer: 4000 });
            } else {
              swal({ text: 'Could not fetch device location, please check safie connector settings.', icon: 'error' });
              setIsSafieLocationAvailable(false);
              let data = {...inputs, sync_safie_location: true};
              setInputs(data);
              if(!isViewPage){
                props.parentCallback(data);
              }
            }
          }).catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
            setIsSafieLocationAvailable(false);
          }).finally(() => {
            setLoading(false);
          });
      } else {
        setInputs(prev => {
          if(!isViewPage){
            props.parentCallback({...prev, sync_safie_location: event.target.checked});
          }
          return {...prev, sync_safie_location: event.target.checked};
        });
      }
    }

    const handleOnChange = (event) => {
      let data = { ...inputs };
      if(event.target.name === 'latitude') {
          event.target.value = event.target.value.replace(/[^.-\d]/g, '');
          if((event.target.value > 90  ||  event.target.value <= -90)) {
              return '';
          } else if(event.target.value.split('.').length>2){
              event.target.value = event.target.value.replace(/\.+$/,"");
          } else if(event.target.value.split('-').length>2){
              event.target.value = event.target.value.replace(/\.+$/,"");
          } else if(event.target.value.charAt(event.target.value.length) && event.target.value.length > 1 && event.target.value.includes('-')){
              event.target.value = event.target.value.replace('-', '');
          } else {
              data[event.target.name] = event.target.value;
              setInputs(data);
              if(!isViewPage){
                props.parentCallback(data);
              }
          }
      } else if(event.target.name === 'longitude') {
          event.target.value = event.target.value.replace(/[^.-\d]/g, '');
          if((event.target.value > 180  ||  event.target.value < -180)) {
              return '';
          } else if(event.target.value.split('.').length>2){
              event.target.value = event.target.value.replace(/\.+$/,"");
          } else if(event.target.value.split('-').length>2){
              event.target.value = event.target.value.replace(/\.+$/,"");
          } else if(event.target.value.charAt(event.target.value.length) && event.target.value.length > 1 && event.target.value.includes('-')){
              event.target.value = event.target.value.replace('-', '');
          } else {
              data[event.target.name] = event.target.value;
              setInputs(data);
              if(!isViewPage){
                props.parentCallback(data);
            }
          }
      } else {
          data[event.target.name] = event.target.value;
          setInputs(data); 
          if(!isViewPage){
            props.parentCallback(data);
        }
      }
  };

  const onBlurInputs = (event) => {
    // return;
    event.persist();
      let data = {...inputs};
      if (event.target.id === 'latitude' && (event.target.value.length === 1 && event.target.value === '-')) {
          document.getElementById(event.target.id).style.borderColor = 'lightgray';
          return;
      } else if (event.target.id === 'longitude' && (event.target.value.length === 1 && event.target.value === '-')) {
          document.getElementById(event.target.id).style.borderColor = 'lightgray';
          return;
      } else if (event.target.id === 'longitude' && event.target.value === '') {
          document.getElementById(event.target.id).style.borderColor = 'lightgray';
          data[event.target.id] = 0;
          setInputs(prev => {
            if (!isViewPage) {
              parentCallback({...prev, [event.target.id]: '0'});
            }
            return {...prev, [event.target.id]: '0'};
          });
          setPosition(prev => { return {...prev, lng:  '0'}});
          return;
      } else if (event.target.id === 'latitude' && event.target.value === '') {
          document.getElementById(event.target.id).style.borderColor = 'lightgray';
          data[event.target.id] = '0';
          setInputs(prev => {
            if (!isViewPage) {
              parentCallback({...prev, [event.target.id]: '0'});
            }
            return {...prev, [event.target.id]: '0'};
          });
          setPosition(prev => { return {...prev, lat:  '0'}});
          return;
      } else {
        if(data.latitude === '-' || data.latitude === '.') {
          document.getElementById('latitude').style.borderColor = 'lightgray';
          return;
        } else if(data.longitude === '-' || data.longitude === '.') {
          document.getElementById('longitude').style.borderColor = 'lightgray';
          return;
        } else {
          data[event.target.id] = round5(parseFloat(event.target.value)).toString();
          if(event.target.id === 'latitude') {
            setPosition(prev => { return {...prev, lat:  round5(parseFloat(event.target.value)).toString()}});
          } else {
            setPosition(prev => { return {...prev, lng:  round5(parseFloat(event.target.value)).toString()}});
          }
        } 
      }
  }

  const getLocation = useCallback(() => {
    try {
      navigator.geolocation.getCurrentPosition(
          function(position) {
            let data = { lat: round5(position.coords.latitude), lng: round5(position.coords.longitude) };
            setPosition(data);
            setInputs(prev => {
              if (isSafie) {
                prev.sync_safie_location = false;
              }
              if (!isViewPage) {
                props.parentCallback({...prev, latitude: data.lat, longitude: data.lng});
              }
              return {...prev, latitude: data.lat, longitude: data.lng};
            });
          },
          function(error) {
            swal({ text: "Some error occured while fetching browser's location.", icon: 'error' });
  
          }
      );
    } catch (err) {
      swal({ text: "Some error occured while fetching browser's location.", icon: 'error' });
    } 
  }, [isSafie])
  
  function validateText(str) {
    var tarea = str;
    if (tarea.indexOf("http://") == 0) {
        return true;
    } else if (tarea.indexOf("https://") == 0) {
        return false;
    }
  }
  
  const getBrowserLocation = () => {
    if(!readOnly){
        const Url = window.location.href;
        const isHttp = validateText(Url);
        if(isHttp) {
            swal({ text: "Browser's Location can be used only when site is accessed through HTTPS", icon: 'error' });
        } else {
            getLocation();
        }
    }
  };

  const onSaveLocation = useCallback(() => {
    let location = {...inputs}
    let data = {...inputs}
    let lat = location.latitude;
    let lon = location.longitude;
    data.longitude = round5(data.longitude.toString());
    data.latitude = round5(data.latitude.toString());
    if(((lat.length === 1 && lat === '-') || lat === '')){
        swal('Invalid Latitude.', { icon: 'error'});
        return;
    }
    if((lon.length === 1 && lon === '-') || lon === ''){
        swal('Invalid Longitude.', { icon: 'error'});
        return;
    }
    let jsonData = {
      "namespace": "default"
    }
    jsonData["location"] = location;
    axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
          swal("Stream location updated successfully.", { icon: "success"});
          setTimeout(function(){ window.location.reload() }, 2000);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
  }, [stackName, inputs]);

  useEffect(() => {
    if(locationData) {
      if(updateCamera !== '') {
        if(prevCameraRef.current !== updateCamera) {
          onSaveLocation();
        }
      }
    }
  },[locationData, updateCamera, onSaveLocation]);

  const onEdit = () => {
    setReadOnly(false);
  }

  const markerIcon = () => {
    let icon = '';
    if(streamStatus === 'running') {
      icon = markerIconGreen;
    } else if (streamStatus === 'disabled') {
      icon = markerIconGrey;
    } else if (streamStatus === 'recovering') {
      icon = markerIconYellow;
    } else if (streamStatus === 'failed') {
      icon = markerIconRed;
    } else if (streamStatus === 'paused') {
      icon = markerIconBlue;
    } else if (streamStatus === 'desired') {
      icon = markerIconYellow;
    } else {
      icon = markerIconBlack;
    }
    return icon;
  }

  const onMapDragEnd = useCallback(() => {
    setDeviceCheckbox(false);
  }, []);

  const onDoubleClkPosition = useCallback((streamPosition) => {
    if (readOnly) return;
    setPosition(streamPosition);
    const data = {latitude: round5(streamPosition.lat).toString(), longitude: round5(streamPosition.lng).toString()}
    setInputs(prev => {
      return {...prev, ...data};
    });
  }, [isViewPage, readOnly]);

  const onDeviceCheckbox = useCallback((e) => {
    const {checked} = e.target;
    setDeviceCheckbox(checked);
  }, []);

  useEffect(() => {
    if (!isViewPage) {
      parentCallback(inputs);
    }
  }, [isViewPage, inputs, parentCallback]);
  
  useEffect(() => {
    localStorage.setItem('deviceCheckbox', deviceCheckbox);
    if (deviceCheckbox) {
      setMapViewType('Device');
    } else {
      setMapViewType('NoDevice');
    }
  }, [deviceCheckbox]);

  
  useEffect(() => {
    if (deviceLocationData.enabled_location === false) {
      localStorage.setItem('deviceCheckbox', false);
      setDeviceCheckbox(false);
    }
  }, [deviceLocationData.enabled_location]);

  const getMapCurrentView = useCallback((mapCurrentView) => {
    currentMapView.current = mapCurrentView;
  }, []);

  const onDeviceMarkerClick = useCallback(() => {
    if (isViewPage && readOnly) {
      localStorage.setItem('keepRedirectedDeviceMapView', JSON.stringify({keep: true, ...currentMapView.current}));
      history.push('./location?general');
    }
  }, [isViewPage, readOnly]);

    return (
      <TabPanel tabId="geoLocation">
         <ResponsiveRow style={{flexDirection:'column',  'padding': '10px 10px 10px 2%'}}>
            <div id="map" style={{'marginTop': '20px'}}>
              {showMap && 
                <MapContainer
                  doubleClickZoom={false}
                  scrollWheelZoom={isViewPage ? readOnly ? false : !inputs.sync_safie_location : !inputs.sync_safie_location}
                  maxBounds={mapMaxBounds}
                  minZoom={1}
                  maxBoundsViscosity={1}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                  {(inputs.enabled_location && (inputs.sync_safie_location ? isSafieLocationAvailable : true)) ?
                    <Marker 
                        position={position} 
                        icon={new Icon({iconUrl: markerIcon(), iconSize: [40, 51], iconAnchor: [22, 51]})}
                        zIndexOffset={2}
                        draggable={isViewPage ? readOnly ? false : !inputs.sync_safie_location : !inputs.sync_safie_location }
                        eventHandlers={eventHandlers}
                        ref={markerRef}>
                    </Marker>
                  : null}
                  {deviceLocationData.enabled_location ? <Marker
                    position={[deviceLocationData.latitude, deviceLocationData.longitude]}
                    icon={new Icon({ iconUrl: markerIconMap, iconSize: [25, 41], iconAnchor: [12, 41] })}
                    eventHandlers={{
                      click: onDeviceMarkerClick
                    }}
                    zIndexOffset={1}
                    draggable={false}>
                    <Tooltip style={{ 'font-size': '15px' }}>This Device</Tooltip>
                  </Marker> : null}
                  {isMapConsumerLoaded ? <TabLeafletConsumer
                    devicePosition={[deviceLocationData.latitude, deviceLocationData.longitude]}
                    streamPosition={[position.lat, position.lng]}
                    isStreamEnabled={(inputs.enabled_location && (inputs.sync_safie_location ? isSafieLocationAvailable : true))}
                    {...{mapViewType, onMapDragEnd, onDoubleClkPosition, getMapCurrentView}}
                  />: null}
                </MapContainer>}
            </div>
            <DeviceCheckboxContainer isDisabled={!deviceLocationData.enabled_location} style={{'display': 'flex', 'flex-wrap': 'wrap', "align-items": "baseline", 'margin': '10px 0 0 6px'}}>
              <InputCheck type="checkbox" id="center_map" name="center_map" checked={deviceCheckbox} disabled={!deviceLocationData.enabled_location} onChange={onDeviceCheckbox} style={{marginTop: '10px'}}/>&nbsp;Move the map to show the device location
            </DeviceCheckboxContainer>
            <div style={{maxWidth: '546px'}}>
              <ResponsiveFieldset>
                <legend>Stream Location</legend>
                <div>
                  <ToggleContainer style={{'marginTop': '5px'}} isDisabled={isViewPage && readOnly}>
                    <SelectionControl style={{height: 'auto'}} id='enabled_location' type="switch" label={inputs.enabled_location ? "Enabled": "Disabled"} name="enabled_location" labelBefore="true" value={inputs.enabled_location} checked={inputs.enabled_location} onChange={onToggle} />
                  </ToggleContainer>
                  <DeviceSettings isDisabled={!inputs.enabled_location}>
                    <DirectionInputs>
                      <div>
                        <TextFieldLabel style={{'width': '80px'}}>Latitude</TextFieldLabel>
                        <TextFieldSmall id="latitude" name='latitude' title={(inputs.sync_safie_location && !isSafieLocationAvailable) ? 'NA': inputs.enabled_location === false ? '' : inputs.latitude} maxLength={8}  value={(inputs.sync_safie_location && !isSafieLocationAvailable) ? 'NA': inputs.enabled_location === false ? '' : inputs.latitude} disabled={readOnly || inputs.sync_safie_location || !inputs.enabled_location} style={{'width': '150px', 'cursor':'pointer'}} onChange={handleOnChange} onBlur={onBlurInputs} />
                      </div>
                      <div>
                        <TextFieldLabel style={{'width': '80px'}}>Longitude</TextFieldLabel>
                        <TextFieldSmall id="longitude" name='longitude' title={(inputs.sync_safie_location && !isSafieLocationAvailable) ? 'NA': inputs.enabled_location === false ? '' : inputs.longitude} maxLength={9} value={(inputs.sync_safie_location && !isSafieLocationAvailable) ? 'NA': inputs.enabled_location === false ? '' : inputs.longitude} disabled={readOnly || inputs.sync_safie_location || !inputs.enabled_location} style={{'width': '150px', 'cursor':'pointer'}} onChange={handleOnChange} onBlur={onBlurInputs} /> 
                      </div>
                    </DirectionInputs>
                  </DeviceSettings>
                  {isSafie &&
                    <div style={{'display': 'flex', 'alignItems':'baseline', 'margin': '15px 0 0 -3px'}}>
                    { loading ?
                      <>
                        <CircularProgress style={{height:'15px',width:'15px',margin:'0 5px 0 0px'}} /> &nbsp;<label htmlFor="">Sync location using safie</label>
                      </>
                      :
                      <>
                        <input type="checkbox" checked={inputs.enabled_location && inputs.sync_safie_location} value={inputs.enabled_location && inputs.sync_safie_location} disabled={!inputs.enabled_location  || (isViewPage && readOnly)} name="sync_safie_location" id='sync_safie_location' onChange={onCheckboxChange} />
                        <span style={{opacity: `${!inputs.enabled_location ? 0.4 : 1}`}}>&nbsp;Sync location using safie</span>
                      </>
                    }
                    </div>
                  }
                  <FetchBrowserLocationDiv
                    isDisabled={!inputs.enabled_location || validateText(window.location.href)}
                  >
                    <div>
                      Fetch the geolocation from your browser{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        (requiring Webgate/HTTPS)
                      </span>
                    </div>
                    <Button
                      id="browser_location"
                      primary
                      disabled={readOnly || !inputs.enabled_location}
                      onClick={getBrowserLocation}
                    >
                      Fetch
                    </Button>
                  </FetchBrowserLocationDiv>
                </div>
              </ResponsiveFieldset>
              {isViewPage &&
                  <div style={{width: '100%', display:'flex', justifyContent: 'flex-end'}}>
                    {readOnly ? <Button id="editBroadcastererButton" primary onClick={onEdit}>Edit</Button> : 
                    <div>   
                      <Button primary onClick={onSaveLocation}>Save</Button>
                      <Button onClick={() => window.location.reload()}>Cancel</Button>
                    </div>}
                  </div>}
            </div>
          </ResponsiveRow>
      </TabPanel>
    );
  
}

export default React.memo(Leaflet);
