import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import markerIconGreen from '../images/green-camera.svg';
import markerIconGrey from '../images/grey-camera.svg';
import markerIconBlue from '../images/blue-camera.svg';
import markerIconRed from '../images/red-camera.svg';
import markerIconYellow from '../images/yellow-camera.svg';
import markerIconBlack from '../images/black-camera.svg';
import { markerClusterGroup } from 'leaflet';
import { useHistory } from 'react-router';
import { isEqual } from 'lodash';
import { useRef } from 'react';

function LeafletConsumer({ updateMarkerPosition = () => {}, onStreamMarkerDragEnd = () => {}, getMapCurrentView = () => {}, stackList = {}, streamStatusData = {}, readOnly, mapViewType, devicePosition, onMapDragEnd = () => {}, isStreamPage = false }) {
  const [isMounted, setIsMounted] = useState(true);
  const [streamMarkersList, setStreamMarkersList] = useState([]);
  const isProgramaticZoom = useRef(true);
  const mapViewTypeRef = useRef(mapViewType);
  const streamMarkersListRef = useRef(streamMarkersList);
  const devicePositionRef = useRef(devicePosition);
  const prevStackListRef = useRef({});
  const isRedirectMount = useRef(true);
  const prevStreamStatusDataRef = useRef(undefined);  
  const [markersLocation, setMarkersLocation] = useState([]);
  const [isMarkersListAvailable, setIsMarkersListAvailable] = useState(false);
  const prevMarkersLocationRef = useRef();
  const history = useHistory();
  const map = useMap();
  const mcg = useMemo(() => {
    return markerClusterGroup({
      iconCreateFunction: function (cluster) {
        var markers = cluster.getAllChildMarkers();
        var html = '<div>' + markers.length + '</div>';
        return L.divIcon({ html, className: 'clusterIcon', iconSize: L.point(32, 32) });
      },
      maxClusterRadius: 80,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: false,
      animate: true,
			spiderfyOnMaxZoom: true
    });
  }, []);

  // for map drag-end event
  const handleMapDragEnd = useCallback(() => {
    onMapDragEnd();
  }, [onMapDragEnd]);

  useEffect(() => {
    map.on('dragend', handleMapDragEnd);
    return () => {
      map.removeEventListener('dragend', handleMapDragEnd)
    }
  }, [map, handleMapDragEnd]);

  // for map move-end event, for saving local-storage map center on move-end
  const handleMapMoveEnd = useCallback(() => {
    const mapCurrentView = {latLng: map.getCenter(), zoom: map.getZoom()};
    getMapCurrentView(mapCurrentView);
    if (isStreamPage) {
      localStorage.setItem('geolocationMapCenterStream', JSON.stringify(mapCurrentView));
    } else {
      localStorage.setItem('geolocationMapCenterSettings', JSON.stringify(mapCurrentView));
      localStorage.setItem('keepRedirectedDeviceMapView', JSON.stringify({keep: true, ...mapCurrentView}));
    }
  }, [map, isStreamPage, getMapCurrentView]);

  useEffect(() => {
    map.on('moveend zoomend', handleMapMoveEnd);
    return () => {
      map.removeEventListener('moveend zoomend', handleMapMoveEnd)
    }
  }, [map, handleMapMoveEnd]);
  
  const getBoundingList = useCallback((mapViewType, streamMarkersList, devicePosition) => {
    let boundingList = [];
    const {lat, lng} = devicePosition;
    if (mapViewType === 'StreamDevice') {
      if (lat !== undefined) {
        boundingList.push(L.marker([lat, lng]));
      }
      streamMarkersList.forEach(item => {
        boundingList.push(L.marker(item));
      });
    } else if (mapViewType === 'Device') {
      if (lat !== undefined) {
        boundingList.push(L.marker([lat, lng]));
      }
    } else if (mapViewType === 'Stream') {
      streamMarkersList.forEach(item => {
        boundingList.push(L.marker(item));
      });
    }
    return boundingList;
  }, []);

  useEffect(() => {
    mapViewTypeRef.current = mapViewType;
    streamMarkersListRef.current = streamMarkersList;
    devicePositionRef.current = devicePosition;
  }, [mapViewType, streamMarkersList, devicePosition]);

  const shouldBoundingUncheck = useCallback(() => {
    return new Promise((resolve, reject) => {
      let isUncheck = true;
      setTimeout(() => {
        const boundingList = getBoundingList(mapViewTypeRef.current, streamMarkersListRef.current, devicePositionRef.current)
        if (boundingList.length !== 0) {
          const group = new L.featureGroup(boundingList);
          const glatlngBounds = group.getBounds();
          let prevMapZoom = map.getZoom();
          let maxBoundsZoom = map.getBoundsZoom(glatlngBounds);
          const glatlngCenter = glatlngBounds.getCenter();
          const mlatlngCenter = map.getBounds().getCenter();
          if (prevMapZoom <= maxBoundsZoom) {
            isUncheck = false;
            if (!isEqual(glatlngCenter, mlatlngCenter)) {
              map.addOneTimeEventListener('zoomend', () => {
                const currentMapZoom = map.getZoom();
                map.fitBounds(glatlngBounds, {maxZoom: currentMapZoom});
              });
            }
          } else {
            isUncheck = true;
          }
        }
        resolve(isUncheck);
      }, 100);
    });
  }, [map, isStreamPage, getBoundingList]);

  const handleZoomStart = useCallback(async () => {
    map.addOneTimeEventListener('zoomend', () => {
      const currentMapZoom = map.getZoom();
      if (isStreamPage) {
        if (window.location.search.includes('selectedTab=map')) {
          localStorage.setItem('mapZoomStream', currentMapZoom); // data fetched on load of stream tab so it changes the zoom to 18 which we don't want it.
        }
      } else {
        localStorage.setItem('mapZoomSettings', currentMapZoom);
      }
    });
    if (!isProgramaticZoom.current) {
      const isUncheck = await shouldBoundingUncheck();
      if (isUncheck) {
        onMapDragEnd();
      }
    }
  }, [map, isStreamPage, onMapDragEnd, shouldBoundingUncheck]);
  
  useEffect(() => {
    map.addEventListener('zoomstart', handleZoomStart);
    return () => {
      map.removeEventListener('zoomstart', handleZoomStart);
    };
  }, [map]);

  // for changing device location on double click in map
  const handleDoubleClick = useCallback((e) => {
    updateMarkerPosition(e.latlng);
  }, [updateMarkerPosition]);

  useEffect(() => {
    map.on('dblclick', handleDoubleClick);
    return () => {
      map.removeEventListener('dblclick', handleDoubleClick)
    }
  }, [map, handleDoubleClick]);

  // marker cluster group logic
  const getStackTooltipDetails = useCallback((stack_name, stack) => {
    let name = ''
    if (stack === 'USB') {
      name = stack_name + ' (Web Camera)'
    } else if (stack === 'RTSP') {
      name = stack_name + ' (IP Camera)'
    } else if (stack === 'FTP') {
      name = stack_name + ' (Image FTP)'
    } else if (stack === 'SAFIE') {
      name = stack_name + ' (Safie Camera)'
    } else if (stack === 'MLIT') {
      name = stack_name + ' (MLIT Encoder)'
    } else if (stack === 'RTMP') {
      name = stack_name + ' (RTMP)'
    } else if (stack === 'RTP') {
      name = stack_name + ' (RTP)'
    }
    return name;
  }, []);

  const markerToolTip = useCallback((stack_name, services, description) => {
    let NewStack = '';
    if(services["recorder1"]["recorder_type"] === "mlit_recorder"){
       NewStack = "MLIT"
    }else{
      NewStack = services["recorder1"]["camera_type"];
    }
    return (
      `${getStackTooltipDetails(stack_name, NewStack)}
      <br />${description === '' ? '' : description}`
    );
  }, [getStackTooltipDetails]);

  const getStreamStatus = useCallback((stackName) => {
    var streamStatus = '';
    try {
      if (streamStatusData && 'stacks' in streamStatusData) {
        streamStatus = streamStatusData['stacks'][stackName]['running_status']['status'];
      }
    } catch (error) {
      console.error(error);
    }
    return streamStatus;
  }, [streamStatusData]);

  const markerIcon = useCallback((stack_name) => {
    let icon = '';
    let status = getStreamStatus(stack_name);
    if (status === 'running') {
      icon = markerIconGreen;
    } else if (status === 'disabled') {
      icon = markerIconGrey;
    } else if (status === 'recovering') {
      icon = markerIconYellow;
    } else if (status === 'failed') {
      icon = markerIconRed;
    } else if (status === 'paused') {
      icon = markerIconBlue;
    } else if (status === 'desired') {
      icon = markerIconYellow;
    } else {
      icon = markerIconBlack;
    }
    return icon;
  }, [getStreamStatus]);

  const checkUndefinedNestedKey = useCallback((obj, ...args) => {
    return args.reduce((obj, level) => obj && obj[level], obj)
  }, []);

  useEffect(() => {
    function getSafieLocation(stackName) {
      let result = {location: {}, isLocAvailable: true};
      if (checkUndefinedNestedKey(streamStatusData, 'stacks', stackName.toString(), 'services', 'recorder1', 'health_status', 'status_detail', 'location_detail') !== undefined) {
        const {services: {recorder1: {health_status: {status_detail: {location_detail}}}}} = streamStatusData.stacks[stackName];
        let isLocAvailable;
        if (location_detail.gps_status === 'active') {
          isLocAvailable = true;
        } else {
          isLocAvailable = false;
        }
        result = {location: location_detail.location, isLocAvailable};
      }
      return result;
    }
    if (!isEqual(prevStreamStatusDataRef.current, streamStatusData) || !isEqual(stackList, prevStackListRef.current)) {
      const list = [];
      Object.keys(stackList).forEach(stackName => {
        const {
          location: {
            latitude,
            longitude,
            enabled_location = false,
            sync_safie_location = false,
          } = {},
          description,
          services,
        } = stackList[stackName];
        if (enabled_location) {
          if (sync_safie_location) {
            const {location: { latitude, longitude }, isLocAvailable = false} = getSafieLocation(stackName);
            if (isLocAvailable && latitude !== undefined && longitude !== undefined) {
              list.push({stackName, latitude, longitude, description, services, sync_safie_location});
            }
          } else {
            if (latitude !== undefined && longitude !== undefined) {
              list.push({stackName, latitude, longitude, description, services, sync_safie_location});
            }
          }
        }
      });
      setMarkersLocation(list);
    }
  }, [stackList, streamStatusData, checkUndefinedNestedKey]);

  const [markersList, setMarkersList] = useState([]);

  useEffect(() => {
    if (!isMounted && isMarkersListAvailable) {
      markersLocation.forEach((location, index) => {
        const { stackName, sync_safie_location } = location;
        if (markersList[index] instanceof L.Marker) {
          let icon = new L.Icon({ iconUrl: markerIcon(stackName), iconSize: [40, 51], iconAnchor: [22, 51] });
          markersList[index].setIcon(icon);
          if (!isStreamPage) {
            const isDraggable = sync_safie_location ? false : !readOnly;
            if (markersList[index].dragging !== undefined) {
              if (isDraggable) {
                markersList[index].dragging.enable();
              } else {
                markersList[index].dragging.disable();
              }
            } else {
              markersList[index].options.draggable = isDraggable;
            }
          }
        }
      });
      mcg.refreshClusters();
    }
  }, [isMounted, isMarkersListAvailable, markersList, markersLocation, readOnly, mcg, markerIcon]);

  useEffect(() => {
    if (isStreamPage && !isMounted && isEqual(markersLocation, prevMarkersLocationRef.current)) {
      return;
    }
    if (isMarkersListAvailable) {
      return;
    }
    const markersList = markersLocation.map((marker) => {
      const { stackName, latitude, longitude, description, services, sync_safie_location } = marker;
      return L.marker(new L.LatLng(latitude, longitude), {
        icon: new L.Icon({ iconUrl: markerIcon(stackName), iconSize: [40, 51], iconAnchor: [22, 51] }),
        draggable: sync_safie_location ? false : !readOnly, // need testing
        zIndexOffset: 2,
        id: stackName
      })
      .bindTooltip(markerToolTip(stackName, services, description))
      .on('click', () => {
        if (readOnly) {
          const localView = {keep: true, latLng: map.getCenter(), zoom: map.getZoom()};
          localStorage.setItem('keepRedirectedMapView', JSON.stringify({...localView}));
          history.push('./view-stream?stream=' + stackName + '&selectedTab=geoLocation&redirection=true');
        }
      })
      .on('dragend', (e) => {
        onStreamMarkerDragEnd(e, stackName);
      });
    });
    
    mcg.clearLayers();
    markersList.forEach((marker) => {
      if (marker instanceof L.Marker) {
        marker.addTo(mcg);
      }
    });
    mcg.on('clusterclick', (a) => {
      const {_southWest, _northEast} = a.layer._bounds;
      if (isEqual(_southWest, _northEast)) {
        a.layer.spiderfy();
      } else {
        const bounds = a.layer.getBounds();
        map.fitBounds(bounds, {padding: [30, 35]});
      }
    });
    map.addLayer(mcg);
    setMarkersList(markersList);
    if (isStreamPage && map.hasLayer(mcg) && markersList.length > 0) {
      setIsMarkersListAvailable(true);
    }
  }, [isStreamPage, isMounted, markersLocation, isMarkersListAvailable, readOnly, history, onStreamMarkerDragEnd, markerIcon, markerToolTip]);

  // for getting streams list 
  const getMarkers = useCallback((markersLocation) => {
    let markers = [];
    if (markersLocation) {
      markers = markersLocation.map(marker => {
        const { latitude, longitude } = marker;
        return [latitude, longitude];
      });
    }
    return markers;
  }, []);

  useEffect(() => {
    if (!isEqual(markersLocation, prevMarkersLocationRef.current)) {
      if (markersLocation.length !== 0) {
        const markers = getMarkers(markersLocation); // this also needs to be improved
        setStreamMarkersList(markers);
      }
      prevMarkersLocationRef.current = markersLocation;
    }
  }, [markersLocation, getMarkers]);

  // for map bounding 
  const changeMapView = useCallback((mapView) => {
    const { latLng, zoom, useCurrentZoom = false } = JSON.parse(mapView);
    let localPrevMapZoom;
    if (isStreamPage) {
      localPrevMapZoom = localStorage.getItem('mapZoomStream');
    } else {
      localPrevMapZoom = localStorage.getItem('mapZoomSettings');
    }
    if (useCurrentZoom) {
      let zoom = map.getZoom();
      if (zoom === undefined) {
        zoom = 5;
      }
      if (localPrevMapZoom !== null) {
        zoom = localPrevMapZoom;
      }
      map.setView(latLng, zoom);
      return;
    }
    map.setView(latLng, zoom);
  }, [map]);

  const setMapBounds = useCallback((streamMarkers = [], deviceLatLng = {}, isboundDevice) => {
    isProgramaticZoom.current = true;
    let boundingList = [];
    if (isboundDevice) {
      const {lat, lng} = deviceLatLng;
      boundingList.push(L.marker([lat, lng]));
    }
    streamMarkers.forEach(item => {
      boundingList.push(L.marker(item));
    });
    const group = new L.featureGroup(boundingList);
    let prevMapZoom = map.getZoom();
    let maxBoundsZoom = map.getBoundsZoom(group.getBounds());
    let maxZoom = 5;
    let localPrevMapZoom;
    if (isStreamPage) {
      localPrevMapZoom = localStorage.getItem('mapZoomStream');
    } else {
      localPrevMapZoom = localStorage.getItem('mapZoomSettings');
    }
    if (localPrevMapZoom !== null) {
      prevMapZoom = parseInt(localPrevMapZoom);
    }
    if (prevMapZoom <= maxBoundsZoom) {
      maxZoom = prevMapZoom;
    } else {
      maxZoom = maxBoundsZoom;
    }
    map.fitBounds(group.getBounds(), {maxZoom, padding: [30, 35]});

    setTimeout(() => {
      isProgramaticZoom.current = false;
    }, 100);
  }, [map, isStreamPage]);

  useEffect(() => {
    return () => {
      if (!isStreamPage && localStorage.getItem('keepRedirectedDeviceMapView') !== null) {
        localStorage.removeItem('keepRedirectedDeviceMapView')
      }
    };
  }, [isStreamPage]);

  useEffect(() => {
    // For using redirected map view
    if (!isStreamPage) {
      const localRedirectedView = JSON.parse(localStorage.getItem('keepRedirectedDeviceMapView'));
      if (localRedirectedView !== null && isRedirectMount.current) {
        const {keep, latLng, zoom} = localRedirectedView;
        if (keep) {
          isProgramaticZoom.current = true;
          map.setView(latLng, zoom);
          localStorage.setItem('keepRedirectedDeviceMapView', JSON.stringify({keep: true, latLng, zoom}));
          const boundingList = getBoundingList(mapViewType, streamMarkersList, devicePosition);
          if (boundingList.length > 0) {
            const group = new L.featureGroup(boundingList);
            const glatlngBounds = group.getBounds();
            const isInside = map.getBounds().contains(glatlngBounds);
            if (!isInside) {
              onMapDragEnd();
            }
          }
          setTimeout(() => {
            isProgramaticZoom.current = false;
          }, 100);
          if (isMounted) {
            setIsMounted(false);
          }
        }
        setTimeout(() => {
          if (isRedirectMount.current && (isEqual(mapViewTypeRef.current, mapViewType) && isEqual(streamMarkersListRef.current, streamMarkersList) && isEqual(devicePositionRef.current, devicePosition))) {
            isRedirectMount.current = false;
          }
        }, 1000);
        return;
      }
    }

    // Code for map view depending on checkboxes
    const defaultMapView = JSON.stringify({latLng: L.latLng(38.16911, 137.79403), zoom: 5});
    const isDeviceOnlyView = JSON.stringify({latLng: L.latLng(devicePosition.lat, devicePosition.lng), zoom: 5, useCurrentZoom: true});
    let localMapView;
    if (isStreamPage) {
      localMapView = localStorage.getItem('geolocationMapCenterStream');
    } else {
      localMapView = localStorage.getItem('geolocationMapCenterSettings');
    }

    switch (mapViewType) {
      case 'StreamDevice':
        setMapBounds(streamMarkersList, devicePosition, true);
        if (isMounted) {
          setIsMounted(false);
        }
        break;
      case 'Stream':
        if (streamMarkersList.length !== 0) {
          setMapBounds(streamMarkersList, {}, false);
        } else {
          if (localMapView !== null) {
            changeMapView(localMapView);
          } else {
            changeMapView(defaultMapView);
          }
        }
        if (isMounted) {
          setIsMounted(false);
        }
        break;
      case 'Device':
        changeMapView(isDeviceOnlyView);
        if (isMounted) {
          setIsMounted(false);
        }
        break;
      case 'NoStreamDevice':
        if (isMounted) {
          if (localMapView !== null) {
            changeMapView(localMapView);
          } else if (devicePosition.lat !== undefined && devicePosition.lng !== undefined) {
            changeMapView(isDeviceOnlyView);
          } else {
            changeMapView(defaultMapView);
          }
          setIsMounted(false);
        }
        break;
    }
    if (isProgramaticZoom.current) {
      setTimeout(() => {
        isProgramaticZoom.current = false;
      }, 100);
    }
  }, [isStreamPage, isMounted, mapViewType, devicePosition, streamMarkersList, setMapBounds, getBoundingList]);

  return null;
}

export default LeafletConsumer;