import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TabPanel } from 'react-web-tabs';
import { Wrapper, Label, SelectField, Row, Column, RightDiv, Button, MessageLabel} from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import { SelectionControl, GridList } from 'react-md';
import swal from "sweetalert";
import TimeRangeSlider from 'react-time-range-slider';
import styled from 'styled-components';

const MessageContaier = styled.div`
  padding: 5px 5px 5px 10px;
  font-size: 0.9em;
`;

const ResponsiveSelectField = styled(SelectField)`
    @media screen and (max-width: 450px) {
        width: 25px !important;
    }
`;
const ResponsiveGridContainer = styled.div`
    & > div {
        gap: 0 5px;
        & > div {
            width: 100px;
            @media screen and (max-width: 500px) {
                width: 82px;
            }
        }
        @media screen and (min-width: 2400px) {
            justify-content: space-between;
        }
        @media screen and (max-width: 900px) {
            justify-content: space-between;
        }
    }
`;

const TimeContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 15px 10px;
    flex-wrap: wrap;
    @media screen and (max-width: 465px) {
        padding-left: 5px;
        flex-direction: column;
    }
`;

const ResponsiveLabel = styled(Label)`
    width: 60px;
    flex-shrink: 0;
    @media screen and (max-width: 550px) {
        width: 45px;
    }
`;

const ResponsiveRow = styled(Row)`
    display: flex;
    @media screen and (max-width: 1100px) {
        flex-direction: column;
    }
    & > ${Column} {
        @media screen and (max-width: 1100px) {
            width: 100%;
            max-width: 600px;
        }
    }
`;

const LabelTime = styled.label`
  display:inline-block;
  margin-bottom:5px;
  font-size:14px;
    @media screen and (max-width: 400px) {
    font-size: 8px;
}
`;
const Label1 = styled.label`
  display:inline-block;
  margin-bottom:5px;
  width:40px;
    @media screen and (max-width: 400px) {
    width:21px;
    
}
`;

const Label2 = styled.label`
  display:inline-block;
  margin-bottom:5px;
  width:32px;
    @media screen and (max-width: 400px) {
    width:18px;
    
}
`;

const CaptureScheduleTab = (props) => {
    var isViewPage = props.readOnly;
    var stackName = props.stackName;
    const [readOnly, setReadOnly] = useState(props.readOnly);
    const [scheduleState, setScheduleState] = useState(Constants.CAPTURE_SCHEDULE_DEFAULT_VALUES);
    const [discreteState, setDiscreteState] = useState(Constants.DISCRETE_CAPTURE_DEFAULT_VALUES);
    const [timeSliderValue, setTimeSliderValue] = useState({start: "00:00:00", end: "23:59:00"});
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    props.parentCallback(scheduleState, discreteState);

    useEffect(() => {
        if(props.readOnly){
            let scheduleData = props.data;
            let scheduleCapture = {};
            scheduleCapture["enabled"] = scheduleData.enabled;
            scheduleCapture["start_time"] = scheduleData.start_time;
            scheduleCapture["end_time"] = scheduleData.end_time;
            let scheduleDayList = scheduleData.weekdays.split(",");
            Object.keys(Constants.WEEK_DAYS).map((week_day) => {
                if(scheduleDayList.includes(Constants.WEEK_DAYS[week_day])) {
                    scheduleCapture[week_day] = true;
                } else {
                    scheduleCapture[week_day] = false;
                }
            });
            setScheduleState(scheduleCapture);
            setTimeSliderValue({start: scheduleData.start_time, end: scheduleData.end_time});
            setDiscreteState(props.data.discrete_capture);
        } else {
           let scheduleDayList = scheduleState.weekdays.split(",");
            Object.keys(Constants.WEEK_DAYS).map((week_day) => {
                if(scheduleDayList.includes(Constants.WEEK_DAYS[week_day])) {
                    scheduleState[week_day] = true;
                } else {
                    scheduleState[week_day] = false;
                }
            });
            setScheduleState(scheduleState);
        }
    },[]);

    function changeStartHandler(time){
    }

    function timeChangeHandler(time){
        if(!readOnly){
            let startTime = time.start.split(':');
            let startHours = makeTwoDigit(startTime[0])
            if(startHours >= 0){
                startTime = startHours + ':' + makeTwoDigit(startTime[1]);
                time.start = startTime;
                let endTime = time.end.split(':');
                endTime = makeTwoDigit(endTime[0]) + ':' + makeTwoDigit(endTime[1]);
                time.end = endTime;
                setTimeSliderValue(time);
            }
        }
    }

    function makeTwoDigit(val){
        if(val != undefined){
            let str = val.toString();
            return str.length == 1 ? "0" + str : "" + str;
        }
    }

    function changeCompleteHandler(time){
        if(!readOnly){
            scheduleState["start_time"] = makeTwoDigit(time.start.hours) + ":" + makeTwoDigit(time.start.minutes);
            scheduleState["end_time"] = makeTwoDigit(time.end.hours) + ":" + makeTwoDigit(time.end.minutes);
            setScheduleState(scheduleState);
        }
    }

    const handleDiscreteToggleChange = (value, event) => {
        if(!readOnly){
            if(event.target.id === "discrete_capture_enabled"){
                if(scheduleState.enabled === false && value === true){
                    swal("Please enable capture schedule to enable discrete capture.");
                } else if(value === false && discreteState.optimize === true) {
                    let data ={...discreteState}
                    data.enabled = value;
                    data.optimize = false;
                    setDiscreteState(data);
                } else {
                    setDiscreteState({...discreteState, ["enabled"]: value});
                }
            } else {
                setDiscreteState({...discreteState, ["optimize"]: value});
            }
        }
    };

    const handleDiscreteOnChange = (event) => {
        if(!readOnly){
            event.persist();
            setDiscreteState({...discreteState, [event.target.id]: event.target.value});
        }
    }

    const handleScheduleToggleChange = (value, event) => {
        if(!readOnly){
            if(event.target.id === "schedule_enabled"){
                setScheduleState({...scheduleState, ["enabled"]: value});
            } else {
                setScheduleState({...scheduleState, [event.target.id]: value});
            }
        }
    };

    const editCaptureSchedule = (event) => {
        setReadOnly(false);
    }

    const cancelCaptureSchedule = (event) => {
        window.location.reload();
    }

    function getDate(hours, minutes, seconds){
        let date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);
        return date;
    }

    const saveCaptureSchedule = (event) => {
        let weekdays = "";
        Object.keys(Constants.WEEK_DAYS).map((week_day) => {
            if(scheduleState[week_day] === true) {
                weekdays += ((Constants.WEEK_DAYS[week_day]) + ",");
            }
        });
        weekdays = weekdays.replace(/,+$/, "");
        if(scheduleState.enabled){
            if(weekdays === ""){
                swal("Please select capture schedule day.");
                return;
            }
        }
        let startTimeArr = scheduleState.start_time.split(':');
        let endTimeArr = scheduleState.end_time.split(':')
        let startTime = getDate(parseInt(startTimeArr[0]), parseInt(startTimeArr[1]), 0);
        let endTime = getDate(parseInt(endTimeArr[0]), parseInt(endTimeArr[1]), 0);
        let scheduleInSeconds = (endTime.getTime() - startTime.getTime())/1000;
        if(discreteState.enabled){
            let intervalHours = parseInt(discreteState.interval_hours);
            let intervalMinutes = parseInt(discreteState.interval_minutes);
            let intervalSeconds = parseInt(discreteState.interval_seconds);
            if( intervalHours == 0 && intervalMinutes == 0 && intervalSeconds == 0){
                swal("Please select discrete capture interval.");
                return;
            }
            let intervalInSeconds = (intervalHours * 60 * 60) + (intervalMinutes * 60) + intervalSeconds;
            if(intervalInSeconds > scheduleInSeconds){
                swal("Discrete capture interval should be less than or equal to schedule duration.");
                return;
            } else {
                let captureHours = parseInt(discreteState.capture_hours);
                let captureMinutes = parseInt(discreteState.capture_minutes);
                let captureSeconds = parseInt(discreteState.capture_seconds);
                if( captureHours == 0 && captureMinutes == 0 && captureSeconds == 0){
                    swal("Please select capture duration.");
                    return;
                }
                let captureDurationInSeconds = (captureHours * 60 * 60) + (captureMinutes * 60) + captureSeconds;
                if(captureDurationInSeconds > intervalInSeconds){
                    swal("Capture duration should be less than or equal to interval.");
                    return;
                }
            }
        }

        let captureSchedule = {}
        captureSchedule["enabled"] = scheduleState["enabled"];
        captureSchedule["start_time"] = scheduleState["start_time"];
        captureSchedule["end_time"] = scheduleState["end_time"];
        captureSchedule["weekdays"] = weekdays;
        captureSchedule["discrete_capture"] = discreteState;
        let jsonData = {
            "namespace": "default"
        }
        jsonData["capture_schedule"] = captureSchedule;
        axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
            swal("Capture schedule updated successfully.", { icon: "success"});
            setReadOnly(true);
            setTimeout(function(){ window.location.reload() }, 2000)
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    function handleTimeRangeChange(event) { 
            let time={};
            if(event.target.id === "hours"){
                time = {...timeSliderValue, [event.target.name]: `${event.target.value}${timeSliderValue[event.target.name].slice(2)}`};
            }
            if(event.target.id === "minutes"){
                time = {...timeSliderValue, [event.target.name]: `${timeSliderValue[event.target.name].slice(0,3)}${event.target.value}${timeSliderValue[event.target.name].slice(5)}`};
            }
            if(event.target.id === "seconds"){
                time = {...timeSliderValue, [event.target.name]: `${timeSliderValue[event.target.name].slice(0,6)}${event.target.value}`};
            }
            let start_h = time.start.slice(0,2);
            let start_m = time.start.slice(3,5);
            let end_h = time.end.slice(0,2);
            let end_m = time.end.slice(3,5);

            if(start_h ==="00" && end_h ==="00"){
                if(parseInt(start_m, 10) >= parseInt(end_m, 0)){
                    swal("Please Select Valid Time Range");
                    return
                }
             } else if(start_h ==="00" || end_h ==="00"){
                    if(parseInt(start_h, 0) >= parseInt(end_h, 0)){
                        swal("Please Select Valid Time Range");
                        return
                    }
             } else{
                    if(parseInt(start_h, 0) >= parseInt(end_h, 0)){
                        swal("Please Select Valid Time Range");
                        return
                    }
             }
            setTimeSliderValue(time)

            if(!readOnly){
                scheduleState["start_time"] = time.start;
                scheduleState["end_time"] =  time.end;
                setScheduleState(scheduleState);
            }
    } 

    return (
        <TabPanel tabId="captureScheduleTab">
        <Wrapper>
        <Row>
        <Column>
            <SelectionControl id="schedule_enabled" type="switch" label={scheduleState.enabled? "Enabled" :"Disabled"} name="schedule_enabled" labelBefore="true" checked={scheduleState.enabled || false} onChange={handleScheduleToggleChange} style={{"display": "none"}}/>
        </Column>
        </Row>
        <ResponsiveRow>
        <Column>
            <div style={{"padding": (window.innerWidth == 1024 ? "10px 15px 15px 15px" : "0px 15px 5px 15px") }}>
                <TimeRangeSlider disabled={false} format={24} maxValue={"23:59"} minValue={"00:00"} name={"time_range"}
                    onChangeStart={changeStartHandler} onChangeComplete={changeCompleteHandler} onChange={timeChangeHandler}
                    step={1} value={timeSliderValue}/>
            </div>
            <div style={{"padding": "10px 0 0 0", "margin-bottom": "20px", "display": "flex", "justify-content": "space-between"}}>
                <div>
                <div style={{display:"flex", alignItems: 'baseline'}}> 
                    <Label1 style={{'marginBottom': '5px', 'marginLeft': '5px'}}>Start</Label1>
                    <LabelTime style={{"color":"rgb(50 44 44 / 90%)"}}>(hh:mm:ss)</LabelTime>
                </div>
                <div>
                    <ResponsiveSelectField id="hours" name="start" className="slider-time-dropdown" value={timeSliderValue.start.slice(0,2)} onChange={handleTimeRangeChange} disabled={readOnly}>
                    <SelectOptions items={Constants.HOURS}/>
                    </ResponsiveSelectField>:
                    <ResponsiveSelectField id="minutes" name="start" className="slider-time-dropdown" value={timeSliderValue.start.slice(3,5)} onChange={handleTimeRangeChange} disabled={readOnly}>
                    <SelectOptions items={Constants.MINUTES}/>
                    </ResponsiveSelectField>:
                    <ResponsiveSelectField id="seconds" name="start" className="slider-time-dropdown" value={timeSliderValue.start.slice(6, 12)} onChange={handleTimeRangeChange} disabled={readOnly}>
                    <SelectOptions items={Constants.SECONDS}/>
                    </ResponsiveSelectField>
                </div>
                </div>
                <div style={{display:"flex", 'flexDirection': 'column'}}>
                    <div style={{"display": "flex", alignItems: 'baseline'}}>
                        <Label2 style={{'marginBottom': '5px',  'marginLeft': '5px'}}>End</Label2>
                        <LabelTime style={{"color":"rgb(50 44 44 / 90%)"}}>(hh:mm:ss)</LabelTime>
                    </div>
                    <div>
                        <ResponsiveSelectField id="hours" name="end" className="slider-time-dropdown" value={timeSliderValue.end.slice(0,2)} onChange={handleTimeRangeChange} disabled={readOnly}>
                        <SelectOptions items={Constants.HOURS}/>
                        </ResponsiveSelectField>:
                        <ResponsiveSelectField id="minutes" name="end" className="slider-time-dropdown" value={timeSliderValue.end.slice(3,5)} onChange={handleTimeRangeChange} disabled={readOnly}>
                        <SelectOptions items={Constants.MINUTES}/>
                        </ResponsiveSelectField>:
                        <ResponsiveSelectField id="seconds" name="end" className="slider-time-dropdown" value={timeSliderValue.end.slice(6, 12)} onChange={handleTimeRangeChange} disabled={readOnly}>
                        <SelectOptions items={Constants.SECONDS}/>
                        </ResponsiveSelectField>
                    </div>
                </div>
            </div>
            <fieldset style={{'marginTop': '5px'}}>
            <legend>Capture Start Day</legend>
            <ResponsiveGridContainer>
                <GridList size={window.innerWidth>1024 ? 3 : 4} style={{"paddingLeft": "0px", "paddingTop": (window.innerWidth == 1024 ? "0px" : "22px")}}>
                { Object.keys(Constants.WEEK_DAYS).map((week_day) => {
                    let isSelected = scheduleState[week_day] === undefined ? false : scheduleState[week_day];
                    return(
                        <SelectionControl id={week_day} type="switch" label={week_day.toUpperCase()} name={week_day} labelBefore="true" checked={isSelected} onChange={handleScheduleToggleChange}/>
                    )
                })}
                </GridList>
            </ResponsiveGridContainer>
            </fieldset>
            
            { (props.readOnly && props.captureStatus) &&
                <Label style={{"marginTop": "25px"}}>Next Capture Start Time: {props.captureStatus.next_capture_start.replace('_', ' ')}</Label>
            }
        </Column>
        <Column>
        <fieldset>
            <legend>Discrete Capture</legend>
            <div style={{'display': 'flex'}}>
                <SelectionControl id="discrete_capture_enabled" name="discrete_capture_enabled" style={{"padding": "25px 0px"}} type="switch" label={discreteState.enabled? "Enabled" :"Disabled"} labelBefore="true" checked={discreteState.enabled || false} onChange={handleDiscreteToggleChange}/>
                <SelectionControl id="optimize" name="optimize" style={{"padding": "25px 0px"}} type="switch" label="Optimized Mode" labelBefore="true" checked={discreteState.optimize || false} onChange={handleDiscreteToggleChange} disabled={discreteState.enabled ? false : true}/>
            </div>
            <fieldset>
                <legend>Interval</legend>
                <TimeContainer>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <ResponsiveLabel>Hour</ResponsiveLabel>
                        <SelectField style={{margin: '0', width: '60px'}} id="interval_hours" name="interval_hours" value={discreteState.interval_hours} onChange={handleDiscreteOnChange} disabled={readOnly}>
                            <SelectOptions items={Constants.HOURS}/>
                        </SelectField>
                    </div>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <ResponsiveLabel>Minute</ResponsiveLabel>
                        <SelectField style={{margin: '0', width: '60px'}} id="interval_minutes" name="interval_minutes" value={discreteState.interval_minutes} onChange={handleDiscreteOnChange} disabled={readOnly}>
                            <SelectOptions items={Constants.MINUTES}/>
                        </SelectField>
                    </div>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <ResponsiveLabel>Second</ResponsiveLabel>
                        <SelectField style={{margin: '0', width: '60px'}} id="interval_seconds" name="interval_seconds" value={discreteState.interval_seconds} onChange={handleDiscreteOnChange} disabled={readOnly}>
                            <SelectOptions items={Constants.MINUTES}/>
                        </SelectField>
                    </div>
                </TimeContainer>
            </fieldset>
            <fieldset>
                <legend>Capture Duration</legend>
                <TimeContainer>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <ResponsiveLabel>Hour</ResponsiveLabel>
                        <SelectField style={{margin: '0', width: '60px'}} id="capture_hours" name="capture_hours" value={discreteState.capture_hours} onChange={handleDiscreteOnChange} disabled={readOnly}>
                            <SelectOptions items={Constants.HOURS}/>
                        </SelectField>
                    </div>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <ResponsiveLabel>Minute</ResponsiveLabel>
                        <SelectField style={{margin: '0', width: '60px'}} id="capture_minutes" name="capture_minutes" value={discreteState.capture_minutes} onChange={handleDiscreteOnChange} disabled={readOnly}>
                            <SelectOptions items={Constants.MINUTES}/>
                        </SelectField>
                    </div>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <ResponsiveLabel>Second</ResponsiveLabel>
                        <SelectField style={{margin: '0', width: '60px'}} id="capture_seconds" name="capture_seconds" value={discreteState.capture_seconds} onChange={handleDiscreteOnChange} disabled={readOnly}>
                            <SelectOptions items={Constants.MINUTES}/>
                        </SelectField>
                    </div>
                </TimeContainer>
            </fieldset>
        </fieldset>
        <MessageContaier>
            <MessageLabel>
                <b> Note:</b> When "Optimize Mode" is turned on, stream ingestion keeps running, only outputs are temporarily disabled.<br/>
            </MessageLabel>
        </MessageContaier>
        {isViewPage &&
            <RightDiv>
                { readOnly == true &&
                    <Button id="editCaptureScheduleButton" primary onClick={editCaptureSchedule}>Edit</Button>
                }
                { readOnly == false &&
                    <div>
                    <Button id="saveCaptureScheduleButton" primary onClick={saveCaptureSchedule}>Save</Button>
                    <Button id="cancelCaptureScheduleButton" onClick={cancelCaptureSchedule}>Cancel</Button>
                    </div>
                }
            </RightDiv>
        }
        </Column>
        </ResponsiveRow>
        </Wrapper>
        </TabPanel>
    )
}

export default CaptureScheduleTab;
