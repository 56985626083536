import React, { useEffect, useState, useCallback } from 'react';
import { ContentDiv, Column, Row, Label, TextField,TextArea, RightDiv, MessageLabel, Td,TableContainer, CenterDiv, PopupContainer, Button, SectionHeader, SectionLabel, Wrapper, overlayStyle, SelectField } from '../utils/Styles';
import format from 'date-fns/format';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import * as Constants from '../utils/Constants';
import * as Timezone from '../utils/TimeZoneList';
import swal from 'sweetalert';
import axios from 'axios';
import Loader from './Loader';
import { Tabs, Tab, TabList, TabPanel, TabProvider } from 'react-web-tabs';
import SelectOptions from './SelectOptions';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import axiosRetry from 'axios-retry';
import { SelectionControl, CircularProgress } from 'react-md';

const LabelName = styled(Label)`
  width: 240px;
  
  @media screen and (max-width: 820px) {
    width: 150px;
  }
  @media screen and (max-width: 725px) {
    width: 115px;
  }
  @media screen and (max-width: 490px) {
    width: 90px;
  }
  @media screen and (max-width: 420px) {
    width: 75px;
  }
  @media screen and (max-width: 400px) {
    width: 72px;
  }
`;

const FieldContainer = styled.div`
  display: flex;
`;

const FieldSet = styled.fieldset`
  width: 600px;
  padding: 20px;
  margin-top: 20px;
  
  @media screen and (max-width: 875px) {
    width: 545px;
  }
  @media screen and (max-width: 820px) {
    width: 460px;
  }
  @media screen and (max-width: 725px) {
    width: 420px;
  }
  @media screen and (max-width: 700px) {
    width: 400px;
  }
  @media screen and (max-width: 500px) {
    width: 375px;
  }
  @media screen and (max-width: 450px) {
    width: 350px;
  }
  @media screen and (max-width: 420px) {
    width: 300px;
  }
  @media screen and (max-width: 390px) {
    width: 278px;
  }
`;

const Fieldset = styled.fieldset`
  padding: 10px 20px;
  margin-left: 0px;
  margin-top: 20px;
  width: 550px;

  @media screen and (max-width: 840px) {
    width: 450px;
  }
  @media screen and (max-width: 735px) {
    width: 425px;
  }
  @media screen and (max-width: 700px) {
    width: 380px;
  }
  @media screen and (max-width: 480px) {
    width: 300px;
    ${FieldContainer} {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 400px) {
    width: 275px;
  }
  @media screen and (max-width: 375px) {
    width: 250px;
  }
`;

const TimeZoneInput = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between; 
  
  & > select {
    width: 200px;
  }
  @media screen and (max-width: 415px) {
    & > select {
      width: 150px;
    }
  }
`;

const TimeZoneColumn = styled(Column)`
  width: 450px;
  padding: 10px 20px;
  @media screen and (max-width: 525px) {
    width: 390px;
  }
  @media screen and (max-width: 450px) {
    width: 355px;
  }
  @media screen and (max-width: 415px) {
    width: 300px;
  }
  @media screen and (max-width: 355px) {
    width: 270px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  padding: 0px 20px 10px 20px;
`;

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
  margin-top: 20px;
`;

const MessageContaier = styled.div`
  padding: 5px 5px 1px 5px;
`;

const ResponsivePopupContainer = styled(PopupContainer)`
  @media screen and (max-height: 600px) {
    height: 415px;
    overflow-y: auto;
  }
`;

const UpperWrapper = styled(Wrapper)`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 420px) {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 390px) {
    font-size: 0.68rem;
  }
  @media screen and (max-width: 350px) {
    width: 290px;
  }
`;

const TimeSettings = () => {
  const [inputs, setInputs] = useState(); 
  const [browserDate, setBrowserDate] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [statusDetails, setStatusDetails] = useState();
  const [dates, setDates] = useState([]);
  const [selectedTab, setSelectedTab] = useState('general');
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const history = useHistory();
  const [timeLoad, setTimeLoad] = useState(true);
  const [clockLoad, setClockLoad] = useState(false);
  const [ntpLoad, setNtpLoad] = useState(false);
  const [localDate, setLocalDate] = useState('');
  const [clockInput, setClockInput] = useState();
  const [readOnlyClock, setReadOnlyClock] = useState(true);
  const [readOnlyNtp, setReadOnlyNtp] = useState(true);
  const [inputsNTP, setInputsNTP] = useState(); 
  const [retryClock,setRetryClock]=useState(false);
  const [retryStatus,setRetryStatus]=useState(false);
  const [retryNTP,setRetryNTP]=useState(false);


  var localTimeInterval;
  var globalTimeInterval;
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);


  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);


  const validationIpHost = (email) => {
    let re = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^[ A-Za-z0-9_@./#+-]*$/;
    return re.test(email);
  }

  const handleNtpInput = (event) => {
    if(!readOnlyNtp) {
      if ((event.target.name === 'ntp_servers') && event.target.value.includes('$')) {     
        event.target.value = event.target.value.replace('$', ''); 
      } else if((event.target.name === 'ntp_servers') && event.target.value.includes('&')) {
        event.target.value = event.target.value.replace('&', '');
      } else {
        setInputsNTP({...inputsNTP,[event.target.name]: event.target.value})
      }
    }
  }

  const editNtpInput = () => {
    setReadOnlyNtp(false);
  }

  const saveNtpInput = () => {
    let jsonData = Object.assign({}, inputsNTP);
    let ntpServer = jsonData.ntp_servers;
     let ntpServerSelection  = jsonData.server_type;

     if(ntpServerSelection === 'custom') {
       let invalidIPHost = [];
       if (ntpServer !== '') {
         invalidIPHost = [];
         let ipHost = ntpServer.split(',');
         for (let i = 0; i < ipHost.length; i++) {
           if (!validationIpHost(ipHost[i].trim())) {
             invalidIPHost.push(ipHost[i].trim());
             swal({ text: 'Invalid NTP server ' + invalidIPHost, icon: 'error' });
             return;
           }
         }
       } else {
         swal({ text: 'Please enter NTP Server', icon: 'error' });
         return;
       }
     }
    let finalJson = {}
    finalJson = Object.assign({}, jsonData);
    finalJson.client.is_enabled = jsonData.is_enabled
    finalJson.client.ntp_servers =jsonData.ntp_servers
    finalJson.client.server_type = jsonData.server_type
    delete finalJson.is_enabled;
    delete finalJson.ntp_servers;
    delete finalJson.server_type;

    axios.patch(Constants.EDGE_API_ENDPOINT + '/system/ntp/all', jsonData)
    .then(res => {
      swal('NTP updated successfully.', { icon: 'success' });
      setReadOnlyNtp(true);
    })
    .catch(error => {
      if (error.response) {
        var errorObj = error.response.data;
        swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
      } else {
        swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
      }
    });
  }

  const getClockInput = () => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetryClock(true);
      } else if(retryCount === 15) {
        setRetryClock(false);
        swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
      }
      return 3000;
    },
      retryCondition: (error) => {
          return true;
      },
    });
    setClockLoad(true);
    client.get('/system/clock/all')
    .then(res => {
      setClockInput(res.data.data)
      setClockLoad(false);
    }).catch(error => {
      setClockLoad(false);
      console.error('clock data error');
    });
  }

  const getClockStatus = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetryStatus(true)
       } else if(retryCount === 15) {
         setRetryStatus(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/clock/status/all')
    .then(res => {
      setInputs(res.data.data)
      const universalTime = res.data.data.universal_time;
      const localTime = res.data.data.local_time;
      const rtcTime = res.data.data.rtc_time;
      if(localTimeInterval && globalTimeInterval){
        clearInterval(localTimeInterval)
        clearInterval(globalTimeInterval)
      }
      localTimeInterval = getLocalDate(localTime);
      globalTimeInterval = getFormattedDate([universalTime, rtcTime]);
    }).catch(error => {
      console.error('clock data');
      setTimeLoad(false)
    });
  },[]);

  const getRefreshClockStatus = useCallback(() => {
    let jsonData = { 'action': 'RefreshStatus', 'source': 'EdgeUI', 'namespace': 'system', 'type':'time', 'services':{} };
    axios.post(Constants.EDGE_API_ENDPOINT + '/system/clock/all', jsonData)
    .then(res => {
      getClockStatus();
    })
    .catch(error => {
      console.log(error);
      getClockStatus();
    });
  },[])

  useEffect(()=>{
    getRefreshClockStatus();
  },[getRefreshClockStatus]);
  
  useEffect(() => {
    getClockInput();
  },[]);

  const handleClockInput = (event) => {
    if(!readOnlyClock) {
      setClockInput({...clockInput,[event.target.name]: event.target.value })
    }
  }

  const editClockTime = () => {
    setReadOnlyClock(false);
  }

  const saveClockInput = () => {
    let finalJson = {};
    finalJson.enabled = true
    finalJson.namespace = 'system';
    finalJson.type = 'time';
    finalJson.services = {};
    finalJson = Object.assign({}, clockInput);

    axios.patch(Constants.EDGE_API_ENDPOINT + '/system/clock/all', finalJson)
    .then(res => {
      swal('Timezone updated successfully.', { icon: 'success', timer: '2000' });
      setReadOnlyClock(true);
      setTimeLoad(true);
      getRefreshClockStatus();
    })
    .catch(error => {
      if (error.response) {
        var errorObj = error.response.data;
        swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
      } else {
        swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
      }
    });
  }

  const setParams = useCallback(() => {
    const params = '?' +
      `${selectedTab === 'general' ? 'general' : '&selectedTab=' + selectedTab}`
    if (params === '?') {
      return '';
    }
    return params;
  }, [selectedTab]);

  const fetchHistoryParams = useCallback(() => {
    const string = new URLSearchParams(historyParams);
    let newParams = {};
    newParams.selectedTab = string.get('selectedTab');
    if (newParams.selectedTab) {
      setSelectedTab(newParams.selectedTab);
      setClockInput(true);
      setReadOnlyNtp(true);
    }
  }, [historyParams]);

  useEffect(()=>{
    fetchHistoryParams();
  },[fetchHistoryParams]);

  useEffect(() => {
    history.push('/time-settings' + setParams());
  }, [history, setParams]);

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '26%',
    border: '2px solid #d7d7d7'
  };

  const getNTPData = () => {
    setNtpLoad(true);
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetryNTP(true)
       } else if(retryCount === 15) {
         setRetryNTP(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/ntp/all')
    .then(res => {
      let data = res.data.data;
      data.is_enabled= data.client.is_enabled
      data.ntp_servers= data.client.ntp_servers
      data.server_type= data.client.server_type
      setInputsNTP(data)
      setNtpLoad(false);
    }).catch(error => {
      setNtpLoad(false);
      console.error(error);
    });
  }

  const getNTPStatus = () => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetryNTP(true)
       } else if(retryCount === 15) {
         setRetryNTP(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/ntp/status/all')
    .then(res => {
      let data = res.data.data;
      setStatusDetails(res.data.data.client)
    }).catch(error => {
      console.error('clock data time error');
    });
  }

  useEffect(() => {
    getNTPData();
    getNTPStatus();
  },[]);

  const getLocalDate = useCallback((date) => {
    let sec = 0;
    const interval = setInterval(() => {
      const splitDate = date.split(' ');
      const dateformate = splitDate[1] + ' ' + splitDate[2];
      var d1 = new Date(dateformate);
      d1.setSeconds(d1.getSeconds() + sec);
      let day = format(d1, "yyyy/MM/dd HH:mm:ss ") + splitDate[3] + format(d1, " (eeee)");
     setLocalDate(day);
     sec = sec+1;
    },1000);
    return interval;
  },[]);

  const getFormattedDate = useCallback( (dates) => {
    let sec = 0;
    const interval = setInterval(() => {
      setTimeLoad(false);
      let newDates = [];
      dates.forEach(date => {
        if(date === 'n/a' || date === '' ){
          newDates.push('-');
        } else {
          const splitDate = date.split(' ');
          const dateformate = splitDate[1] + ' ' + splitDate[2];
          let code = ''
          if(splitDate[3] !== undefined) {
            code = splitDate[3]
          }
          var d1 = new Date(dateformate);
          d1.setSeconds(d1.getSeconds() + sec);
          newDates.push(format(d1, "yyyy/MM/dd HH:mm:ss") + ' ' + code + format(d1, " (eeee)"));
        } 
    });
    sec = sec+1;
    setDates(newDates);}
    , 1000)
    return interval;
  },[]);

  const onTabChange = (tabId) => {
    setTimeLoad(true);
    getRefreshClockStatus();
    if (tabId === 'timezone' || tabId === 'ntp') {
      setSelectedTab(tabId);
    } else {
      setSelectedTab(tabId);
    }
  }

  const onShowDetails = () => {
    setModalOpen(true)
  }

  const closePopup = (event) => {
    setModalOpen(false);
  };

  useEffect(() => {
    setInterval(getConvertedBrowerDate, 1000);
  },[]);

  const getConvertedBrowerDate = () => {
    const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const code = moment.tz(tzid).format(' z');
    const time = format(new Date(), "yyyy/MM/dd HH:mm:ss ") + ' ' + code + format(new Date(), " (eeee)");
    setBrowserDate(time);
  };

  return (
    <ContentDiv>
      <UpperWrapper>
        <SectionHeader>
          <SectionLabel>Date and Time</SectionLabel>
        </SectionHeader>
        <>
        <TabProvider defaultTab={selectedTab} style={{ 'padding': '6px' }} onChange={(tabId) => onTabChange(tabId)}>
          <TabList>
            <Tab tabFor='general' className={'rwt__tab'}>General</Tab>
            <Tab tabFor='timezone' className={'rwt__tab'}>Timezone</Tab>
            <Tab tabFor='ntp' className={'rwt__tab'}>NTP</Tab>
          </TabList>
         
          <TabPanel tabId='general'>
          {(!timeLoad && inputs)?  
          <Row>
            <Column>
              <FieldSet>
                <legend>System Clock</legend>
                <LabelName className='' style={{'marginTop': '10px'}}>Local Time</LabelName><Label> {inputs.local_time !== '' ? localDate: ' -'}</Label>
                <LabelName>Universal Time</LabelName><Label>{inputs.universal_time !== '' ? dates[0] : ' -'}</Label><br/>
              </FieldSet>
              <FieldSet>
                <legend>Hardware Clock</legend>
                <LabelName>RTC Time</LabelName><Label>{inputs.rtc_time !== '' ? dates[1] : ' -'}</Label><br/>
                <LabelName style={{'marginTop': '10px'}}>In Local Time</LabelName><Label>{inputs.rtc_local_tz !== '' ? (inputs.rtc_local_tz === 'yes' ? 'Yes' : 'No' ) : ' -'}</Label><br/>
              </FieldSet>
              <FieldSet>
                <legend>Your Browser</legend>
                <LabelName>Local Time</LabelName><Label>{browserDate}</Label><br/>
              </FieldSet>
            </Column>
          </Row> : <>{ timeLoad &&
        <div className="loading-msg">
          <label>{retryStatus ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress />
        </div>}</>}
        </TabPanel> 

          <TabPanel tabId='timezone'>
            {(clockInput && !clockLoad) ?
            <>
            <Row>
              <TimeZoneColumn>
                <TimeZoneInput>
                  <Label style={{'width': 'max-content', 'margin-top': '5px', 'flex-shrink': '0'}}>Select Timezone</Label>
                  <SelectField name='timezone' id='timezone' disabled={readOnlyClock} value={clockInput.timezone} onChange={handleClockInput}>
                    <SelectOptions items={Timezone.TIMEZONE} />
                  </SelectField>
                </TimeZoneInput>
                <ButtonContainer>
                  { readOnlyClock === true &&
                    <Button id='editRecorderButton' primary onClick={editClockTime}>Edit</Button>
                  }
                  { readOnlyClock === false &&
                    <div>
                      <Button id='saveClockInputButton' primary onClick={saveClockInput}>Save</Button>
                      <Button id='cancelClockInputButton' onClick={() =>  window.location.reload()}>Cancel</Button>
                    </div>
                  }
                </ButtonContainer>
              </TimeZoneColumn>
            </Row>
            </>
             : <>{ clockLoad &&
              <div className="loading-msg">
                <label>{retryClock ? 'Retrying...' : 'Loading...'}</label>
                <CircularProgress />
              </div>}</>}
          </TabPanel>
          <TabPanel tabId='ntp'>
           
          {(inputsNTP && statusDetails && inputs && !ntpLoad) ? 
            <Row>
              <Popup modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                <DetailsPopup closePopup={closePopup} />
              </Popup>
              <Column style={{'width': 'max-content', padding: '10px 20px'}}>
              <Fieldset style={{'margin-top': '20px'}}>
                <legend>Status</legend>
                  <FieldContainer style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Label style={{'marginTop': '0'}} >{inputs.ntp_sync_status === 'active' ? 'System clock is in sync with NTP server' : 'System clock is not in sync with NPT server'}</Label>
                    <div style={{'display': 'flex', 'justifyContent': 'flex-end'}}>
                      <Button id='saveRecorderButton' primary onClick={onShowDetails} style={{'marginLeft':'20px', width: 'max-content'}} >Show Details</Button>
                    </div>
                  </FieldContainer>
                </Fieldset>
                <Fieldset style={{'margin-top': '20px'}}>
                <legend>Server</legend>
                  <FieldContainer style={{'display': 'flex', 'flexDirection':'column', 'marginBottom':'0'}}>
                  <div style={{'marginTop': '20px', 'display': 'flex'}}>
                    <SelectField name='server_type' disabled={readOnlyNtp} value={inputsNTP.server_type} onChange={handleNtpInput} style={{ 'width': '500px'}}>
                      <option value='default'>Default</option>
                      <option value='custom'>Custom</option>
                    </SelectField>
                  </div>
                  {inputsNTP.server_type === 'default' && 
                      <MessageContaier>
                        <MessageLabel style={{'text-size-adjust': '100%'}}>
                        The NTP pool DNS system automatically picks time servers which are geographically close for you, In SCORER Edge default NTP Server is "debian.pool.ntp.org"
                        </MessageLabel>
                      </MessageContaier>}
                  {inputsNTP.server_type === 'custom' && 
                  <div style={{'marginTop': '20px', 'display': 'flex'}}>
                    <TextField name='ntp_servers' value={inputsNTP.ntp_servers} placeholder='e.g. 0.debian.pool.ntp.org,1.debian.pool.ntp.org' type='text' disabled={readOnlyNtp} onChange={handleNtpInput} style={{'marginTop': '10px', 'width': '502px'}} />
                  </div>}
                  <Row>
                    <Column style={{ 'display': 'flex', 'justifyContent': 'flex-end', 'width':'100%', 'paddingBottom':'0'}}>
                    { readOnlyNtp === true &&
                      <Button id='editRecorderButton' primary onClick={editNtpInput}>Edit</Button>
                    }
                    { readOnlyNtp === false &&
                      <div>
                        <Button id='saveRecorderButton' primary onClick={saveNtpInput}>Save</Button>
                        <Button id='cancelRecorderButton' onClick={() =>  window.location.reload()}>Cancel</Button>
                      </div>
                    }
                    </Column>
                    </Row>
                  </FieldContainer>
                </Fieldset>
              </Column>
            </Row> : <>{ ntpLoad &&
            <div className="loading-msg">
              <label>{retryNTP ? 'Retrying...' : 'Loading...'}</label>
              <CircularProgress />
            </div>}</>} 
            
          </TabPanel>
        </TabProvider>
        </>
      </UpperWrapper>
    </ContentDiv>
  );
};

const DetailsPopup = (props) => {
  const {closePopup} = props;
  const [statusDetails, setStatusDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [retry, setRetry]= useState(false);

  const getNTPStatus = () => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    
    client.get('/system/ntp/status/all')
    .then(res => {
      let data = res.data.data;
      setStatusDetails(res.data.data.client)
      setLoading(false);
    }).catch(error => {
      console.error('clock data time error');
      setLoading(false);
    });
  }

  useEffect(() => {
    getNTPStatus();
  },[]);

  return (
    <ResponsivePopupContainer>
       <SectionHeader style={{ 'display': 'flex' }}>
            <SectionLabel>Timesync Status</SectionLabel>
        </SectionHeader>
      <Container>
      { loading &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress />
        </div>
      }
        {statusDetails && !loading &&
        <TableLayout>
          <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' , 'border': '0px' }}>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Server</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.server !== '' ? statusDetails.server : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Poll interval</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}> {Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.poll_interval !== '' ? statusDetails.poll_interval : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Leap</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.leap !== '' ? statusDetails.leap : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Version</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.version  !== '' ? statusDetails.version : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Stratum</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.stratum !== '' ? statusDetails.stratum : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Reference</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.reference !== '' ? statusDetails.reference : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Precision</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.precision !== '' ? statusDetails.precision : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Root distance</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}> {Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.root_distance !== '' ? statusDetails.root_distance : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Offset</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.offset !== '' ? statusDetails.offset : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Delay</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.delay !== '' ? statusDetails.delay : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Jitter</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.jitter !== '' ? statusDetails.jitter : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Packet count</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.packet_count !== '' ? statusDetails.packet_count : ' -'}</Td>
            </tr>
            <tr>
            <Td style={{'fontWeight': '500', 'textAlign': 'left', 'padding': '7px'}}>Frequency</Td>
            <Td style={{'textAlign': 'left', 'padding': '7px'}}>{Object.keys(statusDetails).length === 0 ? ' -' : statusDetails.frequency !== '' ? statusDetails.frequency : ' -'}</Td>
            </tr>
          </table>
        </TableLayout>}
      </Container>
      <CenterDiv style={{'padding': '0 10px 0 10px'}}>
        <Button id="btnCancel" onClick={closePopup} >OK</Button>  
      </CenterDiv>
    </ResponsivePopupContainer>
  )
}

export default TimeSettings;
